import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useCallback, useEffect, useState } from "react";
import { getSector } from "helper";

import StructureTree from "components/Tree/StrutureTree";
import Scrollbar from "components/Tree/Scrollbar";
import { useNavigate } from "react-router-dom";
import { unflatten } from "redux/action/api";
import { _postApi } from "redux/action/api";
import { handleDelete } from "helper";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { _fetchApi } from "redux/action/api";
import CustomTypeahead from "components/CustomTypeahead";
import { bg } from "redux/action/api";

function Tables() {
  const [results, setResults] = useState();
  const [result1, setResult1] = useState();
  const columns = [
    { Header: "Head", accessor: "head", width: "45%", align: "left" },
    { Header: "Code", accessor: "sub_head", align: "left" },
    { Header: "Description", accessor: "description", align: "left" },
    {
      Header: "Action",
      accessor: "action",
      align: "center",
      Cell: () => (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Edit
        </MDTypography>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    getSector({}, "select1", (d) => {
      setResult1(d);
      // console.log(d, "LLLLLLLLL");
      const formattedData = unflatten(d);
      setResults(formattedData);

      setLoading(false);
    });
  }, []);

  let data = result1?.map((i) => ({
    head: i.head,
    description: i.description,
    sub_head: i.sub_head,
  }));

  const rows = data || [];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const [inputs, setInputs] = useState({
    head: "",
    sub_head: "",
    description: "",
    remarks: "",
    type: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setInputs((p) => ({ ...p, [name]: value }));
  };

  const [damn, setDamn] = useState();
  const [edit, setEdit] = useState(false);
  const { addToast } = useToasts();
  let heads = inputs?.head;
  const _new = damn?.hmm === "submit";
  let submit = damn?.hmm === "submit" ? "Submit" : "Update";

  const handleSubmit = () => {
    let type = damn?.hmm === "submit" ? "insert" : "update";
    console.log(inputs);
    let query_type = type;
    if (inputs.head === "" || inputs.description === "") {
      // alert("complate form");
      addToast("complate form", {
        appearance: "info",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } else {
      _postApi(
        `/account?query_type=${type}&head=${heads}`,
        inputs,
        (data) => {
          console.log({ form: data.results[0] });
          setInputs(data.results[0][0]);

          // }
        },
        (err) => console.log(err)
      );
    }

    toggle3();
    getSector({}, "select1", (d) => {
      setResult1(d);
      const formattedData = unflatten(d);
      setResults(formattedData);
    });
  };

  const [headss1, setHeadss] = useState([]);

  const getHeads = useCallback(() => {
    _fetchApi(
      `/get-heads?type=${damn?.type}`,

      (res) => {
        setHeadss(res.results[0]);
        // alert(JSON.stringify(res.results[0]))
      },
      (err) => {
        console.log(err);
      }
    );
  }, [damn?.type]);
  useEffect(() => {
    getHeads();
  }, [getHeads]);

  let id = damn?.id ? damn?.id : 0;
  const getIds = useCallback(() => {
    _fetchApi(
      `/getId/${id}`,
      (data) => {
        console.log({ form: data.results[0] });
        setInputs(data.results[0][0]);
        // }
      },
      (err) => console.log(err)
    );
  }, [id]);

  useEffect(() => {
    getIds();
  }, [getIds]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  CHART OF ACCOUNT
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <Modal isOpen={modal3} toggle={toggle3} size="lg">
                  <ModalHeader>Continue With</ModalHeader>
                  <ModalBody>
                    <div className=" row mb-4 p-3">
                      {/* {JSON.stringify(inputs)}  */}
                      <div className="col-md-6">
                        <lable>Head</lable>
                        <input
                          className="form-control"
                          name="head"
                          value={inputs.head}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <CustomTypeahead
                          // ref={repRef1}
                          labelKey="sub_head"
                          label="Sub Head"
                          options={headss1}
                          onChange={(s) => {
                            if (s.length) {
                              setInputs((p) => ({
                                ...p,
                                sub_head: s[0].sub_head,
                              }));
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <lable>Description</lable>
                        <input
                          className="form-control"
                          name="description"
                          value={inputs.description}
                          onChange={handleChange}
                        />
                      </div>

                      {inputs.type === "ADMINISTRATIVE SEGMENT" ? (
                        <>
                          <div className="col-md-6">
                            <lable>organization(2)</lable>
                            <input
                              className="form-control"
                              name="remarks"
                              value={inputs.remarks}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Sub organization(2)</lable>
                            <input
                              className="form-control"
                              name="sub_org"
                              value={inputs.sub_org}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6">
                            <lable>Sub Sub organization(3)</lable>
                            <input
                              className="form-control"
                              name="remarks2"
                              value={inputs.remarks2}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Sub Sub Sub organization(2)</lable>
                            <input
                              className="form-control"
                              name="remarks1"
                              value={inputs.remarks1}
                              onChange={handleChange}
                            />
                          </div>
                        </>
                      ) : inputs.type === "FUND SEGMENT" ? (
                        <>
                          <div className="col-md-6">
                            <lable>Main Funds(2)</lable>
                            <input
                              className="form-control"
                              name="remarks5"
                              value={inputs.remarks5}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Sub-Funds(1)</lable>
                            <input
                              className="form-control"
                              name="remarks4"
                              value={inputs.remarks4}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Fund Sources(2)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                        </>
                      ) : inputs.type === "ECONOMIC SEGMENT" ? (
                        <>
                          <div className="col-md-6">
                            <lable>Account Type(1)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Sub-Account Types(1)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Account Class(2)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Sub-Class(2)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Economic Code (Line Item)(2)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                        </>
                      ) : inputs.type === "PROGRAMME SEGMENT" ? (
                        <>
                          <div className="col-md-6">
                            <lable>Policy(2)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Programme(2)</lable>
                            <input
                              className="form-control"
                              name="remarks14"
                              value={inputs.remarks14}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Project(6)</lable>
                            <input
                              className="form-control"
                              name="remarks12"
                              value={inputs.remarks12}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Objective(2)</lable>
                            <input
                              className="form-control"
                              name="remarks10"
                              value={inputs.remarks10}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Activity(2)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                        </>
                      ) : inputs.type === "GEO SEGMENT" ? (
                        <>
                          <div className="col-md-6">
                            <lable>Zone(1)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>State(2)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Senatorial(1)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>LGA(2)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <lable>Wards(2)</lable>
                            <input
                              className="form-control"
                              name="remarks3"
                              value={inputs.remarks3}
                              onChange={handleChange}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {_new && (
                        <div className="col-md-6">
                          <lable>Code</lable>
                          <input
                            className="form-control"
                            type="number"
                            name="sub_head"
                            value={inputs.sub_head}
                            onChange={handleChange}
                          />
                        </div>
                      )}

                      <div className="col-md-6">
                        <lable>Description</lable>
                        <input
                          className="form-control"
                          name="description"
                          value={inputs.description}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-md-6">
                        <lable>Remarks</lable>
                        <input
                          className="form-control"
                          name="remarks"
                          value={inputs.remarks}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-between">
                    <button
                      className="btn btn-primary  mb-3"
                      onClick={handleSubmit}
                    >
                      {submit}
                    </button>
                    <Button color="danger" onClick={toggle3}>
                      cancel
                    </Button>
                  </ModalFooter>
                </Modal>
                {loading && <Spinner />}
                <Scrollbar>
                  <StructureTree
                    treeData={results}
                    addChild={(node) => {
                      setInputs((p) => ({
                        sub_head: node.head,
                        type: node.type,
                      }));
                      setDamn((p) => ({
                        ...p,
                        name: node.description,
                        type: node.type,
                        head: node.head,
                        hmm: "submit",
                        ids: node.head,
                      }));
                      toggle3();
                    }}
                    editNode={(node) => {
                      setEdit(true);
                      setDamn((p) => ({
                        ...p,
                        name: node.description,
                        type: node.type,
                        head: node.head,
                        hmm: "edit",
                        id: node.head,
                      }));
                      setInputs((p) => ({
                        ...p,
                        sub_head: node.sub_head,
                        head: node.head,
                      }));
                      toggle3();
                    }}
                    deleteNode={(node) => {
                      handleDelete(node.head);
                      getSector({}, "select1", (d) => {
                        setResult1(d);
                        const formattedData = unflatten(d);
                        setResults(formattedData);
                      });
                    }}
                  />
                </Scrollbar>

                {/* {JSON.stringify(results)} */}

                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
