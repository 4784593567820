import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";
import { getSector } from "helper";
// import { useNavigate } from "react-router-dom";

import moment from "moment";
// import SearchBar from "components/SearchBar";
import { _fetchApi, unflatten } from "redux/action/api";
import BudgetViewTree from "components/Tree/BudgetViewTree";
import Scrollbar from "components/Tree/Scrollbar";
// import StructureTree from "components/Tree/StrutureTree";
import { Label, Input, Spinner } from "reactstrap";
import { useYear } from "useYearList";

export default function BudgetView() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ budget_year: moment().format("YYYY") });
  const years = useYear();

  useEffect(() => {
    setLoading(true);
    getSector({ type: form.budget_year }, "budget_view", (d) => {
      const formattedData = unflatten(d);
      setResults(formattedData);

      setLoading(false);
    });
  }, [form.budget_year]);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  return (
    <DashboardLayout>
      <MDBox pt={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  Budget View Tracker
                </MDTypography>
              </MDBox>
              <MDBox>
                <div className="p-2">
                  {/* <h1>{JSON.stringify(results)}</h1> */}
                  <div className="row">
                    <div className="col-md-8" />
                    <div className="col-md-4" style={{ marginBottom: 10 }}>
                      <Label>Select budget year</Label>
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                  </div>
                  {loading && (
                    <>
                      Loading... <Spinner color="primary" />
                    </>
                  )}
                  <Scrollbar>
                    <BudgetViewTree treeData={results} />
                  </Scrollbar>
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
