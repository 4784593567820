import ReportsExpShareCom from "./reports/ReportsExpShareCom";

function Report() {
  const data = [
    { title: "Total Expenditure by Economic", value: "total_ex_by_eco" },
    { title: "Expenditure by MDA", value: "expenditure_by_mda" },
    { title: "Expenditure by Department", value: "expenditure_by_department" },
  ];
  return (
    <ReportsExpShareCom
      type="total_ex_by_eco"
      title="Total Expenditure by Economic"
      route="/reports"
      options={data}
    />
  );
}

export default Report;
