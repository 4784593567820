import React, { useCallback, useEffect, useRef, useState } from "react";

// import { FaPlus } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// import { AiFillEdit, AiFillEye } from "react-icons/ai";
// import { FiDelete, FiEdit } from "react-icons/fi";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Spinner,
  Row,
  Col,
  Table,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import CustomTypeahead from "components/CustomTypeahead";
// import { today } from "helper";
import { year } from "helper";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useYear } from "useYearList";
import { checkIfNull } from "redux/action/api";
import { bg } from "redux/action/api";
import { FaEye } from "react-icons/fa";
import { useQuery } from "redux/action/api";
import BackButton from "./Back";
import moment from "moment";

function ReleaseView() {
  const currentDate = moment();

  const lastMonthDate = currentDate.subtract(1, "months");

  const formattedLastMonthDate = lastMonthDate.format("YYYY-MM-DD");
  const years = useYear();
  const [form, setForm] = useState({
    budget_year: year,
    economic_code: "",
    economic_description: "",
    program_code: "",
    program_description: "",
    function_code: "",
    function_description: "",
    fund_code: "",
    fund_description: "",
    geo_code: "",
    geo_description: "",
    budget_amount: "",
    actual_amount: "",
    budget_type: "",
    start_date: "",
    end_date: "",
    segment: "",
    transaction_type: "",
    sector_type: "",
    start_date: formattedLastMonthDate,
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [searchText, setSearchText] = useState("");
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const fields = [
    {
      title: "Project Description",
      custom: true,
      component: (ite) => <div> {ite.description}</div>,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => item.economic_code,
    },
    {
      title: "Economic Description",
      custom: true,
      component: (ite) => ite.economic_description,
    },

    { title: "Fund Code", custom: true, component: (ite) => ite.fund_code },
    {
      title: "Fund Description",
      custom: true,
      component: (ite) => ite.fund_description,
    },
    {
      title: "Program Code",
      custom: true,
      component: (ite) => ite.program_code,
    },
    {
      title: "Program Description",
      custom: true,
      component: (ite) => ite.program_description,
    },
    {
      title: "Functional code",
      custom: true,
      component: (ite) => ite.function_code,
    },

    { title: "Geo code", custom: true, component: (ite) => ite.geo_code },
    {
      title: "Geo Description",
      custom: true,
      component: (ite) => ite.geo_description,
    },

    {
      title: "Approved Estimate(₦)",
      custom: true,
      component: (ite, idx) => (
        <div
          style={{ float: "right" }}
          onDoubleClick={() => handleDoubleClick(idx)}
        >
          {ite.status === "final-house" ? (
            editingIndex === idx ? (
              <Input
                name="approve_amount"
                value={ite.approve_amount}
                onChange={({ target: { value } }) => handleChanges(value, idx)}
                onBlur={handleInputBlur}
                style={{ width: "120px", alignItems: "right" }}
              />
            ) : (
              formatNumber(ite.approve_amount)
            )
          ) : (
            formatNumber(ite.approve_amount)
          )}
        </div>
      ),
    },
    {
      title: "Budget Utilize(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.actual_amount)}</div>
      ),
    },
  ];

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  let query_type = form.transaction_type !== "" ? "budget_type" : "selects";
  // let data = results&&results.map(item => item.name)
  // budget_type;
  const getBudgetNew = useCallback(() => {
    setLoading(true);
    _fetchApi(
      `/get-budget-new/${form.mda_name}?query_type=${query_type}&budget_year=${form.budget_year}&transaction_type=${form.transaction_type}`,
      (res) => {
        console.log(res.results, "DATA");
        const modifiedResults = res.results
          .map((item) => {
            if (
              item.approve_amount === 0 ||
              item.approve_amount === "" ||
              item.approve_amount === null
            ) {
              return { ...item, approve_amount: item.house_amount };
            }
            return item;
          })
          .filter(
            (item) =>
              item.status === "final-house" || item.status === "approved"
          );

        setResults(modifiedResults);
        console.log(modifiedResults, "DATA");

        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.mda_name, form.budget_year, form.transaction_type, query_type]);

  useEffect(() => {
    getBudgetNew();
  }, [getBudgetNew]);
  const [results5, setResults5] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults5);
  }, []);
  const [editingIndex, setEditingIndex] = useState(-1);

  const handleDoubleClick = (index) => {
    setEditingIndex(index);
  };

  const handleInputBlur = () => {
    setEditingIndex(-1);
  };
  const handleChanges = (value, index) => {
    const arr = [...results];
    arr[index].approve_amount = value;
    setResults(arr);
  };
  const { addToast } = useToasts();
  const handleSubmit = (name = "") => {
    const filteredResults = results.filter(
      (item) => item.status === "final-house"
    );
    let type = name === "approved" ? "UPDATE" : "INSERT";
    _postApi(
      `/post-budget?query_type=${type}&status=${name}`,
      name === "approved" ? filteredResults : [form],
      (res) => {
        if (res.success) {
          getBudgetNew();
          addToast("Submitted Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setData([]);
        }
      },
      (err) => {
        console.log(err);
        addToast("Submitted Failed", {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    );
  };

  const totalAmount = results?.reduce(
    (total, item) => total + parseFloat(item?.approve_amount),
    0
  );
  console.log(results, "RESULTS");
  const [results4, setResult4] = useState([]);
  useEffect(() => {
    getSector({ type: "mdas", sector_type: form.sector_type }, "mdas", (d) => {
      setResult4(d);
    });
  }, [form.sector_type]);

  let aheadData = results4?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const repRef = useRef();
  const repRef1 = useRef();
  const repRef2 = useRef();
  const repRef3 = useRef();
  const repRef4 = useRef();
  const repRef5 = useRef();
  const [results6, setResults6] = useState([]);
  const [results1, setResults1] = useState([]);
  const [results2, setResults2] = useState([]);
  const [results3, setResults3] = useState([]);
  const [results9, setResults9] = useState([]);
  const [results10, setResults10] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults6);
  }, []);

  useEffect(() => {
    getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults1);
  }, []);

  useEffect(() => {
    getSector({ type: "FUNCTIONAL SEGMENT" }, "select", setResults2);
  }, []);

  useEffect(() => {
    getSector({ type: "PROGRAMME SEGMENT" }, "select", setResults3);
  }, []);

  useEffect(() => {
    getSector({ type: "FUND SEGMENT" }, "select", setResults9);
  }, []);

  useEffect(() => {
    getSector({ type: "GEO SEGMENT" }, "select", setResults10);
  }, []);

  let aheadData6 =
    results9 &&
    results9?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData1 =
    results6 &&
    results6?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData2 =
    results2 &&
    results2?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData3 =
    results3 &&
    results3?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData4 =
    results1 &&
    results1?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData5 =
    results10 &&
    results10?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  const handleChanging = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const filteredResults = results?.filter(
    (item) => item.status === "final-house"
  );

  let data = [];

  results?.forEach((item) => {
    if (
      (item.economic_description &&
        item.economic_description
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) === -1) ||
      (item.economic_code &&
        item.economic_code.toLowerCase().indexOf(searchText.toLowerCase()) ===
          -1)
    )
      return;
    else data.push(item);
  });

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const query = useQuery();
  const mda_name = query.get("mda_name");
  const mda_code = query.get("mda_code");
  const [approveData, setApproveData] = useState([]);
  const getApproveData = () => {
    _postApi(
      "/get-approval-by-date",
      {
        mda_name: mda_name,
        mda_code: mda_code,
        start_date: form.start_date,
        end_date: form.end_date,
      },
      (resp) => {
        // alert(JSON.stringify(resp));
        setApproveData(resp.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getApproveData();
  }, [mda_code, mda_name, form.start_date, form.end_date]);

  return (
    <DashboardLayout>
      {form.mda_name && (
        <center>
          <h2>{/* {form.mda_code} - {form.mda_name} */}</h2>
        </center>
      )}
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  className="text-center"
                >
                  Release Report
                </MDTypography>
              </MDBox>
              {/* <div className="">
                <Button
                  color="primary"
                  className="pr-5 pl-5 m-4"
                  style={{ float: "right" }}
                  onClick={() => toggle3()}
                >
                  Add New Proposal
                </Button>
              </div> */}

              <MDBox>
                <MDBox pt={0}>
                  <BackButton className="m-2" />
                  <div className=" row p-3">
                    {/* {JSON.stringify({ mda_code, mda_name })} */}
                    <Row>
                      <Col md={4}>
                        <label>Date From</label>
                        <Input
                          name="start_date"
                          value={form.start_date}
                          onChange={handleChange}
                          type="date"
                        />
                      </Col>
                      <Col md={4}>
                        <label>Date To</label>
                        <Input
                          name="end_date"
                          value={form.end_date}
                          onChange={handleChange}
                          type="date"
                        />
                      </Col>
                      <div className="col-md-4 mb-3">
                        <label>Budget Type</label>
                        <select
                          name="transaction_type"
                          onChange={handleChange}
                          className="form-control"
                          value={form.transaction_type}
                        >
                          <option value="">...Select...</option>
                          <option value="Recurrent">Recurrent</option>
                          <option value="Capital">Capital</option>
                          {/* <option value="Capital Receipt">
                            Capital Receipt
                          </option>
                          <option value="Persornnel">Persornnel</option>
                          <option value="OverHead">OverHead</option> */}
                        </select>
                      </div>
                    </Row>

                    <Table bordered striped className="mt-3">
                      <thead>
                        <tr>
                          <th style={{ width: "20%" }}>Approval Date</th>
                          <th style={{ width: "20%" }}>MDA Name</th>
                          <th style={{ width: "20%" }}>MDA Code</th>
                          <th style={{ width: "20%" }}>Economic Code</th>
                          <th style={{ width: "10%" }}>Amount</th>
                          <th style={{ width: "20%" }}>Approval Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {approveData &&
                          approveData[0]?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.approval_date}</td>
                              <td>{item.mda_name}</td>
                              <td>{item.mda_name}</td>
                              <td>{item.economic_code}</td>
                              <td>{item.amount}</td>
                              <td>{item.approval_type}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>

                    {/* <center>
                      <Button
                        color="primary"
                        className="pr-5 pl-5"
                        onClick={() => handleSubmit("approved")}
                        disabled={filteredResults?.length === 0 ? true : false}
                      >
                        Submit
                      </Button> 
                    </center>*/}
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ReleaseView;
