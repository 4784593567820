import ReportsAdminShareCom from "./ReportsAdminShareCom";

const listdata = [
  { value: "total_ex_by_dep", title: "Total Exp. by Dep" },
  {
    value: "ex_by_dep_new",
    title: "Personnel Exp. by Dep",
    code: "Persornnel",
  },
  {
    value: "ex_by_dep_new",
    title: "Other NDR Exp. by Dep",
    code: "OverHead",
  },
  {
    value: "ex_by_dep",
    title: "Debt Service by Dep",
    code: "022%",
  },
  {
    value: "ex_by_dep_new",
    title: "Capital Exp. by Dep",
    code: "Capital",
  },
];
function ExpByDepartment() {
  return (
    <ReportsAdminShareCom
      type="total_ex_by_admin"
      route="/reports"
      options={listdata}
      title="Total Expenditure by Dep"
    />
  );
}

export default ExpByDepartment;
