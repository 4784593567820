import ReportsFundShareCom from "./ReportsFundShareCom.js";
import ReportsRevByFund from "./ReportsRevByFund.js";
const listdata = [
  { value: "total_ex_by_fund", title: " Total Expenditure by Fund" },
  { value: "ex_by_fund_new", title: "Personnel Expenditure By Fund" },
  { value: "ex_by_fund", title: "Other NDR Exp. Exp by Fund" },
  { value: "ex_by_fund", title: "Debt Service by Fund" },
  { value: "ex_by_fund_new", title: "Capital Expenditure By Fund" },
];
function ReportByFund() {
  return (
    // <ReportsFundShareCom
    <ReportsRevByFund
      type="total_ex_by_fund"
      title="Total Expenditure by Fund"
      route="/reports"
      options={listdata}
    />
  );
}

export default ReportByFund;
