import { Grid, selectClasses } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Row,
  Spinner,
  FormGroup,
  Alert,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import { useQuery } from "redux/action/api";
import { fetchApi, postApi } from "redux/action/api_tbeams";
import { apiURL } from "redux/action/api_tbeams";
import CapitalProject, { getDistinctObjects } from "./CapitalProject";
import WarningModal from "./WarningModal";
import { formatNumber } from "redux/action/api";
import { useToasts } from "react-toast-notifications";
import SelectedDataTable from "./SelectedDataTable";

export default function MonthlyRecurrentForm() {
  const query = useQuery();
  const type = query.get("type");
  const mdaRef = useRef();
  const economicRef = useRef();
  const navigate = useNavigate();
  const [budgetSummary, setBudgetSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [economicCode, setEconomicCode] = useState([]);
  const [childCode, setChildCode] = useState([]);
  const economicTypeahead = useRef();

  const [form, setForm] = useState({
    collection_date: moment().format("YYYY-MM-DD"),
    approval_date: moment().format("YYYY-MM-DD"),
    mda_description: "",
    mda_budget_balance: "",
    mda_economic_code: "",
    approved_amount: "",
  });

  const [selectedData, setSelectedData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const { addToast } = useToasts();

  const handleAddToList = () => {
    form.rec_type = type;
    if (form.approved_amount === "") {
      addToast("Uncomplete form", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } else {
      setSelectedData((prevData) => [...prevData, form]);
      setShowTable(true);
      setForm({
        mda_description: "",
        mda_name: "",
        mda_budget_balance: "",
        mda_economic_code: "",
        mda_code: "",
        approved_amount: "",
      });
      mdaRef?.current?.setState({ text: "" });
      economicRef?.current?.setState({ text: "" });
    }
  };

  const getList = () => {
    fetchApi(`get-budget-summary?query_type=list`)
      .then((d) => {
        console.log("d", d);
        if (d.result) {
          setBudgetSummary(d.result);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.type === "checkbox") {
      setForm((p) => ({ ...p, [name]: value }));
    } else {
      setForm((p) => ({ ...p, [name]: value }));
    }
  };
  const getBudgetBalance = useCallback(
    (economic_code) => {
      postApi(`update_budget_balance`, {
        query_type: "select",
        mda_name: form.mda_name,
        mda_code: form.mda_code,
        amount: null,
        economic_code: economic_code,
        geo_code: form.geo_code,
        program_code: null,
        functional_code: form.functional_code,
      })
        .then((d) => {
          console.log("data", d);
          if (d.success) {
            const selectedItem = d.data[0];
            setForm((p) => ({
              ...p,
              description: selectedItem?.budget_description,
              mda_budget_balance:
                selectedItem?.mopb_balance == 0
                  ? "Budget Exausted"
                  : selectedItem?.mopb_balance,
              budget:
                selectedItem?.approve_amount == 0
                  ? "Budget Exausted"
                  : selectedItem?.approve_amount,
            }));
          }
        })
        .catch((err) => {
          // Handle errors
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [form.mda_code]
  );

  const handleEconomicCode = (selected) => {
    if (selected?.length) {
      console.log("selected budget", selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        mda_economic_code: selectedItem.economic_code,
        description: selectedItem.economic_description,
        mda_description: selectedItem.economic_description,
      }));
      getBudgetBalance(selectedItem.economic_code);
    } else {
      console.log("no item selected");
    }
  };

  const getList2 = useCallback((mda_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_economic_code&&mda_code=${mda_code}`
    )
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log(d);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getList5 = () => {
    fetchApi(`get-budget-summary?query_type=recurrent`)
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log(d);
          setEconomicCode(d.result);
          economicTypeahead?.current?.focus();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList5();
  }, []);

  const handleMdaCode = (selected) => {
    if (selected?.length) {
      console.log(selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        mda_code: selectedItem.mda_code,
        mda_name: selectedItem.mda_name,
      }));

      getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const handleApi = () => {
    let _data = selectedData;

    if (form.amount === "") {
      addToast("Please Complete the form", {
        appearance: "info",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } else {
      setIsLoading(true);
      postApi(`monthly-recurrent?query_type=insert`, { _data, form })
        .then((data) => {
          if (data.success) {
            setSelectedData([]);
            addToast("submit success", {
              appearance: "info",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            });
          }
        })
        .catch((err) => {
          alert(err);
          addToast(err, {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // const clearEconomicTypeahead = () => {
  //   economicTypeahead.current.clear();
  // };

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3} pl={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  New Release Collection
                </MDTypography>
              </MDBox>
              <Col
                md={5}
                style={{
                  marginLeft: 9,
                }}
                className="mt-4"
              >
                <button
                  className="btn btn-primary"
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                  onClick={() => navigate(-1)}
                >
                  Click here to go back
                </button>
              </Col>

              <Row
                style={{
                  marginLeft: 8,
                  marginBottom: -20,
                  marginTop: 20,
                }}
              ></Row>
              <Row className="mt-1 px-4 py-4">
                <>
                  <Col md={4}>
                    <Label>Select MDA</Label>
                    <Typeahead
                      size="sm"
                      id="20"
                      onChange={(selected) => handleMdaCode(selected)}
                      onInputChange={(text) => console.log(text)}
                      options={getDistinctObjects(
                        budgetSummary,
                        "mda_name",
                        "mda_code"
                      )}
                      labelKey={(option) => `${option.mda_name}`}
                      placeholder="Select---"
                      ref={mdaRef}
                    />
                  </Col>

                  <Col md={4}>
                    <Label>Administrative Code</Label>
                    <Input
                      type="number"
                      value={form.mda_code}
                      name="mda_code"
                      onChange={handleChange}
                      disabled
                    />
                  </Col>
                  {/* <Col md={4}>
                    <Label>Select Head (if any)</Label>
                    <Typeahead
                      size="sm"
                      id="20"
                      onChange={(selected) => handleMdaCode1(selected)}
                      onInputChange={(text) => console.log(text)}
                      options={getDistinctObjects(
                        budgetSummary,
                        "mda_name",
                        "mda_code"
                      )}
                      labelKey={(option) => `${option.mda_name}`}
                      placeholder="Select---"
                      // ref={mdaRef}
                    />
                  </Col> */}
                  <Col md={4}>
                    <Label>Select Economic Code</Label>
                    <Typeahead
                      size="sm"
                      id="20"
                      onChange={(selected) => handleEconomicCode(selected)}
                      onInputChange={(text) => console.log(text)}
                      options={getDistinctObjects(
                        economicCode,
                        "economic_code",
                        "economic_description"
                      )}
                      labelKey={(option) => {
                        return `${option.economic_code} - ${option.economic_description}`;
                      }}
                      placeholder="Select---"
                      ref={economicRef}
                    />
                  </Col>
                  <Col md={4}>
                    <Label>Description</Label>
                    <Input
                      type="text"
                      value={form.description}
                      name="description"
                      onChange={handleChange}
                      disabled
                    />
                  </Col>
                  <Col md={4}>
                    <Label>Budget Balance</Label>
                    <Input
                      value={
                        isNaN(form.mda_budget_balance)
                          ? form.mda_budget_balance
                          : formatNumber(form.mda_budget_balance)
                      }
                      name="mda_budget_balance"
                      onChange={handleChange}
                      disabled
                    />
                  </Col>
                  <Col md={4}>
                    <Label>Approved Budget Allocation</Label>
                    <Input
                      value={
                        isNaN(form.budget)
                          ? form.budget
                          : formatNumber(form.budget)
                      }
                      name="budget"
                      onChange={handleChange}
                      disabled
                    />
                  </Col>
                  <Col md={4}>
                    <Label>
                      Approved Amount ({formatNumber(form.approved_amount)})
                    </Label>
                    <Input
                      type="number"
                      value={form.approved_amount}
                      name="approved_amount"
                      onChange={handleChange}
                    />
                  </Col>
                  {form.mda_code !== "" && form.mda_budget_balance === 0 ? (
                    <Alert color="primary" className="mt-2">
                      <center>Please select the corresponding line item</center>
                    </Alert>
                  ) : null}

                  <center className="mb-3">
                    <Button color="success" onClick={handleAddToList}>
                      {isLoading ? (
                        <Spinner color="light" size="sm" />
                      ) : (
                        "Add to batch"
                      )}
                    </Button>
                  </center>
                  <SelectedDataTable
                    selectedData={selectedData}
                    showTable={showTable}
                  />

                  {selectedData?.length ? (
                    <Button
                      color="primary"
                      onClick={handleApi}
                      disabled={isLoading}
                      style={{
                        marginRight: 10,
                      }}
                    >
                      {isLoading ? (
                        <Spinner color="light" size="sm" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  ) : null}
                </>
              </Row>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
