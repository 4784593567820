import React, { useState, useRef } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Footer from "examples/Footer";
import { _updateApi } from "redux/action/api";
import { useToasts } from "react-toast-notifications";
import { bg } from "redux/action/api";

export default function ProfileNew() {
  const user = useSelector((state) => state.auth.user);
  const { addToast } = useToasts();
  const [form, setForm] = useState(user);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [imageURL, setImageURL] = useState(null);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleSubmit = () => {
    _updateApi(
      `/api/users`,
      form,
      () => {
        addToast("Updated Successfully", {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      },
      () => {
        addToast("Error Occur", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    );
  };

  const updatePassword = () => {
    if (form.newPassword !== form.confirm) {
      addToast("Password do not match", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } else {
      _updateApi(
        `/update-user-password-new`,
        form,
        () => {
          addToast("Updated Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        },
        (err) => {
          addToast(err.password, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      );
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  className="text-center"
                >
                  Profile Info
                </MDTypography>
              </MDBox>
              {/* {JSON.stringify({ old: form.oldPassword, new: form.newPassword })} */}
              <MDBox p={4}>
                <Modal isOpen={open} toggle={toggle}>
                  <ModalHeader toggle={toggle}>Add Signature</ModalHeader>
                  <ModalBody>
                    {/* <SignaturePad
                      ref={sigCanvas}
                      canvasProps={{
                        className: "signatureCanvas",
                      }}
                    /> */}
                  </ModalBody>
                  <ModalFooter>
                    {/* <Button color="success" onClick={save}>
                      Save
                    </Button> */}
                    {/* <Button color="primary" onClick={clear}>
                      Clear
                    </Button> */}
                    <Button color="danger" onClick={toggle}>
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
                <Row>
                  <Col md={9}>
                    <Row>
                      <Col md={7}>
                        <Label>Full name</Label>
                        <Input
                          type="text"
                          name="name"
                          value={form.name}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={5}>
                        <Label>User Name</Label>
                        <Input
                          disabled
                          type="text"
                          name="username"
                          value={form.username}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={7}>
                        <Label>Email</Label>
                        <Input
                          type="text"
                          name="email"
                          value={form.email}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={5}>
                        <Label>Phone</Label>
                        <Input
                          type="text"
                          name="phone"
                          value={form.phone}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={7}>
                        <Label>Address</Label>
                        <Input
                          type="text"
                          name="address"
                          value={form.address}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={5}>
                        <Label>State</Label>
                        <Input
                          type="text"
                          name="state"
                          value={form.state}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={7}>
                        <Label>MDA</Label>
                        <Input
                          disabled
                          type="text"
                          name="mda"
                          value={form.mda}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={5}>
                        <Label>LGA</Label>
                        <Input
                          type="text"
                          name="lga"
                          value={form.lga}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col md={3}>
                    <Col md={12} className="pt-2">
                      <Button color="danger" className="mt-4 " onClick={toggle}>
                        Create Signature
                      </Button>
                      <div className="col-md-6 mt-2">
                        {imageURL ? (
                          <img
                            src={imageURL}
                            className="shadow mt-1"
                            alt="my signature"
                            style={{
                              display: "block",
                              margin: "0 auto",
                              border: "0.5px solid gray",
                              width: "150px",
                              borderRadius: "10px",
                            }}
                          />
                        ) : null}
                      </div>
                    </Col>
                  </Col>
                </Row>
                <center>
                  <button
                    className="btn btn-primary pl-5 pr-5 mt-3"
                    onClick={handleSubmit}
                  >
                    Click to Update
                  </button>
                </center>
                {/* {JSON.stringify(user)} */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Card>
        <MDBox
          mx={2}
          mt={-5}
          py={1}
          px={2}
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h5" color="white" className="text-center">
            Update your Password
          </MDTypography>
        </MDBox>
        <MDBox p={4}>
          <Row>
            <Col md={4}>
              <Label>Old Password</Label>
              <Input
                type="password"
                name="oldPassword"
                value={form.oldPassword}
                onChange={handleChange}
              />
            </Col>
            <Col md={4}>
              <Label>New Password</Label>
              <Input
                type="password"
                name="newPassword"
                value={form.newPassword}
                onChange={handleChange}
              />
            </Col>

            <Col md={4}>
              <Label>Confirm Password</Label>
              <Input
                type="password"
                name="confirm"
                value={form.confirm}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <center>
            <button
              className="btn btn-primary pl-5 pr-5 mt-3"
              onClick={updatePassword}
              // onClick={toggle4}
            >
              Click to Update
            </button>
          </center>
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}
