import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import InputForm from "./Component/InputForm";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Grid } from "@mui/material";
import { getSector } from "helper";
import AgricIndex from "./AgricIndex";
import CustomTypeahead from "components/CustomTypeahead";

export default function KanoStateMandE() {
  const [form, setForm] = useState({
    name_of_sector: "",
    mda: "",
    date_of_visit: "",
    location_project_programme_intervention: "",
    title_project_programme_titervention: "",
    agricultural_contribution_percentage: "",
    farmers_adopted_technology: "",
    registered_farmers: "",
    forecast_registered_farmers_percentage: "",
    engineering_registered_farmers_percentage: "",
    livestock_production_growth_rate: "",
    registered_farmers_finance: "",
    registered_farmers_insurance_subsidy: "",
    fisheries_growth_rate_percentage: "",
    farmers_clusters_facility: "",
    first_Latitude: "",
    first_Longitude: "",
    first_altitude: "",
    first_accuracy: "",
    second_Latitude: "",
    second_Longitude: "",
    second_altitude: "",
    second_accuracy: "",
    third_Latitude: "",
    third_Longitude: "",
    third_altitude: "",
    third_accuracy: "",
    actual_expenditure: "",
    personnel_expenditure: "",
    overhead_expenditure: "",
    capital_expenditure: "",
    adequate_manpower: "",
    adequate_infrastructure_facilities: "",
    linkage_with_other_sectors: "",
    non_governmental_organisations: "",
    suggestions_for_improvements: "",
    name_of_m_and_staff: "",
  });
  const [display, setDisplay] = useState(true);
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
    console.log(form);
  };
  const toggle = () => setDisplay(!display);

  const handleSubmit = () => {
    console.log(form);
  };

  const [results, setResults] = useState([]);
  const [results1, setResults1] = useState([]);
  const [results2, setResults2] = useState([]);
  const [results3, setResults3] = useState([]);
  const [results4, setResults4] = useState([]);
  const [results5, setResults5] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults);
  }, []);

  useEffect(() => {
    getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults1);
  }, []);

  useEffect(() => {
    getSector({ type: "FUNCTIONAL SEGMENT" }, "select", setResults2);
  }, []);

  useEffect(() => {
    getSector({ type: "PROGRAMME SEGMENT" }, "select", setResults3);
  }, []);

  useEffect(() => {
    getSector({ type: "FUND SEGMENT" }, "select", setResults4);
  }, []);

  useEffect(() => {
    getSector({ type: "GEO SEGMENT" }, "select", setResults5);
  }, []);
  const repRef = useRef();

  const repRef1 = useRef();
  const repRef2 = useRef();
  const repRef3 = useRef();
  const repRef4 = useRef();
  const repRef5 = useRef();
  let aheadData =
    results &&
    results?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData1 =
    results1 &&
    results1?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData2 =
    results2 &&
    results2?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData3 =
    results3 &&
    results3?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData4 =
    results4 &&
    results4?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData5 =
    results5 &&
    results5?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  return (
    <div>
      <DashboardLayout>
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card className="">
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h5" color="white">
                    State M and E - Data Collection Tool
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDBox pt={3}>
                    {display ? (
                      <AgricIndex toggle={toggle} />
                    ) : (
                      <MDBox pt={3} p={3}>
                        <Button onClick={() => toggle()}>Go Back</Button>
                        <Row className="p-0 mt-3">
                          <div className="col-md-6">
                            <CustomTypeahead
                              ref={repRef}
                              labelKey="head"
                              label="Select MDA"
                              options={aheadData}
                              onChange={(s) => {
                                if (s.length) {
                                  console.log(s);
                                  setForm((p) => ({
                                    ...p,
                                    admin_description: s[0].description,
                                    admin_code: s[0].code,
                                  }));
                                }
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <CustomTypeahead
                              ref={repRef1}
                              labelKey="head"
                              label="Select Economic Code"
                              options={aheadData1}
                              onChange={(s) => {
                                if (s.length) {
                                  console.log(s);
                                  setForm((p) => ({
                                    ...p,
                                    economic_description: s[0].description,
                                    economic_code: s[0].code,
                                  }));
                                }
                              }}
                            />
                          </div>

                          <div className="col-md-6">
                            <CustomTypeahead
                              ref={repRef2}
                              labelKey="head"
                              label="Select Functional Code"
                              options={aheadData2}
                              onChange={(s) => {
                                if (s.length) {
                                  console.log(s);
                                  setForm((p) => ({
                                    ...p,
                                    function_description: s[0].description,
                                    function_code: s[0].code,
                                  }));
                                }
                              }}
                            />
                          </div>

                          <div className="col-md-6">
                            <CustomTypeahead
                              ref={repRef3}
                              labelKey="head"
                              label="Select Program Code"
                              options={aheadData3}
                              onChange={(s) => {
                                if (s.length) {
                                  console.log(s);
                                  setForm((p) => ({
                                    ...p,
                                    program_description: s[0].description,
                                    program_code: s[0].code,
                                  }));
                                }
                              }}
                            />
                          </div>

                          <div className="col-md-6">
                            <CustomTypeahead
                              ref={repRef4}
                              labelKey="head"
                              label="Select Fund Code"
                              options={aheadData4}
                              onChange={(s) => {
                                if (s.length) {
                                  console.log(s);
                                  setForm((p) => ({
                                    ...p,
                                    fund_description: s[0].description,
                                    fund_code: s[0].code,
                                  }));
                                }
                              }}
                            />
                          </div>

                          <div className="col-md-6">
                            <CustomTypeahead
                              ref={repRef5}
                              labelKey="head"
                              label="Select Geo Code"
                              options={aheadData5}
                              onChange={(s) => {
                                if (s.length) {
                                  console.log(s);
                                  setForm((p) => ({
                                    ...p,
                                    geo_description: s[0].description,
                                    geo_code: s[0].code,
                                  }));
                                }
                              }}
                            />
                          </div>
                          <Col md={4}>
                            <label style={{ height: 35 }}>Name of Sector</label>
                            <InputForm
                              id="exampleSelect"
                              value={form.name_of_sector}
                              name="name_of_sector"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              Name of Lead Ministry, Department or Agency (MDA)
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.mda}
                              name="mda"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>Date of visit.</label>
                            <InputForm
                              id="exampleSelect"
                              value={form.date_of_visit}
                              name="date_of_visit"
                              type="date"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              Location of the Project/ Programme/ Intervention
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={
                                form.location_project_programme_intervention
                              }
                              name="location_project_programme_intervention"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              Title of the Project/ Programme/ Intervention
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.title_project_programme_titervention}
                              name="title_project_programme_titervention"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What is the nature of the Project/ Programme/
                              Intervention?
                            </label>
                            <Col style={{ display: "flex", width: "103%" }}>
                              <Col>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Works/Infrastructure
                                </label>
                              </Col>
                              <Col>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Goods supply
                                </label>
                              </Col>
                              <Col>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Service provision
                                </label>
                              </Col>
                              <Col>
                                <input
                                  type="checkbox"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Programme
                                </label>
                              </Col>
                            </Col>
                          </Col>

                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              Please provide additional information on the
                              nature of the Project/ Programme/ Intervention?
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.farmers_adopted_technology}
                              name="farmers_adopted_technology"
                              placeholder="Describe the nature of the infrastructural project, provision of good, services or programme? Give a brief description."
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What is the goal/objective of the Project/
                              Programme/ Intervention?
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.registered_farmers}
                              name="registered_farmers"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What is the duration of the Project/ Programme/
                              Intervention?
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={
                                form.forecast_registered_farmers_percentage
                              }
                              name="forecast_registered_farmers_percentage"
                              onChange={handleChange}
                            />
                          </Col>

                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What is the start date of the Project/ Programme/
                              Intervention?
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={
                                form.engineering_registered_farmers_percentage
                              }
                              name="engineering_registered_farmers_percentage"
                              type="date"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What is the scheduled end date of the Project/
                              Programme/ Intervention?
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.livestock_production_growth_rate}
                              name="livestock_production_growth_rate"
                              type="date"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              {" "}
                              Who are the target beneficiaries of the project/
                              Programme/ Intervention?
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.registered_farmers_finance}
                              name="registered_farmers_finance"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What is the mode of execution/implementation?
                            </label>
                            <Col style={{ display: "flex", width: "100%" }}>
                              <Col>
                                <input
                                  type="radio"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Contract
                                </label>
                              </Col>
                              <Col>
                                <input
                                  type="radio"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Direct labour
                                </label>
                              </Col>
                              <Col>
                                <input
                                  type="radio"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Partners support
                                </label>
                              </Col>
                            </Col>
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              Please provide the name and contact details of the
                              Contractor/ Service provider/ Implementing
                              partner.
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.fisheries_growth_rate_percentage}
                              name="fisheries_growth_rate_percentage"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What is the current status of the Project/
                              Programme/ Intervention?
                            </label>
                            <Col style={{ display: "flex", width: "100%" }}>
                              <Col>
                                <input
                                  type="radio"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Pending/Not started
                                </label>
                              </Col>
                              <Col>
                                <input
                                  type="radio"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Ongoing
                                </label>
                              </Col>
                              <Col>
                                <input
                                  type="radio"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Completed
                                </label>
                              </Col>
                            </Col>
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What is the current condition of the Project/
                              Programme/ Intervention output?
                            </label>
                            <Col style={{ display: "flex", width: "100%" }}>
                              <Col>
                                <input
                                  type="radio"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Functioning
                                </label>
                              </Col>
                              <Col>
                                <input
                                  type="radio"
                                  name=""
                                  id="Works/Infrastructure"
                                />
                                <label style={{ paddingLeft: 3 }}>
                                  Not Functioning
                                </label>
                              </Col>
                            </Col>
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              List the activities attached to the implementation
                              of the Project/ Programme/ Intervention.
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.farmers_clusters_facility}
                              name="farmers_clusters_facility"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              How much was budgeted for the Project/ Programme/
                              Intervention?
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.farmers_clusters_facility}
                              name="farmers_clusters_facility"
                              placeholder="....in Naira"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={12}>
                            <label style={{ height: 35 }}>
                              Please provide the appropriate budget codes for
                              the Project/ Programme/ Intervention below.
                            </label>
                            <Row>
                              <Col>
                                <InputForm
                                  id="exampleSelect"
                                  value={form.farmers_clusters_facility}
                                  name="farmers_clusters_facility"
                                  placeholder="Code"
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col>
                                <InputForm
                                  id="exampleSelect"
                                  value={form.farmers_clusters_facility}
                                  name="farmers_clusters_facility"
                                  placeholder="Admin Code"
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col>
                                <InputForm
                                  id="exampleSelect"
                                  value={form.farmers_clusters_facility}
                                  name="farmers_clusters_facility"
                                  placeholder="Economic Code"
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col>
                                <InputForm
                                  id="exampleSelect"
                                  value={form.farmers_clusters_facility}
                                  name="farmers_clusters_facility"
                                  placeholder="Function Code"
                                  onChange={handleChange}
                                />
                              </Col>
                              <Col>
                                <InputForm
                                  id="exampleSelect"
                                  value={form.farmers_clusters_facility}
                                  name="farmers_clusters_facility"
                                  placeholder="Programme Code"
                                  onChange={handleChange}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              How much has been released for the implementation
                              of the Project/ Programme/ Intervention?
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.farmers_clusters_facility}
                              name="farmers_clusters_facility"
                              placeholder="...in Naira"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What are the issues observed with the Project/
                              Programme/ Intervention?
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.farmers_clusters_facility}
                              name="farmers_clusters_facility"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What Mitigation measures have been put in place to
                              address (17) above? Please list in the order
                              stated in (17) above.
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.farmers_clusters_facility}
                              name="farmers_clusters_facility"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              Issues identified
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.farmers_clusters_facility}
                              name="farmers_clusters_facility"
                              onChange={handleChange}
                            />
                          </Col>
                          <label style={{ height: 35 }}>
                            Mitigation measures
                          </label>
                          <Row>
                            <Col md={4} className="mb-3">
                              <InputForm
                                id="exampleSelect"
                                value={form.farmers_clusters_facility}
                                name="farmers_clusters_facility"
                                placeholder="iv"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4} className="mb-3">
                              <InputForm
                                id="exampleSelect"
                                value={form.farmers_clusters_facility}
                                name="farmers_clusters_facility"
                                placeholder="v"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4}>
                              <InputForm
                                id="exampleSelect"
                                value={form.farmers_clusters_facility}
                                name="farmers_clusters_facility"
                                placeholder="iii"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4}>
                              <InputForm
                                id="exampleSelect"
                                value={form.farmers_clusters_facility}
                                name="farmers_clusters_facility"
                                placeholder="ix"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4}>
                              <InputForm
                                id="exampleSelect"
                                value={form.farmers_clusters_facility}
                                name="farmers_clusters_facility"
                                placeholder="vii"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4} className="mb-3">
                              <InputForm
                                id="exampleSelect"
                                value={form.farmers_clusters_facility}
                                name="farmers_clusters_facility"
                                placeholder="i"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4} className="mb-3">
                              <InputForm
                                id="exampleSelect"
                                value={form.farmers_clusters_facility}
                                name="farmers_clusters_facility"
                                placeholder="viii"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4} className="mb-3">
                              <InputForm
                                id="exampleSelect"
                                value={form.farmers_clusters_facility}
                                name="farmers_clusters_facility"
                                placeholder="vi"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4} className="mb-3">
                              <InputForm
                                id="exampleSelect"
                                value={form.farmers_clusters_facility}
                                name="farmers_clusters_facility"
                                placeholder="ii"
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              What are the challenges affecting monitoring this
                              Project/ Programme/ Intervention?
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.farmers_clusters_facility}
                              name="farmers_clusters_facility"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35 }}>
                              Recommended action(s)...
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.farmers_clusters_facility}
                              name="farmers_clusters_facility"
                              onChange={handleChange}
                            />
                          </Col>

                          {/* First Project Location */}

                          <Col md={4}>
                            <label style={{ height: 35, paddingTop: 20 }}>
                              Please provide picture(s) evidence of Projects/
                              Programmes/ Interventions started, ongoing or
                              completed within the period.
                            </label>
                            <input
                              type="file"
                              style={{
                                border: "1px solid #000",
                                padding: 6,
                                width: "100%",
                              }}
                            />
                          </Col>
                          <Row className="mb-3">
                            <label
                              style={{
                                paddingBottom: 12,
                                paddingTop: 12,
                                fontSize: 13,
                              }}
                            >
                              Please provide the location of the project/
                              programme/ intervention.
                            </label>
                            <Col md={3}>
                              <InputForm
                                id="exampleSelect"
                                value={form.first_Latitude}
                                name="first_Latitude"
                                placeholder="Latitude (x.y °)"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={3}>
                              <InputForm
                                id="exampleSelect"
                                value={form.first_Longitude}
                                name="first_Longitude"
                                placeholder="Longitude (x.y °)"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={3}>
                              <InputForm
                                id="exampleSelect"
                                value={form.first_altitude}
                                name="first_altitude"
                                placeholder="altitude (m)"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={3}>
                              <InputForm
                                id="exampleSelect"
                                value={form.first_accuracy}
                                name="first_accuracy"
                                placeholder="accuracy (m)"
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>

                          <Col md={4}>
                            <label style={{ height: 35, paddingTop: 20 }}>
                              Please provide picture(s) evidence of the Project/
                              Programme/ Intervention activity here.
                            </label>
                            <input
                              type="file"
                              style={{
                                border: "1px solid #000",
                                padding: 6,
                                width: "100%",
                              }}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35, paddingTop: 20 }}>
                              Please provide picture(s) evidence of the Project/
                              Programme/ Intervention activity here.
                            </label>
                            <input
                              type="file"
                              style={{
                                border: "1px solid #000",
                                padding: 6,
                                width: "100%",
                              }}
                            />
                          </Col>
                          <Col md={4}>
                            <label style={{ height: 35, paddingTop: 20 }}>
                              Please provide picture(s) evidence of the Project/
                              Programme/ Intervention activity here.
                            </label>
                            <input
                              type="file"
                              style={{
                                border: "1px solid #000",
                                padding: 6,
                                width: "100%",
                              }}
                            />
                          </Col>
                          <Col md={3}>
                            <label style={{ height: 35, paddingTop: 20 }}>
                              M&E Officer (Name, Date & Sign)
                            </label>
                            <InputForm
                              id="exampleSelect"
                              value={form.first_accuracy}
                              name="first_accuracy"
                              placeholder="accuracy (m)"
                              onChange={handleChange}
                            />
                          </Col>

                          <div>
                            <center>
                              <Button
                                color="primary"
                                className="px-5"
                                style={{ float: "right" }}
                                onClick={handleSubmit}
                              >
                                Submit
                              </Button>
                            </center>
                          </div>
                        </Row>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </div>
  );
}
