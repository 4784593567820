import React, { useEffect, useState } from "react";
// import { Table } from "reactstrap";
import PropTypes from "prop-types";
import { checkStrEmpty } from "./index";

import "./table.css";
import { Alert, Spinner } from "reactstrap";

function CustomTable(props) {
  const { fields = [], data = [], className = "", type = "", tableRef } = props;
  const [isLoading, setIsLoading] = useState(true);

  // Simulate data loading (replace with your actual data fetching logic)
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <div className="custom-table-container">
      <table className={`table table-striped table-bordered`} ref={tableRef}>
        <thead>
          <tr style={{ width: "100px" }}>
            {fields?.map((_item, _idx) => (
              <th
                key={_idx}
                className="text-"
                style={{
                  fontSize: "13px",
                  // width: "10000px",
                  fontWeight: "normal",
                }}
              >
                {_item?.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, idx) => (
            // alert(JSON.stringify(item.status))
            <tr
              className="dmtd"
              key={idx}
              style={{
                borderLeftWidth: "10px",
                borderColor:
                  type === "mopb"
                    ? item?.status === "final-proposal"
                      ? "orange"
                      : "green"
                    : type === "proposals"
                    ? item?.status === "draft"
                      ? "orange"
                      : "green"
                    : type === "exco"
                    ? item.status === "final-mopb"
                      ? "orange"
                      : "green"
                    : type === "house"
                    ? item.status === "final-exco"
                      ? "orange"
                      : "green"
                    : type === "approve"
                    ? item.status === "final-house"
                      ? "orange"
                      : "green"
                    : "",
                fontSize: "13px",
                width: "100%",
              }}
            >
              {fields?.map((_item, _idx) => {
                let val = item[_item?.value] || "";
                let value_alt =
                  (_item.value_alt && item[_item.value_alt]) || "";
                if (_item.component) {
                  return (
                    <td
                      key={_idx}
                      style={{
                        fontSize: "13px",
                        // width: "1000px",
                      }}
                      className={_item.className}
                    >
                      {_item?.component(item, idx)}
                    </td>
                  );
                } else {
                  return (
                    <td
                      key={_idx}
                      style={{
                        fontSize: "13px",
                        // width: "1000px",
                      }}
                    >
                      {checkStrEmpty(val) ? value_alt : val.toString()}
                    </td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>

      {isLoading ? (
        <div className="loading-container text-center">
          <Spinner color="primary" style={{ marginTop: "20px" }} /> Loading...
        </div>
      ) : data.length === 0 ? (
        <div className="container">
          <center>
            {" "}
            <Alert color="primary">No Data Available Right Now !!</Alert>
          </center>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

CustomTable.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      value_alt: PropTypes.string,
      component: PropTypes.func,
      className: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  tableRef: PropTypes.string,
};

export default CustomTable;
