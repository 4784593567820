import ReportShareCom from "./ReportsExpShareCom";

function GeoReport() {
  return (
    <ReportShareCom
      type="geo"
      header="Personnel Expenditure by Fund"
      ignore="Geo Code"
    />
  );
}

export default GeoReport;
