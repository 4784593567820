import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

import { useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { UserContext } from "redux/contextApi/user_context";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "redux/action/auth";
import { Toaster } from "react-hot-toast";
import { useToasts } from "react-toast-notifications";
import { getSector } from "helper";
import CustomTypeahead from "components/CustomTypeahead";
import CheckBoxNormal from "examples/CheckboxNormal";
import allModule from "./moduleData";
import CheckBoxItem from "examples/Checkbox";
import { useQuery } from "redux/action/api";
import { _fetchApi } from "redux/action/api";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SignaturePad from "react-signature-canvas";
import "./signCanvas.css";
import { _postApi } from "redux/action/api";
import BackButton from "./Back";
// const subTreasuryModules = [
//   "Dashboard",
//   "Account Chart",
//   "Administrative Segment",
//   "Economic Segment",
//   "Functional Segment",
//   "Program Segment",
//   "Fund Segment",
//   "Geo Segments",
//   "Report",
//   "Budget",
//   "Budget Type",
//   "User Management",
//   "Budget Mapping",
//   "Budget Celling",
//   "MDA Mapping",

//   "set up",
//   "Budget Proposal",
//   "MOPB Recomendation",
//   "Exco Recomedation",
//   "House Recommendation",
//   "Approve Recommendation",
//   "Supplementary",
//   "Revised Estimate",
//   "Expenditure by Department",
//   "Total Revenue by Admin",
//   "Oil Receipts Revenue by Admin",
//   "Oil Capital Rec Revenue by Admin",
//   "Total Revenue by Economic",
//   "Capital Receipts by item",
//   "Total Revenue by Fund",
//   "Receipt Revenue by Fund",
//   "Capital Receipts by Fund",
//   "Total Expenditure by Fund",
//   "Personnel Expenditure by Fund",
//   "Overhead Expenditure by Fund",
// ];

function CreatUser() {
  const [form, setForm] = useState({
    accessTo: [],
    functionalities: [],
    role: "admin",
  });
  const [mda, setMda] = useState([]);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [imageURL, setImageURL] = useState(null);
  const userId = useQuery().get("id");

  const handleChange = ({ target: { name, value } }) =>
    setForm((p) => ({ ...p, [name]: value }));

  const handleCheckboxChange = (i) => {
    if (!Array.isArray(form.accessTo)) {
      // If form.accessTo is not an array, initialize it as an empty array
      setForm((p) => ({ ...p, accessTo: [] }));
      return;
    }

    if (form.accessTo.includes(i)) {
      let newList = form.accessTo?.filter((a) => a !== i);
      setForm((p) => ({ ...p, accessTo: newList }));
    } else {
      let newList = [...form?.accessTo, i];
      setForm((p) => ({ ...p, accessTo: newList }));
    }
  };

  const handleFunctionalities = (val) => {
    if (!Array.isArray(form.functionalities)) {
      // If form.functionalities is not an array, initialize it as an empty array
      setForm((p) => ({ ...p, functionalities: [] }));
      return;
    }

    if (form?.functionalities?.includes(val)) {
      let newVal = form?.functionalities.filter((item) => item !== val);
      setForm((p) => ({ ...p, functionalities: newVal }));
    } else {
      let newVal = [...form?.functionalities, val];
      setForm((p) => ({ ...p, functionalities: newVal }));
    }
  };

  const getDatas = () => {
    _fetchApi(
      `/get-all`,
      (data) => {
        if (data.success) {
          setResults(data.results[0]);
        }
      },
      (err) => console.log(err)
    );
  };

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const handleApi = () => {
    dispatch(
      signup(
        {
          ...form,
          accessTo: form.accessTo.join(","),
          role: "admin",
          mda: form.mda_name,
          mda_code: form.mda_code,
          functionalities: form.functionalities.join(","),
          imageURL,
        },
        (result) => {
          if (result.success) {
            console.log(result, "elelelele");
            setForm({ accessTo: [], role: "", functionalities: [] });
            getDatas();
            addToast("User created Successfully", {
              appearance: "info",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            });
          }
        },
        (err) => {
          console.log(err, "sdasdsadasdsad");
          setLoading(false);
          addToast(err, { appearance: "error" });
        }
      )
    );
    getDatas();
    navigate(-1);
    addToast("User created Successfully", {
      appearance: "info",
      autoDismiss: true,
      autoDismissTimeout: 5000,
    });
  };
  const [results4, setResult4] = useState([]);

  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });
  }, []);

  let aheadData = results4?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));

  const user = useSelector((e) => e.auth.user);
  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setMda(d);
    });
  }, []);

  const isChecked = (name) => form?.accessTo?.includes(name);
  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {
    _fetchApi(
      `/get-user-id?id=${id}`,
      (res) => {
        // alert(JSON.stringify(res.results[0]));
        setForm((p) => ({
          ...p,
          accessTo: res.results[0][0]?.accessTo.split(","),
          username: res.results[0][0]?.username,
          fullname: res.results[0][0]?.name,
          functionalities: res.results[0][0]?.functionalities.split(","),
          mda: res.results[0][0]?.mda,
          mda_name: res.results[0][0]?.mda,
          password: res.results[0][0]?.password,
        }));
        setImageURL(res?.results[0][0]?.imageURL);
      },

      (err) => {
        console.log(err);
        alert("an error occurred during the request");
      }
    );
  }, []);

  // const handleUpdate = () => {
  //   addToast("User Update Successfully", {
  //     appearance: "info",
  //     autoDismiss: true,
  //     autoDismissTimeout: 5000,
  //   });
  //   navigate(-1);
  // };

  const sigCanvas = useRef({});
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    toggle();
  };

  const handleUpdate = () => {
    const updatedUserData = {
      ...form,
      accessTo: form.accessTo.join(","),
      role: "admin",
      mda: form.mda_name,
      mda_code: form.mda_code,
      functionalities: form.functionalities.join(","),
      name: form.fullname,
      imageURL,
      userId,
    };
    _postApi(
      "/update_user-api-new",
      updatedUserData,
      (data) => {
        console.log("Updated user data:", data);
        addToast("User updated successfully", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
        navigate(-1);
      },
      (error) => {
        console.error("There was a problem with the update:", error);
        addToast("Failed to update user", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    );
  };

  return (
    <DashboardLayout>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Signature</ModalHeader>
        <ModalBody>
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={save}>
            Save
          </Button>
          <Button color="primary" onClick={clear}>
            Clear
          </Button>
          <Button color="danger" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  Create New User
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <div style={{ marginLeft: 20 }}>
                  <BackButton />
                </div>
                <Toaster position="top-center" reverseOrder={false} />
                <div className="px-5">
                  {/* {JSON.stringify(form)} */}
                  <form className="form-group">
                    <div className="row" style={{ margin: 0 }}>
                      <div className="col-md-4">
                        <label>UserName</label>
                        <input
                          className="form-control"
                          type="text"
                          name="username"
                          value={form.username}
                          onChange={handleChange}
                          disabled={id ? true : false}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>FullName</label>
                        <input
                          className="form-control"
                          type="text"
                          name="fullname"
                          value={form.fullname}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Password</label>
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          value={form.password}
                          disabled={id ? true : false}
                          onChange={handleChange}
                        />
                      </div>
                      {/* {JSON.stringify(userId)} */}
                      <div className="col-md-4 mb-3">
                        {id ? (
                          <>
                            <label>MDA Name</label>
                            <input
                              className="form-control"
                              type="mda_name"
                              name="mda_name"
                              value={form.mda_name}
                              // disabled={id ? true : true}
                              onChange={handleChange}
                            />
                          </>
                        ) : (
                          <CustomTypeahead
                            disabled={id ? true : false}
                            labelKey="head"
                            label="Select MDA"
                            options={aheadData}
                            onChange={(s) => {
                              if (s.length) {
                                console.log(s);
                                setForm((p) => ({
                                  ...p,
                                  mda_code: s[0].code,
                                  mda_name: s[0].description,
                                }));
                              }
                            }}
                          />
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-5 mt-4">
                            <Button color="danger" onClick={toggle}>
                              Create Signature
                            </Button>
                          </div>
                          <div className="col-md-6">
                            {imageURL ? (
                              <img
                                src={imageURL}
                                className="shadow mt-1"
                                alt="my signature"
                                style={{
                                  display: "block",
                                  margin: "0 auto",
                                  border: "0.5px solid gray",
                                  width: "150px",
                                  borderRadius: "10px",
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <h5 className="text-center">Permission and Access Level</h5>
                  <div className="row">
                    <div
                      className={
                        isChecked("Chart Of Account") ? "col-md-6" : "col-md-3"
                      }
                    >
                      <div className="row card shadow">
                        <div className="col-md-12">
                          <CheckBoxNormal
                            label="Chart Of Account"
                            name="Chart Of Account"
                            handleCheck={handleCheckboxChange}
                            value={form.accessTo}
                            className="font-weight-bold ml-4 m-0 pr-3"
                          />
                        </div>

                        {allModule.account.type.map((item) => (
                          <div className="col-md-6 " key={item}>
                            {isChecked("Chart Of Account") ? (
                              <CheckBoxItem
                                label={item}
                                name={item}
                                handleCheck={handleFunctionalities}
                                value={form.functionalities}
                              />
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="col-md-3 card shadow">
                      <CheckBoxNormal
                        label="Main Org ceiling"
                        name="Main Org ceiling"
                        handleCheck={handleCheckboxChange}
                        value={form.accessTo}
                        className="font-weight-bold ml-4 m-0 pr-3"
                      />
                      {allModule.budget.type.map((item) =>
                        isChecked("Main Org ceiling") ? (
                          <CheckBoxItem
                            key={item}
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={form.functionalities}
                          />
                        ) : null
                      )}
                    </div>

                    <div className="col-md-3 card shadow">
                      <CheckBoxNormal
                        label="MDA Mapping"
                        name="MDA Mapping"
                        handleCheck={handleCheckboxChange}
                        value={form.accessTo}
                        className="font-weight-bold ml-4 m-0 pr-3"
                      />
                      {allModule.mda_mapping.type.map((item) =>
                        isChecked("MDA Mapping") ? (
                          <CheckBoxItem
                            key={item}
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={form.functionalities}
                          />
                        ) : null
                      )}
                    </div>

                    <div className="col-md-3 card shadow">
                      <CheckBoxNormal
                        label="Budget Procceses"
                        name="Budget Procceses"
                        handleCheck={handleCheckboxChange}
                        value={form.accessTo}
                        className="font-weight-bold ml-4 m-0 pr-3"
                      />
                      {allModule.budget_type.type.map((item) =>
                        isChecked("Budget Procceses") ? (
                          <CheckBoxItem
                            key={item}
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={form.functionalities}
                          />
                        ) : null
                      )}
                    </div>

                    <div className="col-md-3 card shadow">
                      <CheckBoxNormal
                        label="Reports"
                        name="Reports"
                        handleCheck={handleCheckboxChange}
                        value={form.accessTo}
                        className="font-weight-bold ml-4 m-0 pr-3"
                      />
                      {allModule.reports.type.map((item) =>
                        isChecked("Reports") ? (
                          <CheckBoxItem
                            key={item}
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={form.functionalities}
                          />
                        ) : null
                      )}
                    </div>

                    <div className="col-md-3 card shadow">
                      <CheckBoxNormal
                        label="M And E"
                        name="M And E"
                        handleCheck={handleCheckboxChange}
                        value={form.accessTo}
                        className="font-weight-bold ml-4 m-0 pr-3"
                      />

                      {allModule.m_and_e.type.map((item) =>
                        isChecked("M And E") ? (
                          <CheckBoxItem
                            key={item}
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={form.functionalities}
                          />
                        ) : null
                      )}
                    </div>

                    <div className="col-md-3 card shadow">
                      <CheckBoxNormal
                        label="Admin"
                        name="Admin"
                        handleCheck={handleCheckboxChange}
                        value={form.accessTo}
                        className="font-weight-bold ml-4 m-0 pr-3"
                      />
                      {allModule.setup.type.map((item) =>
                        isChecked("Admin") ? (
                          <CheckBoxItem
                            key={item}
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={form.functionalities}
                          />
                        ) : null
                      )}
                    </div>

                    <div className="col-md-3 card shadow">
                      <CheckBoxNormal
                        label="Release Processing"
                        name="Release Processing"
                        handleCheck={handleCheckboxChange}
                        value={form.accessTo}
                        className="font-weight-bold ml-4 m-0 pr-3"
                      />

                      {allModule.approval_collection.type.map((item) =>
                        isChecked("Release Processing") ? (
                          <CheckBoxItem
                            key={item}
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={form.functionalities}
                          />
                        ) : null
                      )}
                    </div>

                    <div className="col-md-3 card shadow">
                      <CheckBoxNormal
                        label="Virement"
                        name="Virement"
                        handleCheck={handleCheckboxChange}
                        value={form.accessTo}
                        className="font-weight-bold ml-4 m-0 pr-3"
                      />

                      {allModule.virment.type.map((item) =>
                        isChecked("Virement") ? (
                          <CheckBoxItem
                            key={item}
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={form.functionalities}
                          />
                        ) : null
                      )}
                    </div>

                    <div className="col-md-3 card shadow">
                      <CheckBoxNormal
                        label="CC Copies"
                        name="CC Copies"
                        handleCheck={handleCheckboxChange}
                        value={form.accessTo}
                        className="font-weight-bold ml-4 m-0 pr-3"
                      />

                      {allModule.ccopies.type.map((item) =>
                        isChecked("CC Copies") ? (
                          <CheckBoxItem
                            key={item}
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={form.functionalities}
                          />
                        ) : null
                      )}
                    </div>
                    <div className="col-md-3 card shadow">
                      <CheckBoxNormal
                        label="Main Org ceiling"
                        name="budget"
                        handleCheck={handleCheckboxChange}
                        value={form.accessTo}
                        className="font-weight-bold ml-4 m-0 pr-3"
                      />

                      {allModule.budget.type.map((item) =>
                        isChecked("budget") ? (
                          <CheckBoxItem
                            key={item}
                            label={item}
                            name={item}
                            handleCheck={handleFunctionalities}
                            value={form.functionalities}
                          />
                        ) : null
                      )}
                    </div>
                  </div>

                  <center className="my-4">
                    <button
                      className="btn btn-primary w-110 text-white"
                      onClick={id ? handleUpdate : handleApi}
                    >
                      {id ? "Update User" : "Create user"}
                    </button>
                  </center>
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreatUser;
