import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Divider, Icon } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import "./styles/style.css";
import { MdApi, MdDashboard } from "react-icons/md";
import { TbReport } from "react-icons/tb";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { MdRecordVoiceOver } from "react-icons/md";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import {
  FaAngleDown,
  FaAngleUp,
  FaChartLine,
  FaDesktop,
  FaReceipt,
  FaSignOutAlt,
  FaUser,
} from "react-icons/fa";
import { MdDomainVerification } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/action/auth";
import logo from "../../assets/images/k.png";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import { FaxOutlined, MonetizationOnOutlined } from "@mui/icons-material";
import { setToggleNav } from "context";

const Sidenav = ({ brandName, routes, ...rest }) => {
  const [controller, dispatch] = useMaterialUIController();
  const dispatchR = useDispatch();
  const { miniSidenav, transparentSidenav, darkMode, sidenavColor, toggleNav } =
    controller;
  const whiteSidenav = true;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const navigate = useNavigate();
  let textColor = "dark";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => {
    setMiniSidenav(dispatch, true);
  };
  const handleClose = () => {
    setToggleNav(dispatch, !toggleNav);
  };

  useEffect(() => {
    const handleMiniSidenav = () => {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? true : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? true : whiteSidenav
      );
    };

    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();

    return () => {
      window.removeEventListener("resize", handleMiniSidenav);
    };
  }, [dispatch, location, transparentSidenav, whiteSidenav]);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showSubMenurev, setShowSubMenurev] = useState(false);
  const [showSubMenuExp, setShowSubMenuExp] = useState(false);
  const drop = () => {
    setShowSubMenu((p) => !p);
  };
  const droprev = () => {
    setShowSubMenurev((p) => !p);
  };

  const dropExp = () => {
    setShowSubMenuExp((p) => !p);
  };
  const [showSubMenu2, setShowSubMenu2] = useState(false);
  const drop2 = () => {
    setShowSubMenu2((p) => !p);
  };

  const [showSubMenu3, setShowSubMenu3] = useState(false);
  const drop3 = () => {
    setShowSubMenu3((p) => !p);
  };

  const [showSubMenu4, setShowSubMenu4] = useState(true);
  const drop4 = () => {
    setShowSubMenu4((p) => !p);
  };
  const [showSubMenu5, setShowSubMenu5] = useState(true);
  const drop5 = () => {
    setShowSubMenu5((p) => !p);
  };
  const [showSubMenu6, setShowSubMenu6] = useState(true);
  const drop6 = () => {
    setShowSubMenu6((p) => !p);
  };

  const [showSubMenu1, setShowSubMenu1] = useState(true);
  const drop1 = () => {
    setShowSubMenu1((p) => !p);
  };

  const [showSubMenu7, setShowSubMenu7] = useState(false);
  const drop7 = () => {
    setShowSubMenu7((p) => !p);
  };

  const [showSubMenu8, setShowSubMenu8] = useState(true);
  const drop8 = () => {
    setShowSubMenu8((p) => !p);
  };

  const [showSubMenu9, setShowSubMenu9] = useState(false);
  const drop9 = () => {
    setShowSubMenu9((p) => !p);
  };
  const [showSubMenu11, setShowSubMenu11] = useState(false);
  const drop11 = () => {
    setShowSubMenu11((p) => !p);
  };

  const [showSubMenu10, setShowSubMenu10] = useState(false);
  const drop10 = () => {
    setShowSubMenu10((p) => !p);
  };

  const [showSubMenu12, setShowSubMenu12] = useState(false);
  const drop12 = () => {
    setShowSubMenu12((p) => !p);
  };

  const user = useSelector((e) => e.auth.user);

  const renderRoutes1 = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, droptype }) => {
      let returnValue;
      if (
        type === "report" &&
        droptype != "revenue-reports" &&
        droptype != "expenditure" &&
        user?.functionalities?.includes(name)
      ) {
        returnValue = (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRoutesRev = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, droptype }) => {
      let returnValue;
      if (
        droptype === "revenue-reports" &&
        user?.functionalities?.includes(name)
      ) {
        returnValue = (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRouteExp = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, droptype }) => {
      let returnValue;
      if (droptype === "expenditure" && user?.functionalities?.includes(name)) {
        returnValue = (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRoutes8 = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "new_admin" && user?.functionalities?.includes(name)) {
        returnValue = (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRoutes11 = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "ccCopies") {
        returnValue = (
          <NavLink
            key={key}
            to={route}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRoutes4 = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "budget_type" && user?.functionalities?.includes(name)) {
        returnValue = (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRoutes6 = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "mapping" && user?.functionalities?.includes(name)) {
        returnValue = (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRoutes7 = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "m&e" && user?.functionalities?.includes(name)) {
        returnValue = (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRoutes2 = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "budget" && user?.functionalities?.includes(name)) {
        returnValue = (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRoutesVirement = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "Virement") {
        returnValue = (
          <NavLink
            key={key}
            to={route}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRoutesBudgetAmend = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "BudgetAmend") {
        returnValue = (
          <NavLink
            key={key}
            to={route}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
            />
          </NavLink>
        );
      }

      return returnValue;
    }
  );

  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      // alert(JSON.stringify(name))
      if (type === "collapse" && user?.functionalities?.includes(name)) {
        returnValue = href ? (
          <a
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </a>
        ) : (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
          >
            {/* {JSON.stringify(user?.functionalities)} */}
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={
                user?.functionalities?.includes(name)
                  ? key === collapseName
                  : null
              }
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  const renderRoutes9 = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      // alert(JSON.stringify(type));
      if (type === "collection" && user?.functionalities?.includes(name)) {
        returnValue = href ? (
          <a
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </a>
        ) : (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
          >
            {/* {JSON.stringify(user?.functionalities)} */}
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={
                user?.functionalities?.includes(name)
                  ? key === collapseName
                  : null
              }
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  const renderRoutes10 = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "planning" && user?.functionalities?.includes(name)) {
        // alert(JSON.stringify(type));
        returnValue = href ? (
          <a
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </a>
        ) : (
          <NavLink
            key={key}
            to={user?.functionalities?.includes(name) ? route : null}
          >
            {/* {JSON.stringify(user?.functionalities)} */}
            <SidenavCollapse
              name={user?.functionalities?.includes(name) ? name : null}
              icon={user?.functionalities?.includes(name) ? icon : null}
              active={
                user?.functionalities?.includes(name)
                  ? key === collapseName
                  : null
              }
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );
  const _class =
    "link_item border border-1 rounded-start-pill my-1 bg-light font-weight-bold h6 text-success";
  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ whiteSidenav: true }}
      className={` sm-sidebar ${toggleNav ? "" : "open"}`}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            {/* <i className="bi bi-list"></i> */}
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="#" alignItems="center">
          <center style={{ marginTop: -25 }}>
            <CardMedia
              component="img"
              sx={{ width: 130 }}
              image={logo}
              alt="Logo cover"
            />
          </center>
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            style={{ fontWeight: "bold", marginLeft: "1px" }}
          >
            <center>
              <h6
                // component="h6"
                // variant="button"
                // fontWeight="bold"
                // color={textColor}
                style={{ fontWeight: "bold", color: "black" }}
              >
                {brandName}
              </h6>
            </center>
          </MDBox>
        </MDBox>
      </MDBox>
      <center>
        <MDBox px={2}>
          <MDButton
            component="a"
            onClick={() => {
              navigate("/dashboard");
              handleClose();
            }}
            color={sidenavColor}
            style={{ marginRight: 5 }}
          >
            <FaDesktop />
          </MDButton>
          <MDButton
            component="a"
            onClick={() => {
              navigate("/profile");
              handleClose();
            }}
            variant="gradient"
            color="success"
            style={{ marginRight: 5 }}
          >
            <FaUser />
          </MDButton>
          <MDButton
            component="button"
            onClick={() => {
              dispatchR(logout());
              handleClose();
            }}
            variant="gradient"
            color="error"
          >
            <FaSignOutAlt />
          </MDButton>
        </MDBox>
      </center>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />

      {user?.accessTo?.includes("Release Processing") ? (
        <li className={_class} onClick={drop9}>
          <span>
            <FaChartLine className="logo" />
            Release Processing
            {showSubMenu9 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}
      {/* {JSON.stringify({showSubMenu9})} */}
      {user?.accessTo?.includes("Release Processing")
        ? showSubMenu9 && renderRoutes9
        : ""}
      {user?.accessTo?.includes("Virement") ? (
        <li className={_class} onClick={drop10}>
          <span>
            <FaChartLine className="logo" />
            Virement
            {showSubMenu10 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}
      {user?.accessTo?.includes("Virement")
        ? showSubMenu10 && renderRoutes10
        : ""}

      {/* {JSON.stringify(showSubMenu3)} */}
      {user?.accessTo?.includes("Account Chart") ? (
        <li className={_class} onClick={drop4}>
          <span>
            <FaChartLine className="logo" />
            Chart of Account
            {showSubMenu4 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}
      {user?.accessTo?.includes("Account Chart")
        ? showSubMenu4 && renderRoutes
        : ""}

      {/* {renderRoutes} */}

      {user?.accessTo?.includes("Budget Ceiling") ? (
        <li className={_class} onClick={drop2}>
          <span>
            <MdDomainVerification className="logo" /> Main Org ceiling{" "}
            {showSubMenu2 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}
      {user?.accessTo?.includes("Budget Ceiling")
        ? showSubMenu2 && renderRoutes2
        : ""}
      {user?.accessTo?.includes("MDA Mapping") ? (
        <li className={_class} onClick={drop6}>
          <span>
            <MdApi className="logo" />
            MDA Mapping{" "}
            {showSubMenu6 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}
      {user?.accessTo?.includes("MDA Mapping")
        ? showSubMenu6 && renderRoutes6
        : ""}
      {user?.accessTo?.includes("Budget Procceses") ? (
        <li className={_class} onClick={drop5}>
          <span>
            <MdRecordVoiceOver className="logo" />
            Budget Procceses{" "}
            {showSubMenu5 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}
      {user?.accessTo?.includes("Budget Procceses")
        ? showSubMenu5 && renderRoutes4
        : ""}
      {user?.accessTo?.includes("Budget Adjustment") ? (
        <li className={_class} onClick={drop12}>
          <span>
            <FaChartLine className="logo" />
            Budget Adjustment/Amendment
            {showSubMenu12 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}
      {user?.accessTo?.includes("Budget Adjustment")
        ? showSubMenu12 && renderRoutesBudgetAmend
        : ""}
      {/* {user?.accessTo?.includes("Budget Ceiling") ? (
        <li className={_class} onClick={drop1}>
          <span>
            <MdDomainVerification className="logo" />
            Virement{" "}
            {showSubMenu1 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}

      {user?.accessTo?.includes("Budget Ceiling")
        ? showSubMenu1 && renderRoutesVirement
        : ""} */}
      {user?.accessTo?.includes("M And E") ? (
        <li className={_class} onClick={drop7}>
          <span>
            <FaReceipt className="logo" /> M & E
            {showSubMenu7 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}
      {user?.accessTo?.includes("M And E") ? showSubMenu7 && renderRoutes7 : ""}

      {user?.accessTo?.includes("Report") ? (
        <li className={_class} onClick={drop}>
          <span>
            <TbReport className="logo" /> Reports{" "}
            {showSubMenu ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}

      {user?.accessTo?.includes("Report") && showSubMenu && (
        <span>
          {renderRoutes1}
          <span>
            {user?.accessTo?.includes("Report") && (
              <li
                className="link_item  border-1 l my-1  font-weight-bold h6  rounded text-success"
                onClick={droprev}
              >
                <span>
                  <MonetizationOnOutlined className="logo" /> Revenue Reports{" "}
                  {showSubMenurev ? (
                    <IoMdArrowDropdown size="1.8rem" className="" />
                  ) : (
                    <IoMdArrowDropup size="1.8rem" className="" />
                  )}
                </span>
              </li>
            )}
            <span className="mr-5">
              {user?.accessTo?.includes("Report") &&
                showSubMenurev &&
                renderRoutesRev}
            </span>

            {user?.accessTo?.includes("Report") && (
              <li
                className="link_item  border-1 l my-1  font-weight-bold h6 rounded text-success"
                onClick={dropExp}
              >
                <span>
                  <MonetizationOnOutlined className="logo" /> Expenditure
                  Reports{" "}
                  {showSubMenuExp ? (
                    <IoMdArrowDropdown size="1.8rem" className="" />
                  ) : (
                    <IoMdArrowDropup size="1.8rem" className="" />
                  )}
                </span>
              </li>
            )}
            <span className="mr-5">
              {user?.accessTo?.includes("Report") &&
                showSubMenuExp &&
                renderRouteExp}
            </span>
          </span>
        </span>
      )}

      {user?.accessTo?.includes("Admin") ? (
        <li className={_class} onClick={drop8}>
          <span>
            <MdApi className="logo" />
            Admin
            {showSubMenu8 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}

      {user?.accessTo?.includes("set up") ? (
        <li className={_class} onClick={drop3}>
          <span>
            <FaReceipt className="logo" /> Set Up{" "}
            {showSubMenu3 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}
      {user?.accessTo?.includes("Admin") ? showSubMenu8 && renderRoutes8 : ""}
      {user?.accessTo?.includes("CC Copies") ? (
        <li className={_class} onClick={drop11}>
          <span>
            <MdApi className="logo" />
            CC Copies
            {showSubMenu11 ? (
              <IoMdArrowDropdown size="1.8rem" className="" />
            ) : (
              <IoMdArrowDropup size="1.8rem" className="" />
            )}
          </span>
        </li>
      ) : null}
      {user?.accessTo?.includes("CC Copies")
        ? showSubMenu11 && renderRoutes11
        : ""}
    </SidenavRoot>
  );
};

Sidenav.propTypes = {
  brandName: PropTypes.string.isRequired,
  routelogouts: PropTypes.arrayOf(PropTypes.object).isRequired,
  routes: PropTypes.arrayOf.isRequired,
};

export default Sidenav;
