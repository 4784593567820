/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useRef, useState } from "react";
import Scrollbar from "components/Tree/Scrollbar";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import SearchBar from "components/SearchBar";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import { Input } from "reactstrap";
import { capitalizeWords } from "redux/action/api";
import { Spinner } from "reactstrap";
import { unflattenTable } from "redux/action/api";
import { ButtonGroup, Button, Modal } from "reactstrap";
import MDAReportModal from "./MDAReportsModal";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { PDFViewer } from "@react-pdf/renderer";
import ExpenditurePdf from "./ExpenditurePdf";
import { JoinFullSharp } from "@mui/icons-material";
import { headerColors } from "redux/action/api";
import { removeEmptyChildren } from "redux/action/api";

export function arrangeDates(dates) {
  // let actualDates = dates.filter(d => d.split(' ')[1] > 1)
  // Remove the first 3 elements (fixed columns) and sort the remaining dates

  const fDates = dates.map((a) => moment(a, "MMMM, YYYY").format("YYYYMMDD"));
  const sortedDates = dates
    .slice(3)
    .sort(
      (a, b) =>
        moment(a, "MMMM, YYYY").format("YYYYMMDD") -
        moment(b, "MMMM, YYYY").format("YYYYMMDD")
    );
  const sortedFDates = fDates.slice(3).sort((a, b) => a - b);
  console.log(sortedDates);
  console.log(sortedFDates);

  // Concatenate the fixed columns with the sorted dates
  const arrangedDates = dates.slice(0, 3).concat(sortedDates);

  return arrangedDates;
}

export default function ReportsExpShareCom({
  title = "Total Expenditure by Economic",
  route = "/get-report",
  options = [],
  type = "",
  newPdf = false,
}) {
  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
  });
  const years = useYear();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: type,
    title,
  });

  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle1 = () => setDropdownOpen(!dropdownOpen);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}&budget_year=${form.budget_year}`,
      (data) => {
        setLoading(false);
        const tt = unflattenTable(data?.results);
        setTreeData(tt);
        console.log("data?.results", data?.results);
      },
      (err) => console.log(err)
    );
  }, [form.budget_year, selectedOption.value]);

  const sumChildren = (node, columnName) => {
    if (!node.children || node.children.length === 0) {
      return node[columnName];
    } else {
      let sum = node[columnName] || 0;
      for (const child of node.children) {
        sum += sumChildren(child);
      }
      return sum;
    }
  };

  const calculatePersonnel = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.personnel;
    } else {
      let sum = node.personnel || 0;
      for (const child of node.children) {
        sum += calculatePersonnel(child);
      }
      return sum;
    }
  };

  const calculateOverhead = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.overhead;
    } else {
      let sum = node.overhead || 0;
      for (const child of node.children) {
        sum += calculateOverhead(child);
      }
      return sum;
    }
  };

  const calculateCapital = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.capital;
    } else {
      let sum = node.capital || 0;
      for (const child of node.children) {
        sum += calculateCapital(child);
      }
      return sum;
    }
  };

  const calculateRec = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.total_rec;
    } else {
      let sum = node.total_rec || 0;
      for (const child of node.children) {
        sum += calculateRec(child);
      }
      return sum;
    }
  };

  const calculateTotal = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.total_exp;
    } else {
      let sum = node.total_exp || 0;
      for (const child of node.children) {
        sum += calculateTotal(child);
      }
      return sum;
    }
  };

  const newFilter = (item) => {
    return (
      item.overhead === 0 ||
      (item.overhead === null && item.personnel === 0) ||
      (item.personnel === null && item.capital === 0) ||
      item.capital === null
    );
  };

  const newFilterHead = (item) => {
    return (
      calculatePersonnel(item) === 0 &&
      calculateOverhead(item) === 0 &&
      calculateCapital(item) === 0
    );
  };

  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];
  const tableRef = useRef(null);
  const generateTable2 = () => {
    const renderItems = (item, index) => {
      const isTitle =
        item.code === "0000000000000000000" ||
        item.code === "1000000000000000000" ||
        item.code === "2000000000000000000" ||
        item.code === "3000000000000000000" ||
        item.code === "5000000000000000000" ||
        item.code === "7000000000000000000" ||
        item.code === "8000000000000000000";
      return (
        <React.Fragment key={`group_${index}`}>
          {newFilterHead(item) ? null : (
            <tr
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                toggle();
                setSelectMDA(item);
                console.log(item);
              }}
            >
              <td
                style={{
                  backgroundColor: headerColors[item.parentHeads?.length],
                }}
              >
                {isTitle ? "" : item.code.replace(/0+$/, "")}
              </td>
              <td
                style={{
                  backgroundColor: headerColors[item.parentHeads?.length],

                  textDecoration: isTitle ? "underline" : "none",
                }}
              >
                {isTitle ? "Total Expenditure" : item.description}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads?.length],

                  textDecoration: isTitle ? "underline" : "none",
                }}
              >
                {isNaN(calculatePersonnel(item))
                  ? "---"
                  : formatNumber(calculatePersonnel(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads?.length],

                  textDecoration: isTitle ? "underline" : "none",
                }}
              >
                {isNaN(calculateOverhead(item))
                  ? "---"
                  : formatNumber(calculateOverhead(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads?.length],

                  textDecoration: isTitle ? "underline" : "none",
                }}
              >
                {isNaN(calculateRec(item))
                  ? "---"
                  : formatNumber(calculateRec(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads?.length],

                  textDecoration: isTitle ? "underline" : "none",
                }}
              >
                {isNaN(calculateCapital(item))
                  ? "---"
                  : formatNumber(calculateCapital(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads?.length],

                  textDecoration: isTitle ? "underline" : "none",
                }}
              >
                {" "}
                {isNaN(calculateTotal(item))
                  ? "---"
                  : formatNumber(calculateTotal(item))}
              </td>
            </tr>
          )}

          {item.children?.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else if (newFilter(child)) {
              return null;
            } else {
              return (
                <tr key={`row_${index}_${idx}`} style={{ fontSize: "12px" }}>
                  <td>{child.code}</td>
                  <td>{child.description}</td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.personnel)
                      ? "---"
                      : formatNumber(child.personnel)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.overhead)
                      ? "---"
                      : formatNumber(child.overhead)}
                  </td>

                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.total_rec)
                      ? "---"
                      : formatNumber(child.total_rec)}
                  </td>

                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.capital) ? "---" : formatNumber(child.capital)}
                  </td>

                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.total_exp)
                      ? "---"
                      : formatNumber(child.total_exp)}
                  </td>
                </tr>
              );
            }
          })}
        </React.Fragment>
      );
    };

    return (
      <table className="table table-bordered" ref={tableRef}>
        <thead>
          <tr
            style={{
              fontSize: "14px",
              borderWidth: 1,
              borderColor: "black",
              textAlign: "center",
              backgroundColor: "orange",
            }}
          >
            <th style={{ backgroundColor: "orange" }}>Code</th>
            <th style={{ backgroundColor: "orange" }}>Description</th>
            <th style={{ backgroundColor: "orange" }}>Personnel</th>
            <th style={{ backgroundColor: "orange" }}>Overhead</th>
            <th style={{ backgroundColor: "orange" }}>Total Recurrent</th>
            <th style={{ backgroundColor: "orange" }}>Capital</th>
            <th style={{ backgroundColor: "orange" }}>Total Expenditure</th>
          </tr>
        </thead>
        <tbody>{treeData?.map((item, i) => renderItems(item, i))}</tbody>
        {/* {JSON.stringify(treeData.length)} */}
      </table>
    );
  };
  const [pdf, setPdf] = useState(newPdf);
  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <div>
              <Modal
                isOpen={modal}
                toggle={toggle}
                size="lg"
                style={{
                  marginLeft: "30%",
                }}
              >
                <MDAReportModal
                  toggle={toggle}
                  mda_name={selectMDA.description}
                />
              </Modal>
            </div>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {newPdf
                    ? "Appropriation Law"
                    : capitalizeWords(selectedOption.title)}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* {JSON.stringify({ selectedOption })} */}
                <div
                  className="d-flex justify-content-between row"
                  style={{ marginRight: 10, marginLeft: 10 }}
                >
                  <div className="col-sm-2 mb-2">
                    <Input
                      type="select"
                      name="budget_year"
                      value={form.budget_year}
                      onChange={handleChange}
                    >
                      <option>--select--</option>
                      {years?.map((item) => (
                        <option key={item}>{item}</option>
                      ))}
                    </Input>
                  </div>
                  {newPdf ? null : (
                    <div className="col-sm-8 mb-2">
                      <div className="text-center"></div>
                      <center>
                        <ButtonGroup>
                          {options.map((item) => (
                            <Button
                              size="sm"
                              key={item.title}
                              outline={
                                selectedOption.title !== item.title
                                  ? true
                                  : false
                              }
                              color={
                                selectedOption.title === item.title
                                  ? "primary"
                                  : "secondary"
                              }
                              onClick={() =>
                                handleButtonClick(item.value, item.title)
                              }
                            >
                              <small>{item.title}</small>
                            </Button>
                          ))}
                        </ButtonGroup>
                      </center>
                    </div>
                  )}
                  {/* <Button
                    style={{ color: "white" }}
                    className="text-white mb-3 ml-3"
                    size="sm"
                    color="primary"
                  >
                    <CSVLink
                      data={treeData && treeData ? treeData : []}
                      className="text-white"
                      filename={selectedOption.title}
                    >
                      <FaFileDownload color="white" size="1.2rem" /> Download
                      Report
                    </CSVLink>
                  </Button> */}
                  {pdf ? (
                    <Button onClick={() => setPdf(!pdf)} color="danger">
                      Close
                    </Button>
                  ) : (
                    <Dropdown isOpen={dropdownOpen} toggle={toggle1} className="col-sm-2 mb-2">
                      <DropdownToggle
                        className="btn btn-primary text-white ml-3"
                        caret
                      >
                        {/* <FaFileDownload
                          color="white"
                          size="1.2rem"
                          className="mr-5"
                        /> */}
                        Download
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => console.log("Option 1 clicked")}
                        >
                          <DownloadTableExcel
                            filename={selectedOption.title}
                            sheet={selectedOption.title}
                            currentTableRef={tableRef.current}
                          >
                            Export to Excel
                          </DownloadTableExcel>
                        </DropdownItem>
                        <DropdownItem onClick={() => setPdf(!pdf)}>
                          PDF
                        </DropdownItem>
                        <DropdownItem />
                        <DropdownItem
                          onClick={() => console.log("Another option clicked")}
                        >
                          Word
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </div>
                {pdf ? (
                  <PDFViewer height={1000} width={1200}>
                    <ExpenditurePdf
                      data={treeData}
                      year={form.budget_year}
                      newFilter={newFilter}
                      newFilterHead={newFilterHead}
                    />
                  </PDFViewer>
                ) : (
                  <div className="p-3">
                    {loading ? (
                      <div className="loading-container text-center">
                        <Spinner
                          color="primary"
                          style={{ marginTop: "20px" }}
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      <Scrollbar>{generateTable2()}</Scrollbar>
                    )}
                  </div>
                )}
              </MDBox>
              {/* {JSON.stringify(treeData)} */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
