import React from "react";
import RevenueReports from "./RevenueReport";

export default function RevenueReportsDash() {
  const listdata = [
    { value: "total_revenue_by_mda", title: "Total Revenue by MDA" },
    { value: "revenue_by_dep", title: "Revenue by Department" },
  ];
  return (
    <div>
      <RevenueReports
        type="total_revenue_by_mda"
        route="/reports"
        options={listdata}
        title="Total Revenue by MDA"
      />
    </div>
  );
}
