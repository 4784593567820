import moment from "moment";
import { _postApi } from "redux/action/api";

export const getSector = (obj = {}, query = "", cd = (f) => f) => {
  _postApi(
    `/account?query_type=${query}`,
    obj,
    (data) => {
      console.log(data);
      if (data.success && data?.results) {
        cd(data?.results);
      }
    },
    (err) => console.log(err)
  );
};

export const handleDelete = (head = "") => {
  // alert(head)
  _postApi(
    `/account?query_type=delete&head=${head}`,
    {},
    (data) => {
      if (data.success) {
      }
    },
    (err) => console.log(err)
  );
};

export const year = moment().format("YYYY");

export const yearsBeforeAndAfter = () => {
  const currentYear = moment().year();
  const years = [];
  for (let i = currentYear - 8; i <= currentYear + 3; i++) {
    years.push(i);
  }
  return years;
};