import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useCallback, useEffect, useState } from "react";
import { getSector } from "helper";
import { unflatten } from "redux/action/api";
import StructureTree from "components/Tree/StrutureTree";
import Scrollbar from "components/Tree/Scrollbar";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import SearchBar from "components/SearchBar";
import { handleDelete } from "helper";
import { useToasterStore } from "react-hot-toast";
import { _postApi } from "redux/action/api";
import { _fetchApi } from "redux/action/api";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import {
  Button,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Col,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import StructureTree2 from "components/Tree/StructureTree2";
import CustomTypeahead from "components/CustomTypeahead";
import { selectDeepDown } from "redux/action/api";
import { filterData } from "redux/action/api";
import moment from "moment";
import CustomTable from "components/CustomTable";
import { useQuery } from "redux/action/api";
import { removeParentEntries } from "redux/action/api";

function FundMaping() {
  const [results, setResults] = useState();
  const [result1, setResult1] = useState();
  const [years, setYears] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const toggle4 = () => setIsOpen(!isOpen);
  const [update, setUpdate] = useState(false);
  const [view, setView] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const query = useQuery();
  const code = query.get("code");
  const [_filterData, setDataFilter] = useState([]);
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    getSector({ type: "FUND SEGMENT" }, "select", (d) => {
      setRawData(d);
      const modifiedResults = [...d];

      // Find the index where "description" is "FUND SEGMENT"
      const fundSegmentIndex = modifiedResults.findIndex(
        (item) => item.description === "FUND SEGMENT"
      );

      if (fundSegmentIndex !== -1) {
        // If found, empty the "sub_head" for that specific index
        modifiedResults[fundSegmentIndex].sub_head = "";
      }

      // console.log(modifiedResults);

      // Create a copy excluding the first element
      const modifiedResult1 = modifiedResults.slice(1);

      // setResults with the modified array
      setResults(modifiedResult1);

      // Unflatten the modified array
      const formattedData = unflatten(modifiedResults);

      // setResult1 with the unflattened data
      setResult1(formattedData);
    });
  }, []);

  const navigate = useNavigate();
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const [inputs, setInputs] = useState({
    head: "",
    description: "",
    remarks: "",
    type: "",
    mda_code: "",
    mda_name: "",
  });

  const handleChange = ({ target: { name, value } }) => {
    setInputs((p) => ({ ...p, [name]: value }));
  };
  const [damn, setDamn] = useState();
  const { addToast } = useToasts();
  const initialState = {
    accessTo: [],
    role: "admin",
    description: [],
    mda_code: "",
    mda_name: "",
    type: "FUND SEGMENT",
    year: moment().format("YYYY"),
  };
  const [form, setForm] = useState(initialState);

  const handleCheckboxChange = (head, checked) => {
    const parentNode = selectDeepDown(rawData, head);

    if (!parentNode) {
      return;
    }
    const nodesToUpdate = [...parentNode];
    const _filterData1 = filterData(nodesToUpdate);
    setDataFilter(_filterData1);
    console.log(nodesToUpdate);
    if (checked) {
      setForm((prevForm) => ({
        ...prevForm,
        accessTo: [
          ...prevForm.accessTo,
          ...nodesToUpdate.map((node) => ({
            head: node.head,
            description: node.description,
            mda_name: prevForm.mda_name,
            mda_code: prevForm.mda_code,
            sub_head: node.sub_head,
            type: node.type,
          })),
        ],
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        accessTo: prevForm.accessTo.filter(
          (item) => !nodesToUpdate.some((node) => node.head === item.head)
        ),
      }));
    }
  };

  let getOnlyChildren = removeParentEntries(form?.accessTo);

  const handleSubmit = () => {
    if (code === "" || form.mda_code === "") {
      // alert("you must select a MDA before submitting");
      addToast("you must select a MDA before submitting", {
        appearance: "info",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    } else {
      toggle4();
    }
  };

  const [results4, setResult4] = useState([]);
  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });
  }, []);
  let aheadData = results4?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));

  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });
  }, []);

  const confirmSubmit = () => {
    setLoadingSubmit(true);
    _postApi(
      `/mda-mapping/${form.mda_name}?query_type=insert&year=${form.year}&mapping_code=${code}&type=${form.type}`,
      getOnlyChildren,
      (data) => {
        if (data.success) {
          setLoadingSubmit(false);
          setForm(initialState);
          setUpdate(false);
          setIsOpen(false);
          addToast("Saved Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      },
      (err) => {
        setLoadingSubmit(false);
        console.log(err);
      }
    );
  };

  const mappingVerification = useCallback((mda_name) => {
    _fetchApi(
      `/mapping-verification/${mda_name}?type=FUND SEGMENT`,
      (results) => {
        if (results.success) {
          if (results.results.length) {
            setForm((prev) => ({ ...prev, accessTo: results.results }));
            setUpdate(true);
          } else {
            setUpdate(false);
            setForm((prev) => ({ ...prev, accessTo: results.results }));
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    mappingVerification(form.mda_name);
  }, [form.mda_name]);

  const confirmUpdate = () => {
    setLoadingSubmit(true);
    _postApi(
      `/delete-mapping?year=${form.year}&mapping_code=${code}`,
      {},
      (data) => {
        if (data.success) {
          _postApi(
            `/mda-mapping/${form.mda_name}?query_type=insert&year=${form.year}&mapping_code=${code}&type=${form.type}`,
            getOnlyChildren,
            (data) => {
              if (data.success) {
                addToast("Updated Successfully", {
                  appearance: "info",
                  autoDismiss: true,
                  autoDismissTimeout: 5000,
                });
                setLoadingSubmit(false);
                setForm(initialState);
                setIsOpen(false);
                setUpdate(false);
              }
            },
            (err) => {
              setLoadingSubmit(false);
              console.log(err);
            }
          );
        }
      },
      (err) => {
        setLoadingSubmit(false);
        console.log(err);
      }
    );
  };

  const fields = [
    {
      title: "Head",
      custom: true,
      component: (item, index) => item.head,
    },
    {
      title: "Description",
      custom: true,
      component: (item, index) => item.description,
    },
  ];

  const handleSelectItem = (item) => {
    const updatedAvailableItems = rawData.filter((i) => i.head !== item.head);
    const updatedSelectedItems = [...form.accessTo, item];

    setRawData(updatedAvailableItems);
    setForm((prev) => ({ ...prev, accessTo: updatedSelectedItems }));
  };

  const handleRemoveItem = (item) => {
    const updatedSelectedItems = form.accessTo?.filter(
      (i) => i.head !== item.head
    );
    const updatedAvailableItems = [...rawData, item];

    setForm((prev) => ({ ...prev, accessTo: updatedSelectedItems }));
    setRawData(updatedAvailableItems);
  };

  const [searchQuery, setSearchQuery] = useState("");

  let datas = [];

  rawData?.forEach((item) => {
    if (
      item.description &&
      item.description.toLowerCase().indexOf(searchQuery.toLowerCase()) ===
        -1 &&
      item.head &&
      item.head.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1
    )
      return;
    else datas.push(item);
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  className="text-center"
                >
                  Fund Mapping
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <Modal isOpen={modal3} toggle={toggle3} size="lg">
                  <ModalHeader>Continue With</ModalHeader>
                  <ModalBody>
                    <div className=" row mb-4 p-3">
                      {/* {JSON.stringify(damn)} */}
                      <div className="col-md-6">
                        <lable>CODE</lable>
                        <input
                          className="form-control"
                          type="number"
                          name="head"
                          value={inputs.head}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <lable>Description</lable>
                        <input
                          className="form-control"
                          name="description"
                          value={inputs.description}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <lable>Remarks</lable>
                        <input
                          className="form-control"
                          name="remarks"
                          value={inputs.remarks}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-between"></ModalFooter>
                </Modal>
                <div className="row m-3">
                  <div className="col-md-4 ">
                    <CustomTypeahead
                      labelKey="head"
                      label="Select MDA"
                      options={aheadData}
                      onChange={(s) => {
                        const selectedMda = {
                          mda_code: s[0].code,
                          mda_name: s[0].description,
                        };
                        if (s.length) {
                          navigate(`/fund-mapping?code=${s[0].code}`);
                          console.log(s);
                          setForm((p) => ({
                            ...p,
                            mda_code: s[0].code,
                            mda_name: s[0].description,
                          })) || [selectedMda];
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-1 mb-3" />
                  <div className="col-md-6 mt-4">
                    <ButtonGroup className="mb-2 me-2">
                      <Button
                        color={view ? "dark" : "light"}
                        onClick={() => setView("new")}
                      >
                        View
                      </Button>
                      <Button
                        color={!view ? "dark" : "light"}
                        onClick={() => setView(false)}
                      >
                        Tree View
                      </Button>
                      <Button
                        color={view ? "dark" : "light"}
                        onClick={() => setView(true)}
                      >
                        Tree View
                      </Button>
                    </ButtonGroup>

                    {update ? (
                      <button
                        className="btn btn-primary pl-5 pr-5 mb-2"
                        onClick={handleSubmit}
                      >
                        Click to Update
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary pl-5 pr-5 mb-2"
                        onClick={handleSubmit}
                      >
                        Click to Submit
                      </button>
                    )}
                  </div>
                </div>
                {view === "new" ? (
                  <Container>
                    <Row>
                      <Col className="m-0 p-0">
                        <center>
                          <h6>Selected MDA&apos;s mapping here</h6>
                        </center>
                        <SearchBar
                          placeholder="Search By code or description"
                          filterText={searchQuery}
                          onFilterTextChange={handleSearchChange}
                        />
                        <Scrollbar height="50vh">
                          <ListGroup>
                            {datas?.map((item, index) => (
                              <ListGroupItem key={index}>
                                <span style={{ fontSize: "14px" }}>
                                  {item.description}({item.head})
                                </span>

                                <FaArrowRight
                                  size={20}
                                  onClick={() => handleSelectItem(item)}
                                  style={{
                                    float: "right",
                                    color: "blueviolet",
                                  }}
                                />
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </Scrollbar>
                      </Col>
                      <Col className="m-0 p-0">
                        <center>
                          <h6>Selected MDA&apos;s {form?.mda_name}</h6>
                        </center>

                        <Scrollbar height="50vh">
                          <ListGroup>
                            {form?.accessTo?.map((item, index) => (
                              <ListGroupItem key={index}>
                                <FaArrowLeft
                                  size={20}
                                  onClick={() => handleRemoveItem(item)}
                                  style={{
                                    float: "left",
                                    color: "red",
                                    marginRight: 5,
                                  }}
                                />
                                <span
                                  style={{
                                    float: "right",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.description}({item.head})
                                </span>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </Scrollbar>
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  <div className="row">
                    <div className={`col-md-${view ? 8 : 12}`}>
                      <Scrollbar>
                        <StructureTree2
                          treeData={result1}
                          addChild={(node) => {
                            setInputs((p) => ({
                              sub_head: node.head,
                              type: node.type,
                            }));
                            setDamn((p) => ({
                              ...p,
                              name: node.description,
                              type: node.type,
                              head: node.head,
                              hmm: "submit",
                              ids: node.head,
                              sub_head: node.sub_head,
                            }));
                            toggle3();
                          }}
                          editNode={(node, checked) => {
                            handleCheckboxChange(node.head, checked);
                          }}
                          form={form}
                        />
                        {/* </center> */}
                        {/* <center><button className="btn btn-primary" onClick={handleSubmit}>Submit</button></center> */}
                      </Scrollbar>
                      {/* </div>
              <div className='col-md-8'> 
              <SearchBar placeholder='Search By code or description' filterText={searchQuery}  onFilterTextChange={handleSearchChange} />
              <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /></div> */}
                    </div>
                    {view && (
                      <div className={`col-md-${view ? 4 : 0}`}>
                        <Scrollbar height="50vh">
                          <Grid item xs={12}>
                            <div>
                              <div
                                className="text-center"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Selected MDA&apos;s {form?.mda_name}
                              </div>
                              <div>
                                <div
                                  style={{
                                    minHeight: "20vh",
                                    overflow: "scroll",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  <CustomTable
                                    fields={fields}
                                    data={getOnlyChildren}
                                  />
                                </div>
                              </div>
                              <div>
                                {update ? (
                                  <Button
                                    color="primary"
                                    onClick={confirmUpdate}
                                  >
                                    {loadingSubmit ? (
                                      <Spinner size="sm" />
                                    ) : null}{" "}
                                    Confirm & Update
                                  </Button>
                                ) : (
                                  <Button
                                    color="primary"
                                    onClick={confirmSubmit}
                                  >
                                    {loadingSubmit ? (
                                      <Spinner size="sm" />
                                    ) : null}{" "}
                                    Confirm & Submit
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Scrollbar>
                      </div>
                    )}
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Modal isOpen={isOpen} size="lg">
        <ModalHeader
          className="text-center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Selected MDA&apos;s {form?.mda_name}
        </ModalHeader>

        <ModalBody>
          <div
            style={{
              minHeight: "20vh",
              overflow: "scroll",
              margin: 0,
              padding: 0,
            }}
          >
            <CustomTable fields={fields} data={getOnlyChildren} />
          </div>
        </ModalBody>
        <ModalFooter>
          {update ? (
            <Button color="primary" onClick={confirmUpdate}>
              {loadingSubmit ? <Spinner size="sm" /> : null} Confirm & Update
            </Button>
          ) : (
            <Button color="primary" onClick={confirmSubmit}>
              {loadingSubmit ? <Spinner size="sm" /> : null} Confirm & Submit
            </Button>
          )}
          <Button color="secondary" onClick={toggle4}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </DashboardLayout>
  );
}

export default FundMaping;
