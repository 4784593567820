import ReportTotalEco from "./ReportTotalEco.js";
// import ReportsFundShareCom from "./ReportsFundShareCom.js";
const listdata = [
  { value: "mda_expenditure_by_eco", title: "MDA Expenditure by Economic" },
];
function ReportByEco() {
  return (
    <ReportTotalEco
      type="mda_expenditure_by_eco"
      title="MDA Expenditure by Economic"
      route="/reports"
      options={listdata}
    />
  );
}

export default ReportByEco;
