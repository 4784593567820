/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect, useRef, useState } from "react";

// import { FaPlus } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// import { AiFillEdit, AiFillEye } from "react-icons/ai";
// import { FiDelete, FiEdit } from "react-icons/fi";
import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Spinner,
  Row,
  Col,
  Table,
} from "reactstrap";
import useIsPhoneSize from "layouts/useIsPhoneSize";
import { formatNumber } from "redux/action/api";
import CustomTypeahead from "components/CustomTypeahead";
// import { today } from "helper";
import { year } from "helper";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useYear } from "useYearList";
import { checkIfNull } from "redux/action/api";
import { bg } from "redux/action/api";
import { FaEye } from "react-icons/fa";

function Approved() {
  const years = useYear();
  const [form, setForm] = useState({
    budget_year: year,
    economic_code: "",
    economic_description: "",
    program_code: "",
    program_description: "",
    function_code: "",
    function_description: "",
    fund_code: "",
    fund_description: "",
    geo_code: "",
    geo_description: "",
    budget_amount: "",
    actual_amount: "",
    budget_type: "",
    start_date: "",
    end_date: "",
    segment: "",
    transaction_type: "",
    sector_type: "",
  });
  const [searchText, setSearchText] = useState("");
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);

  const fields = [
    {
      title: "Project Description",
      custom: true,
      component: (ite) => <div> {ite.description}</div>,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            toggle3();
            setForm((p) => ({ ...p, ...item, update: true }));
            // console.log(JSON.stringify(item));
            //  console.log(item, "ITEM");
            repRef?.current?.setState({
              text: item.admin_description,
            });
            repRef1?.current?.setState({
              text: item.economic_description,
            });
            repRef2?.current?.setState({
              text: item.fund_description,
            });
            repRef3?.current?.setState({
              text: item.geo_description,
            });
            repRef4?.current?.setState({
              text: item.program_description,
            });
            repRef5?.current?.setState({
              text: item.function_description,
            });
          }}
        >
          {item.economic_code}
        </div>
      ),
    },
    {
      title: "Economic Description",
      custom: true,
      component: (ite) => ite.economic_description,
    },

    { title: "Fund Code", custom: true, component: (ite) => ite.fund_code },
    {
      title: "Fund Description",
      custom: true,
      component: (ite) => ite.fund_description,
    },
    {
      title: "Program Code",
      custom: true,
      component: (ite) => ite.program_code,
    },
    {
      title: "Program Description",
      custom: true,
      component: (ite) => ite.program_description,
    },
    {
      title: "Functional code",
      custom: true,
      component: (ite) => ite.function_code,
    },

    { title: "Geo code", custom: true, component: (ite) => ite.geo_code },
    {
      title: "Geo Description",
      custom: true,
      component: (ite) => ite.geo_description,
    },
    {
      title: "Proposed Amount(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.budget_amount)}</div>
      ),
    },
    {
      title: "MOPB Estimate(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.mopb_amount)}</div>
      ),
    },
    {
      title: "Exco Estimate(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.exco_amount)}</div>
      ),
    },
    {
      title: "House Estimate(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.house_amount)}</div>
      ),
    },
    {
      title: "Approved Estimate(₦)",
      custom: true,
      component: (ite, idx) => (
        <div
          style={{ float: "right" }}
          onDoubleClick={() => handleDoubleClick(idx)}
        >
          {ite.status === "final-house" ? (
            editingIndex === idx ? (
              <Input
                name="approve_amount"
                value={ite.approve_amount}
                onChange={({ target: { value } }) => handleChanges(value, idx)}
                onBlur={handleInputBlur}
                style={{ width: "120px", alignItems: "right" }}
              />
            ) : (
              formatNumber(ite.approve_amount)
            )
          ) : (
            formatNumber(ite.approve_amount)
          )}
        </div>
      ),
    },
    {
      title: "Budget Utilize(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.actual_amount)}</div>
      ),
    },
  ];

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(false);
  let query_type = form.transaction_type !== "" ? "budget_type" : "selects";
  // let data = results&&results.map(item => item.name)
  // budget_type;
  const getBudgetNew = useCallback(() => {
    setLoading(true);
    _fetchApi(
      `/get-budget-new/${form.mda_name}?query_type=${query_type}&budget_year=${form.budget_year}&transaction_type=${form.transaction_type}`,
      (res) => {
        console.log(res.results, "DATA");
        const modifiedResults = res.results
          .map((item) => {
            if (
              item.approve_amount === 0 ||
              item.approve_amount === "" ||
              item.approve_amount === null
            ) {
              return { ...item, approve_amount: item.house_amount };
            }
            return item;
          })
          .filter(
            (item) =>
              item.status === "final-house" || item.status === "approved"
          );

        setResults(modifiedResults);
        console.log(modifiedResults, "DATA");

        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.mda_name, form.budget_year, form.transaction_type, query_type]);

  useEffect(() => {
    getBudgetNew();
    if (!modal3) {
      setForm((p) => ({ ...p, update: false }));
    }
  }, [getBudgetNew, modal3]);

  const [results5, setResults5] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults5);
  }, []);
  const [editingIndex, setEditingIndex] = useState(-1);

  const handleDoubleClick = (index) => {
    setEditingIndex(index);
  };

  const handleInputBlur = () => {
    setEditingIndex(-1);
  };
  const handleChanges = (value, index) => {
    const arr = [...results];
    arr[index].approve_amount = value;
    setResults(arr);
  };
  const { addToast } = useToasts();

  const handleSubmit = (name = "") => {
    const filteredResults = results.filter(
      (item) => item.status === "final-house"
    );
    let type = name === "approved" ? "UPDATE" : "INSERT";
    _postApi(
      `/post-budget?query_type=${type}&status=${name}`,
      name === "approved" ? filteredResults : [form],
      (res) => {
        if (res.success) {
          getBudgetNew();
          addToast("Submitted Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setData([]);
        }
      },
      (err) => {
        console.log(err);
        addToast("Submitted Failed", {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    );
  };

  const handleUpdate = () => {
    _postApi(
      `/update-budget-new/${form.mda_name}?query_type=UPDATE&status=${form.status}&budget_year=${form.budget_year}&transaction_type=${form.transaction_type}`,
      form,
      (res) => {
        if (res.success) {
          getBudgetNew();
          addToast("Updated Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          toggle3();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const totalAmount = results?.reduce(
    (total, item) => total + parseFloat(item?.approve_amount),
    0
  );
  // console.log(results, "RESULTS");
  const [results4, setResult4] = useState([]);
  useEffect(() => {
    getSector({ type: "mdas", sector_type: form.sector_type }, "mdas", (d) => {
      setResult4(d);
    });
  }, [form.sector_type]);

  let aheadData = results4?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));

  const repRef = useRef(null);
  const repRef1 = useRef(null);
  const repRef2 = useRef(null);
  const repRef3 = useRef(null);
  const repRef4 = useRef(null);
  const repRef5 = useRef(null);
  const mdaRef = useRef(null);

  const [results6, setResults6] = useState([]);
  const [results1, setResults1] = useState([]);
  const [results2, setResults2] = useState([]);
  const [results3, setResults3] = useState([]);
  const [results9, setResults9] = useState([]);
  const [results10, setResults10] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults6);
  }, []);

  useEffect(() => {
    getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults1);
  }, []);

  useEffect(() => {
    getSector({ type: "FUNCTIONAL SEGMENT" }, "select", setResults2);
  }, []);

  useEffect(() => {
    getSector({ type: "PROGRAMME SEGMENT" }, "select", setResults3);
  }, []);

  useEffect(() => {
    getSector({ type: "FUND SEGMENT" }, "select", setResults9);
  }, []);

  useEffect(() => {
    getSector({ type: "GEO SEGMENT" }, "select", setResults10);
  }, []);

  let aheadData6 =
    results9 &&
    results9?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData1 =
    results6 &&
    results6?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData2 =
    results2 &&
    results2?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData3 =
    results3 &&
    results3?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData4 =
    results1 &&
    results1?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData5 =
    results10 &&
    results10?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  const handleChanging = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const filteredResults = results?.filter(
    (item) => item.status === "final-house"
  );

  let data = [];

  results?.forEach((item) => {
    if (
      (item.economic_description &&
        item.economic_description
          .toLowerCase()
          .indexOf(searchText.toLowerCase()) === -1) ||
      (item.economic_code &&
        item.economic_code.toLowerCase().indexOf(searchText.toLowerCase()) ===
          -1)
    )
      return;
    else data.push(item);
  });

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const [approveData, setApproveData] = useState([]);
  const getApproveData = () => {
    _postApi(
      "/get-approval-by-date",
      {
        mda_name: form.mda_name,
        mda_code: form.mda_code,
        start_date: form.start_date,
        end_date: form.end_date,
      },
      (resp) => {
        // alert(JSON.stringify(resp));
        setApproveData(resp.results);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getApproveData();
  }, [form.mda_name, form.mda_code, form.start_date, form.end_date]);

  const customLabelKey = (option) => `${option.description}`;
  const isPhoneSize = useIsPhoneSize();

  return (
    <DashboardLayout>
      {form.mda_name && (
        <center>
          <h2>{/* {form.mda_code} - {form.mda_name} */}</h2>
        </center>
      )}
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  className="text-center"
                >
                  Approved Recommendation Page
                </MDTypography>
              </MDBox>
              <div className="">
                <Button
                  color="primary"
                  className="pr-5 pl-5 m-4"
                  style={{ float: "right" }}
                  onClick={() => toggle3()}
                >
                  Add New Proposal
                </Button>
              </div>

              <Modal isOpen={modal3} toggle={toggle3} size="lg">
                <ModalHeader>Continue With</ModalHeader>
                <ModalBody>
                  {/* {JSON.stringify(form)} */}
                  <div className=" row mb-4 p-3">
                    {/* <div className="col-md-6 ">
                      <Label>Select budget year</Label>
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div> */}

                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef1}
                        labelKey={customLabelKey}
                        label="Economic Description"
                        options={aheadData4}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              economic_description: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.economic_description}
                      />
                    </div>

                    <div className="col-md-6">
                      <small>Economic Code</small>
                      <input
                        className="form-control"
                        name="economic_code"
                        value={form.economic_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef}
                        labelKey={customLabelKey}
                        label="Adminstrative Description"
                        options={aheadData1}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              admin_description: s[0].description,
                              mda_name: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.admin_description}
                      />
                    </div>
                    <div className="col-md-6">
                      <small>Admin Code</small>
                      <input
                        className="form-control"
                        name="admin_code"
                        value={form.admin_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef2}
                        labelKey={customLabelKey}
                        label="Fund Description"
                        options={aheadData6}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              fund_description: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.fund_description}
                      />
                    </div>

                    <div className="col-md-6">
                      <small>Fund Code</small>
                      <input
                        className="form-control"
                        name="fund_code"
                        value={form.fund_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef3}
                        labelKey={customLabelKey}
                        label="Geo Description"
                        options={aheadData5}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              geo_description: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.geo_description}
                      />
                    </div>

                    <div className="col-md-6">
                      <small>Geo Code</small>
                      <input
                        className="form-control"
                        name="geo_code"
                        value={form.geo_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef4}
                        labelKey={customLabelKey}
                        label="Program Description"
                        options={aheadData3}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              program_description: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.program_description}
                      />
                    </div>
                    <div className="col-md-6">
                      <small>Program Code</small>
                      <input
                        className="form-control"
                        name="program_code"
                        value={form.program_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef5}
                        labelKey={customLabelKey}
                        label="Functional Description"
                        options={aheadData2}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              function_description: s[0].description,
                            }));
                          }
                        }}
                        defaultInputValue={form.function_description}
                      />
                    </div>
                    <div className="col-md-6">
                      <small>Function Code</small>
                      <input
                        className="form-control"
                        name="function_code"
                        value={form.function_code}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <small>
                        Budget Amount ({formatNumber(form.budget_amount)})
                      </small>
                      <input
                        className="form-control"
                        name="budget_amount"
                        value={form.budget_amount}
                        onChange={handleChanging}
                      />
                    </div>
                    <div className="col-md-6">
                      <small>
                        Approved Amount ({formatNumber(form.approve_amount)})
                      </small>
                      <input
                        className="form-control"
                        type="number"
                        maxLength="4"
                        name="approve_amount"
                        value={form.approve_amount}
                        onChange={handleChanging}
                      />
                    </div>
                  </div>
                  <center>
                    <button
                      className="btn btn-success px-5 m-1"
                      onClick={() => {
                        if (form.update) {
                          handleUpdate();
                        } else {
                          handleSubmit("final-house");
                        }
                        toggle3();
                      }}
                    >
                      {form.update ? "Update" : "Submit"}
                    </button>

                    <button
                      className="btn btn-danger px-5 m-1"
                      onClick={() => {
                        toggle3();
                      }}
                    >
                      Close
                    </button>
                  </center>
                </ModalBody>
              </Modal>
              <MDBox>
                <MDBox pt={0}>
                  <div className=" row p-3">
                    <div className="col-md-3 mb-3">
                      <label>Select Sector</label>
                      <select
                        name="sector_type"
                        onChange={handleChange}
                        className="form-control"
                        value={form.sector_type}
                      >
                        <option>...Select...</option>
                        <option value="01%">Adminstrative Sector</option>
                        <option value="02%">Economic Sector</option>
                        <option value="05%">Social Sector</option>
                        <option value="03%">Law And Justice</option>
                      </select>
                    </div>

                    <div className="col-md-4 mb-3">
                      <CustomTypeahead
                        labelKey="head"
                        label="Select MDA"
                        ref={mdaRef}
                        options={aheadData}
                        onChange={(s) => {
                          if (s.length) {
                            console.log(s);
                            setForm((p) => ({
                              ...p,
                              mda_code: s[0].code,
                              mda_name: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <label>Budget Type</label>
                      <select
                        name="transaction_type"
                        onChange={handleChange}
                        className="form-control"
                        value={form.transaction_type}
                      >
                        <option value="">...Select...</option>
                        <option value="Revenue">Revenue</option>
                        <option value="Capital">Capital</option>
                        <option value="Capital Receipt">Capital Receipt</option>
                        <option value="Persornnel">Persornnel</option>
                        <option value="OverHead">OverHead</option>
                      </select>
                    </div>
                    {/* {JSON.stringify({ form: form.transaction_type })} */}
                    <div className="col-md-2">
                      <Label>Select budget year</Label>
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                    <div className={`${isPhoneSize} ? mt-4 : null`}>
                      <SearchBar
                        placeholder="Search By code or description"
                        filterText={searchText}
                        onFilterTextChange={handleSearchChange}
                      />
                    </div>
                    <div>
                      {" "}
                      <Button
                        color="success"
                        className="pr-5 pl-5 m-5 mt-0"
                        style={{ float: "right" }}
                        // className="m-3"
                        onClick={() => toggle()}
                        // disabled={filteredResults?.length === 0 ? true : false}
                      >
                        <FaEye /> View
                      </Button>
                      <Button
                        color="primary"
                        className="pr-5 pl-5 "
                        style={{ float: "right" }}
                        onClick={() => handleSubmit("approved")}
                        disabled={filteredResults?.length === 0 ? true : false}
                      >
                        Submit
                      </Button>
                    </div>
                    <span style={{ fontWeight: "bold", float: "right" }}>
                      TOTAL : ₦{totalAmount?.toLocaleString()}
                    </span>
                    {loading && (
                      <div className="loading-container text-center">
                        <Spinner
                          color="primary"
                          style={{ marginTop: "20px" }}
                        />{" "}
                        Loading...
                      </div>
                    )}
                    {/* {JSON.stringify(data)} */}
                    <Scrollbar>
                      <CustomTable fields={fields} data={data} type="approve" />
                    </Scrollbar>
                    <center>
                      <Modal
                        style={{
                          marginRight: "100px",
                          marginTop: isPhoneSize ? "120px" : "",
                        }}
                        isOpen={modal}
                        toggle={toggle}
                        size="lg"
                        centered="true"
                        fullscreen="sm"
                      >
                        <ModalHeader>View Utilize Budget</ModalHeader>
                        <ModalBody>
                          {/* {JSON.stringify(approveData)} */}
                          <Row>
                            <Col md={6}>
                              <label>Date From</label>
                              <Input
                                name="start_date"
                                value={form.start_date}
                                onChange={handleChange}
                                type="date"
                              />
                            </Col>
                            <Col md={6}>
                              <label>Date To</label>
                              <Input
                                name="end_date"
                                value={form.end_date}
                                onChange={handleChange}
                                type="date"
                              />
                            </Col>
                          </Row>

                          <Table bordered striped className="mt-3" responsive>
                            <thead>
                              <tr className="sm-th-text">
                                <th style={{ width: "20%" }}>Approval Date</th>
                                <th style={{ width: "20%" }}>MDA Name</th>
                                <th style={{ width: "20%" }}>MDA Code</th>
                                <th style={{ width: "20%" }}>Economic Code</th>
                                <th style={{ width: "10%" }}>Amount</th>
                                <th style={{ width: "20%" }}>Approval Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {approveData &&
                                approveData[0]?.map((item, index) => (
                                  <tr key={index} className="sm-text">
                                    <td>{item.approval_date}</td>
                                    <td>{item.mda_name}</td>
                                    <td>{item.mda_name}</td>
                                    <td>{item.economic_code}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.approval_type}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          {isPhoneSize ? (
                            <button
                              className="btn btn-danger px-5 m-1 float-right"
                              onClick={() => {
                                toggle();
                              }}
                            >
                              Close
                            </button>
                          ) : (
                            ""
                          )}
                        </ModalBody>
                      </Modal>
                    </center>
                    {/* <center>
                      <Button
                        color="primary"
                        className="pr-5 pl-5"
                        onClick={() => handleSubmit("approved")}
                        disabled={filteredResults?.length === 0 ? true : false}
                      >
                        Submit
                      </Button> 
                    </center>*/}
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Approved;
