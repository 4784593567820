/* eslint-disable react/prop-types */
import React from "react";
import { Button, Table } from "reactstrap";

export default function SelectedDataTable({ selectedData, showTable }) {
  return (
    <Table
      style={
        showTable
          ? {
              width: "100%",
            }
          : {
              display: "none",
              width: "100%",
            }
      }
      bordered
      striped
    >
      <thead>
        <tr>
          <th
            style={{
              fontSize: 15,
            }}
          >
            MDA Name
          </th>
          <th
            style={{
              fontSize: 15,
            }}
          >
            Administrative Code
          </th>
          <th
            style={{
              fontSize: 15,
            }}
          >
            Economic Code
          </th>
          <th
            style={{
              fontSize: 15,
            }}
          >
            Description
          </th>
          <th
            style={{
              fontSize: 15,
            }}
          >
            Amount
          </th>
          <th
            style={{
              fontSize: 15,
            }}
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {selectedData.map((data, index) => (
          <tr key={index}>
            <td
              style={{
                fontSize: 15,
              }}
            >
              {data.mda_name}
            </td>
            <td
              style={{
                fontSize: 15,
              }}
            >
              {data.mda_code}
            </td>
            <td
              style={{
                fontSize: 15,
              }}
            >
              {data.mda_economic_code}
            </td>
            <td
              style={{
                fontSize: 15,
              }}
            >
              {data.description}
            </td>
            <td
              style={{
                fontSize: 15,
              }}
            >
              {data.approved_amount}
            </td>
            <td
              style={{
                fontSize: 15,
              }}
            >
              <Button size="sm">Delete</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
