import { yearsBeforeAndAfter } from "helper";
import React, { useState, useEffect } from "react";

export function useYear() {
  const [years, setYears] = useState([]);

  useEffect(() => {
    setYears(yearsBeforeAndAfter());
  }, []);

  return years;
}
