// CoverPage.js
import React, { useEffect, useState } from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import DM_SANS_NORMAL from "../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../../assets/DM_Sans/DM_Sans/static/DMSans-ExtraBold.ttf";
import DM_SANS_ITALIC from "../../assets/DM_Sans/DM_Sans/static/DMSans-Italic.ttf";
import TAHOMA_NORMAL from "../../assets/Tahoma/static/TAHOMA_0.TTF";
import TAHOMA_BOLD from "../../assets/Tahoma/static/TAHOMAB0.TTF";
import { _fetchApi } from "redux/action/api";
import moment from "moment";
import { useYear } from "useYearList";
import { formatNumbers } from "redux/action/api";
import { capitalizeWords } from "redux/action/api";
import { unflattenTable } from "redux/action/api";
import { headerColors } from "redux/action/api";

Font.register(
  {
    family: "DM_SANS",
    fonts: [
      { src: DM_SANS_NORMAL },
      {
        src: DM_SANS_BOLD,
        // fontStyle: "bold",
        // fontWeight: 700,
      },
      {
        src: DM_SANS_ITALIC,
        fontStyle: "italic",
      },
    ],
  },
  {
    family: "Tahoma",
    fonts: [
      { src: TAHOMA_NORMAL },
      {
        src: TAHOMA_BOLD,
      },
    ],
  }
);
const styles = StyleSheet.create({
  page: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontFamily: "DM_SANS",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 30,
  },
  logo: {
    width: 350,
    height: 100,
    marginBottom: 100,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 20,
  },
  dateContainer: {
    backgroundColor: "#d3d3d3",
    padding: 10,
    textAlign: "center",
    marginTop: 50,
  },
  dateText: {
    fontSize: 14,
  },
  underline: {
    borderBottom: "1px solid black",
    width: "100%",
    marginBottom: 20,
  },
});

const CoverPage = () => (
  <Page size="A4" style={styles.page}>
    <View style={styles.header}>
      <Image style={styles.logo} src={require("./Picture 1.png")} />
      <Text style={styles.title}>Kastina State Government</Text>
      <View style={styles.underline}></View>
      <Text style={styles.subtitle}>
        FIRST QUARTER 2024 BUDGET PERFORMANCE REPORT
      </Text>
    </View>
    <View style={styles.dateContainer}>
      <Text style={styles.dateText}>Date of Publication</Text>
      <Text style={styles.dateText}>(APRIL, 2024)</Text>
    </View>
  </Page>
);

export default CoverPage;

// SummaryPage.js

const styles1 = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    lineHeight: 1.5,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    color: "#6d6d6d",
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: "bold",
    fontFamily: "DM_SANS",
  },
  subsectionTitle: {
    fontSize: 12,
    marginBottom: 5,
    fontWeight: "bold",
    fontFamily: "DM_SANS",
  },
  text: {
    marginBottom: 10,
  },
  boldText: {
    fontWeight: "bold",
    fontFamily: "DM_SANS",
  },
  smallMargin: {
    marginBottom: 5,
  },
  indent: {
    marginLeft: 15,
  },
  bottomBar: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 8,
    color: "#a9a9a9",
  },
});

export const SummaryPage = () => (
  <Page size="A4" style={styles1.page}>
    <Text style={styles1.header}>Kastina State Government</Text>
    <Text style={styles1.sectionTitle}>1 Summary of Performance</Text>
    <Text style={styles1.subsectionTitle}>1.A Introduction</Text>
    <Text style={styles1.text}>
      This Budget Performance Report for Kastina State is prepared quarterly and
      issued within 4 weeks from the end of each quarter.
    </Text>
    <Text style={styles1.text}>
      The report includes the original approved budget appropriation for the
      year 2024 against each organizational unit for each of the core economic
      classification of expenditures (Personnel, Overheads, Capital and Others);
      the actual expenditures for the quarter One (Quarter 1), attributed to
      each organizational unit, as well as the cumulative expenditures for year
      to date, and balances against each of the revenue and expenditure
      appropriations.
    </Text>
    <Text style={styles1.text}>
      This First Quarter report is assessed against the 2024 originally approved
      budget with a total outlay of{" "}
      <Text style={styles1.boldText}>₦437,338,312,787.93</Text>.
    </Text>
    <Text style={styles1.text}>
      It is pertinent to note that, in the course of implementation of the 2024
      Budget certain Budget provisions were over performed in the area of
      Revenue and Expenditure due to the uncertainty happening in the exchange
      market (<Text style={styles1.boldText}>₦</Text> VS{" "}
      <Text style={styles1.boldText}>$</Text>) which reshaping all economic
      activities in the state and nation general. Even Hajji Pilgrim activity
      unless the government contribution. Therefore, all the affected Budget
      Provisions will be formalised which will be forwarded to the State House
      of Assembly for the passage.
    </Text>
    <Text style={styles1.text}>
      This Budget Performance Report is produced by the Ministry of Planning and
      Budget in collaboration with Account General Office of the Ministry of
      Finance and published on the Kastina State website.
    </Text>
    <Text style={styles1.subsectionTitle}>1.B Revenue Performance</Text>
    <Text style={styles1.text}>The Revenue comprises of the:</Text>
    <Text style={[styles1.text, styles1.indent]}>i. Recurrent Revenue</Text>
    <Text style={[styles1.text, styles1.indent]}>ii. Capital Receipts</Text>
    <Text style={styles1.text}>
      In the 2024 Approved Budget, the Total Revenue was projected to be{" "}
      <Text style={styles1.boldText}>₦437,338,312,787.93</Text>. The actual
      revenue for the 2024 (Q1) is{" "}
      <Text style={styles1.boldText}>₦73,611,419,294.62</Text> which gives a
      revenue performance of <Text style={styles1.boldText}>16.8%</Text> from
      January-March 2024.
    </Text>
    <Text style={styles1.text}>
      The Government Share of FAAC and the Independent Revenue forms part of the
      Recurrent Revenue.
    </Text>
    <Text style={styles1.text}>
      The 2024 Recurrent Revenue Budget was projected to be{" "}
      <Text style={styles1.boldText}>₦379,762,289,575.00</Text> with an actual
      of <Text style={styles1.boldText}>₦71,611,419,294.62</Text> for the first
      quarter January to March resulting to a Performance of{" "}
      <Text style={styles1.boldText}>18.9%</Text> out of which the Government
      share of FAAC is <Text style={styles1.boldText}>₦277,805,415,034.00</Text>{" "}
      with an actual of <Text style={styles1.boldText}>₦62,013,974,971.69</Text>{" "}
      for the quarter under review resulting to a performance of{" "}
      <Text style={styles1.boldText}>22.3%</Text>. while the Independent Revenue
      was projected to be{" "}
      <Text style={styles1.boldText}>₦101,956,874,541.00</Text> with an actual
      of <Text style={styles1.boldText}>₦9,597,444,322.93</Text> for the quarter
      under review resulting to a Performance of{" "}
      <Text style={styles1.boldText}>9.4%</Text>.
    </Text>
    <Text style={styles1.text}>
      The Capital Receipts was projected to be{" "}
      <Text style={styles1.boldText}>₦41,765,037,223.00</Text> with an actual of{" "}
      <Text style={styles1.boldText}>₦2,000,000,000.00</Text> as draw dawn data
      as the First quarter and this gives a variance of{" "}
      <Text style={styles1.boldText}>₦39,765,037,223.00</Text> resulting to a
      Performance of <Text style={styles1.boldText}>4.8%</Text>. However, it is
      equally important to note that efforts are on-going to create effective
      strategy amongst MDAs to ensure timely availability of Capital Receipts
      data in the subsequent Reports. We take first quarter as starting point of
      Capital expenditure.
    </Text>
    <Text style={styles1.subsectionTitle}>
      1.C Recurrent Expenditure Performance
    </Text>
    <Text style={styles1.text}>The Recurrent Expenditure is divided into:</Text>
    <Text style={[styles1.text, styles1.indent]}>i. The Personnel Cost</Text>
    <Text style={[styles1.text, styles1.indent]}>
      ii. Other Recurrent Expenditure
    </Text>
    <View style={styles1.bottomBar}>
      <Text>Budget Performance Report – 2024 Quarter 1</Text>
      <Text>Page | 2</Text>
    </View>
  </Page>
);

const styles2 = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    lineHeight: 1.5,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    color: "#6d6d6d",
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: "bold",
    fontFamily: "DM_SANS",
  },
  subsectionTitle: {
    fontSize: 12,
    marginBottom: 5,
    fontWeight: "bold",
    fontFamily: "DM_SANS",
  },
  text: {
    marginBottom: 10,
  },
  boldText: {
    fontWeight: "bold",
    fontFamily: "DM_SANS",
  },
  indent: {
    marginLeft: 15,
  },
  bottomBar: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 8,
    color: "#a9a9a9",
  },
});
export const Summary2 = () => (
  <Page size="A4" style={styles2.page}>
    <Text style={styles2.header}>Kastina State Government</Text>
    <Text style={styles2.sectionTitle}>1 Summary of Performance</Text>
    <Text style={styles2.subsectionTitle}>
      1.D Capital Expenditure Performance
    </Text>
    <Text style={styles2.text}>
      The Capital Expenditure was projected to be{" "}
      <Text style={styles2.boldText}>₦279,835,094,304.21</Text> with an actual
      of <Text style={styles2.boldText}>₦14,045,087,493.03</Text> for the first
      quarter resulting to a Performance of{" "}
      <Text style={styles2.boldText}>5.0%</Text>. Normally, capital expenditure
      is expected to start growing in performance in the second quarter of each
      year, not in the first quarter.
    </Text>
    <Text style={styles2.subsectionTitle}>1.E Conclusions</Text>
    <Text style={styles2.text}>
      In Conclusion, the 2024 Budget size was projected to be{" "}
      <Text style={styles2.boldText}>₦437,338,312,787.93</Text> giving an actual
      revenue performance of{" "}
      <Text style={styles2.boldText}>₦73,611,419,294.62</Text> for the first
      quarter representing <Text style={styles2.boldText}>16.8%</Text>{" "}
      achievement with corresponding expenditure of{" "}
      <Text style={styles2.boldText}>₦48,451,873,712.65</Text> which represents{" "}
      <Text style={styles2.boldText}>11.1%</Text> performance. The state is also
      working hard to harness more of its capital receipt projections so as to
      improve the performance in subsequent quarters.
    </Text>
    <View style={styles2.bottomBar}>
      <Text>Budget Performance Report – 2024 Quarter 1</Text>
      <Text>Page | 3</Text>
    </View>
  </Page>
);

const styles3 = StyleSheet.create({
  page: {
    padding: 30,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#ffc107",
    padding: 5,
  },
  tableCol: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "DM_SANS",
    color: "#000",
  },
  tableCell: {
    fontSize: 10,
  },
  tableCell2: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "DM_SANS",
  },
});

// PDF document component
export const BudgetSummaryPdf = ({ data, totals, form }) => {
  return (
    <Page size="A4" style={styles3.page}>
      <View style={styles3.table}>
        <View style={styles3.tableRow}>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>Item</Text>
          </View>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>2024 Original Budget</Text>
          </View>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>2024 Q1 Performance</Text>
          </View>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>
              2024 Performance Year to Date (Q1)
            </Text>
          </View>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>
              % Performance Year to Date against 2024 Original Budget
            </Text>
          </View>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>
              Balance (against Original Budget)
            </Text>
          </View>
        </View>
        {Object.keys(data).map((category) => (
          <React.Fragment key={category}>
            <View style={styles3.tableRow}>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>
                  <Text style={styles2.boldText}>
                    {capitalizeWords(category)}
                  </Text>
                </Text>
              </View>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>
                  {" "}
                  {formatNumbers(totals[category]?.approve_amount)}
                </Text>
              </View>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>
                  2,500 {JSON.stringify(form)}
                </Text>
              </View>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>2,500 {category}</Text>
              </View>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>25%</Text>
              </View>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>
                  {" "}
                  {formatNumbers(totals[category]?.approve_amount)}
                </Text>
              </View>
            </View>

            {data[category].map((item) => (
              <View style={styles3.tableRow} key={item.item}>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>
                    {capitalizeWords(item.item).toUpperCase()}
                  </Text>
                </View>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>4,000</Text>
                </View>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>1,000</Text>
                </View>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>1,000</Text>
                </View>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>25%</Text>
                </View>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>3,000</Text>
                </View>
              </View>
            ))}
          </React.Fragment>
        ))}
      </View>
    </Page>
  );
};

BudgetSummaryPdf.propTypes = {
  data: PropTypes.array,
  totals: PropTypes.array,
  form: PropTypes.array,
};

export const OverView = ({}) => {
  const [results, setResults] = useState();
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: "budget_overview",
  });
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const options = [];
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const [selectedOption, setSelectedOption] = useState({
    value: "budget_overview",
    title: "Budget Overview",
  });

  const handleChangeSelect = (event) => {
    const selectedIndex = event.target.value;
    setSelectedOption(options[selectedIndex]);
  };

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/get-report?query_type=${selectedOption.value}&budget_year=${form.budget_year}`,
      (data) => {
        setResults(data?.results);
        setLoading(false);
        // console.log(data?.results, "RAW DATA");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value]);

  const groupedData = results?.reduce((acc, item) => {
    const economicCode = item.economic_code.substring(0, 2);
    const group =
      economicCode === "11"
        ? "11 - Govt. Share of FAAC"
        : economicCode === "12"
        ? "12 - Independent Revenue"
        : economicCode === "21"
        ? "21 - Personnel Cost"
        : economicCode === "22"
        ? "22 - Other Recurrent Costs"
        : economicCode === "13"
        ? "13 - AID AND GRANTS"
        : economicCode === "14"
        ? "14 - Capital Development (CDF) Receipts"
        : economicCode === "23"
        ? "Capital_Expenditure"
        : "Total_Expenditure" || "Total_Revenue" || "Closing_Balance";

    const category =
      group === "11 - Govt. Share of FAAC" ||
      group === "12 - Independent Revenue"
        ? "Recurrent_Revenue"
        : group === "21 - Personnel Cost" ||
          group === "22 - Other Recurrent Costs"
        ? "Recurrent_Expenditure"
        : group === "13 - AID AND GRANTS" ||
          group === "14 - Capital Development (CDF) Receipts"
        ? "Other_Receipts"
        : group === "Capital_Expenditure"
        ? "Capital_Expenditure"
        : "Total_Expenditure" || "Total_Revenue" || "Closing_Balance";

    if (!acc[group]) {
      acc[group] = {
        category,
        prev_year2_actual_amount: 0,
        prev_year1_approve_amount: 0,
        prev_year1_actual_amount: 0,
        budget_amount: 0,
        approve_amount: 0,
      };
    }

    acc[group].prev_year2_actual_amount += item.prev_year2_actual_amount || 0;
    acc[group].prev_year1_approve_amount += item.prev_year1_approve_amount || 0;
    acc[group].prev_year1_actual_amount += item.prev_year1_actual_amount || 0;
    acc[group].budget_amount += item.budget_amount || 0;
    acc[group].approve_amount += item.approve_amount || 0;

    return acc;
  }, {});

  // Initialize totals
  let totals = {
    Recurrent_Revenue: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Recurrent_Expenditure: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Transfer_To_Capital_Account: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Other_Receipts: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Capital_Expenditure: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Total_Revenue: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Total_Expenditure: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Closing_Balance: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Opening_Balance: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 15663016826.3199,
      prev_year1_actual_amount: 0,
      budget_amount: 15810985989.93,
      approve_amount: 15810985989.93,
    },
  };
  // Calculate totals
  Object.keys(groupedData || {}).forEach((group) => {
    const category = groupedData[group].category;
    if (category) {
      Object.keys(totals[category] || {}).forEach((key) => {
        totals[category][key] += groupedData[group][key];
      });
    }
    if (
      category === "Recurrent_Revenue" ||
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals.Transfer_To_Capital_Account.prev_year2_actual_amount =
        totals.Recurrent_Revenue.prev_year2_actual_amount +
        totals.Opening_Balance.prev_year2_actual_amount -
        totals.Recurrent_Expenditure.prev_year2_actual_amount;

      totals.Transfer_To_Capital_Account.prev_year1_approve_amount =
        totals.Opening_Balance.prev_year1_approve_amount +
        totals.Recurrent_Revenue.prev_year1_approve_amount -
        totals.Recurrent_Expenditure.prev_year1_approve_amount;

      totals.Transfer_To_Capital_Account.prev_year1_actual_amount =
        totals.Opening_Balance.prev_year1_actual_amount +
        totals.Recurrent_Revenue.prev_year1_actual_amount -
        totals.Recurrent_Expenditure.prev_year1_actual_amount;

      totals.Transfer_To_Capital_Account.budget_amount =
        totals.Opening_Balance.budget_amount +
        totals.Recurrent_Revenue.budget_amount -
        totals.Recurrent_Expenditure.budget_amount;

      totals.Transfer_To_Capital_Account.approve_amount =
        totals.Opening_Balance.approve_amount +
        totals.Recurrent_Revenue.approve_amount -
        totals.Recurrent_Expenditure.approve_amount;
    }
    // total revenue
    if (category === "Recurrent_Revenue" || category === "Other_Receipts") {
      totals.Total_Revenue.prev_year2_actual_amount =
        parseFloat(totals.Recurrent_Revenue.prev_year2_actual_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.prev_year2_actual_amount);

      totals.Total_Revenue.prev_year1_approve_amount =
        parseFloat(totals.Recurrent_Revenue.prev_year1_approve_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.prev_year1_approve_amount);

      totals.Total_Revenue.prev_year1_actual_amount =
        parseFloat(totals.Opening_Balance.prev_year1_actual_amount) +
        parseFloat(totals.Recurrent_Revenue.prev_year1_actual_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.prev_year1_actual_amount);

      totals.Total_Revenue.budget_amount =
        parseFloat(totals.Opening_Balance.budget_amount) +
        parseFloat(totals.Recurrent_Revenue.budget_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.budget_amount);

      totals.Total_Revenue.approve_amount =
        parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Recurrent_Revenue.approve_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.approve_amount);
    }
    // Total Expenditure
    if (
      category === "Capital_Expenditure" ||
      category === "Recurrent_Expenditure"
    ) {
      totals.Total_Expenditure.prev_year2_actual_amount =
        parseFloat(totals.Capital_Expenditure.prev_year2_actual_amount) +
        parseFloat(totals.Recurrent_Expenditure.prev_year2_actual_amount);

      totals.Total_Expenditure.prev_year1_approve_amount =
        parseFloat(totals.Capital_Expenditure.prev_year1_approve_amount) +
        parseFloat(totals.Recurrent_Expenditure.prev_year1_approve_amount);

      totals.Total_Expenditure.prev_year1_actual_amount =
        parseFloat(totals.Capital_Expenditure.prev_year1_actual_amount) +
        parseFloat(totals.Recurrent_Expenditure.prev_year1_actual_amount);

      totals.Total_Expenditure.budget_amount =
        parseFloat(totals.Capital_Expenditure.budget_amount) +
        parseFloat(totals.Recurrent_Expenditure.budget_amount);

      totals.Total_Expenditure.approve_amount =
        parseFloat(totals.Capital_Expenditure.approve_amount) +
        parseFloat(totals.Recurrent_Expenditure.approve_amount);
    }

    // Closing balance

    if (
      category === "Total_Revenue" ||
      category === "Total_Expenditure" ||
      category === "Capital_Expenditure" ||
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals.Closing_Balance.prev_year2_actual_amount =
        parseFloat(totals.Total_Revenue.prev_year2_actual_amount || 0) -
        parseFloat(totals.Total_Expenditure.prev_year2_actual_amount || 0);

      totals.Closing_Balance.prev_year1_approve_amount =
        parseFloat(totals.Total_Revenue.prev_year1_approve_amount) -
        parseFloat(totals.Total_Expenditure.prev_year1_approve_amount);

      totals.Closing_Balance.prev_year1_actual_amount =
        parseFloat(totals.Total_Revenue.prev_year1_actual_amount) -
        parseFloat(totals.Total_Expenditure.prev_year1_actual_amount);

      totals.Closing_Balance.budget_amount =
        parseFloat(totals.Total_Revenue.budget_amount) -
        parseFloat(totals.Total_Expenditure.budget_amount);

      totals.Closing_Balance.approve_amount =
        parseFloat(totals.Total_Revenue.approve_amount) -
        parseFloat(totals.Total_Expenditure.approve_amount);
    }

    if (
      category === "Capital_Expenditure" ||
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals.Opening_Balance.prev_year1_approve_amount = 15663016826.32;

      totals.Opening_Balance.budget_amount = 15810985989.93;

      totals.Opening_Balance.approve_amount = 15810985989.93;
    }
  });

  // console.log(groupedData, "LALALAL");

  const report = {
    Opening_Balance: [],
    Recurrent_Revenue: [],
    Recurrent_Expenditure: [],
    Transfer_To_Capital_Account: [],
    Other_Receipts: [],
    Capital_Expenditure: [],
    Total_Revenue: [],
    Total_Expenditure: [],
    "": [],
    Closing_Balance: [],
  };

  Object.keys(groupedData || {}).forEach((item) => {
    const category = groupedData[item].category;
    if (category) {
      report[category]?.push({ item, ...groupedData[item] });
    }
  });

  return (
    <Page size="A4" style={styles3.page} orientation="landscape">
      <Text style={styles2.sectionTitle}>2. Budget Reports</Text>
      <Text style={styles2.subsectionTitle}>2.A Summary </Text>
      <Text style={styles2.subsectionTitle}>Table 1: Budget Summary </Text>
      <Text style={styles2.subsectionTitle}>
        Kastina State 2024 Q1 Budget Performance Report- Summary
      </Text>
      <View style={styles3.table}>
        <View style={styles3.tableRow}>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>Item</Text>
          </View>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>2024 Original Budget</Text>
          </View>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>2024 Q1 Performance</Text>
          </View>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>
              2024 Performance Year to Date (Q1)
            </Text>
          </View>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>
              % Performance Year to Date against 2024 Original Budget
            </Text>
          </View>
          <View style={styles3.tableColHeader}>
            <Text style={styles3.tableCellHeader}>
              Balance (against Original Budget)
            </Text>
          </View>
        </View>
        {Object.keys(report).map((category) => (
          <React.Fragment key={category}>
            <View style={styles3.tableRow}>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>
                  <Text style={styles2.boldText}>
                    {capitalizeWords(category)}
                  </Text>
                </Text>
              </View>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>
                  {" "}
                  {formatNumbers(totals[category]?.approve_amount)}
                </Text>
              </View>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>2,500</Text>
              </View>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>2,500 </Text>
              </View>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>
                  25%{JSON.stringify(report[category])}
                </Text>
              </View>
              <View style={styles3.tableCol}>
                <Text style={styles3.tableCell}>
                  {" "}
                  {formatNumbers(totals[category]?.approve_amount)}
                </Text>
              </View>
            </View>

            {report[category].map((item) => (
              <View style={styles3.tableRow} key={item.item}>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>{item.item}</Text>
                </View>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>4,000</Text>
                </View>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>1,000</Text>
                </View>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>1,000</Text>
                </View>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>25%</Text>
                </View>
                <View style={styles3.tableCol}>
                  <Text style={styles3.tableCell}>3,000</Text>
                </View>
              </View>
            ))}
          </React.Fragment>
        ))}
      </View>
    </Page>
  );
};

export const Administative = ({}) => {
  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: "revenue_by_admin",
  });
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const [selectedOption, setSelectedOption] = useState({
    value: "revenue_by_admin",
    // title,
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  const likeVar = selectedOption?.title?.includes("Personnel")
    ? "21%"
    : selectedOption?.title?.includes("Capital")
    ? "23%"
    : selectedOption?.title?.includes("Over-head")
    ? "22%"
    : null;

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/reports?query_type=total_revenue_by_admin&budget_year=${form.budget_year}&like=${likeVar}`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        // alert(JSON.stringify(tt));
        setTreeData(tt);

        setLoading(false);
        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value, likeVar]);

  const headers = [
    { label: "Codes", key: "code" },
    { label: "Description", key: "description" },
    { label: "MDA", key: "mda_name" },
    {
      label: `${parseInt(form.budget_year) - 2}  Actual`,
      key: "prev_year2_actual_amount",
    },
    {
      label: `${parseInt(form.budget_year) - 1} Aproved`,
      key: "prev_year1_approve_amount",
    },
    {
      label: `${parseInt(form.budget_year) - 1} Actual`,
      key: "prev_year1_actual_amount",
    },
    {
      label: `${parseInt(form.budget_year)} Budget Amount`,
      key: "budget_amount",
    },
    {
      label: `${parseInt(form.budget_year)} Approved Proposal`,
      key: "approve_amount",
    },
    {
      label: `${parseInt(form.budget_year)} Actual Proposal`,
      key: "actual_amount",
    },
  ];

  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];

  const calculateprevyear = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year2_actual_amount;
    } else {
      let sum = node.prev_year2_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateprevyear(child);
      }
      return sum;
    }
  };

  const calculateApprovePrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_approve_amount;
    } else {
      let sum = node.prev_year1_approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApprovePrev1(child);
      }
      return sum;
    }
  };
  const calculateActualPrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_actual_amount;
    } else {
      let sum = node.prev_year1_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActualPrev1(child);
      }
      return sum;
    }
  };

  const calculateBudgetAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.budget_amount;
    } else {
      let sum = node.budget_amount || 0;
      for (const child of node.children) {
        sum += calculateBudgetAmt(child);
      }
      return sum;
    }
  };

  const calculateApproveAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.approve_amount;
    } else {
      let sum = node.approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApproveAmt(child);
      }
      return sum;
    }
  };
  const calculatePerformancePercentage = (actual, budget) => {
    if (isNaN(parseFloat(actual)) || isNaN(parseFloat(budget))) {
      return "---";
    }
    return ((parseFloat(actual) / parseFloat(budget)) * 100).toFixed(2);
  };

  const generateTable2 = () => {
    const renderItems = (item, index) => {
      const isTitle =
        item.code === "0000000000000000000" ||
        item.code === "1000000000000000000" ||
        item.code === "01000000000000" ||
        item.code === "3000000000000000000" ||
        item.code === "7000000000000000000" ||
        // item.code === "10000000" ||
        // item.code === "2000000000000000000" ||
        item.code === "01000";
      const actualPrev1 = parseFloat(calculateActualPrev1(item));
      const approveAmt = parseFloat(calculateApproveAmt(item));
      const diff = approveAmt - actualPrev1;
      const percentageDiff = (diff / approveAmt) * 100;
      return (
        <React.Fragment key={`group_${index}`}>
          {/* <Text>damnnnn</Text> */}
          <View style={styles3.tableRow}>
            {/* {isTitle ? "" : item.code} */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "" : item.code}
              </Text>
            </View>

            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "Total Revenue by Admin" : item.description}
              </Text>
            </View>
            {/* <View style={[styles3.tableCol,{backgroundColor: headerColors[item.parentHeads.length],}]}>
              <Text style={styles3.tableCell}>
                {" "}
                {isNaN(parseFloat(calculateprevyear(item)))
                  ? "---"
                  : parseFloat(calculateprevyear(item)).toLocaleString()}
              </Text>
            </View> */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActualPrev1(item)))
                  ? "---"
                  : parseFloat(calculateActualPrev1(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActualPrev1(item)))
                  ? "---"
                  : parseFloat(calculateActualPrev1(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActualPrev1(item)))
                  ? "---"
                  : parseFloat(
                      calculateApproveAmt(item) - calculateActualPrev1(item)
                    ).toLocaleString()}
                {/* {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()} */}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {percentageDiff}
              </Text>
            </View>
          </View>

          {item.children.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else {
              return (
                <View style={styles3.tableRow} key={idx}>
                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.code}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.description}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.prev_year2_actual_amount))
                        ? "---"
                        : parseFloat(
                            child.prev_year2_actual_amount
                          ).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.prev_year1_approve_amount))
                        ? "---"
                        : parseFloat(
                            child.prev_year1_approve_amount
                          ).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.prev_year1_actual_amount))
                        ? "---"
                        : parseFloat(
                            child.prev_year1_actual_amount
                          ).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.budget_amount))
                        ? "---"
                        : parseFloat(child.budget_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {isNaN(parseFloat(child.approve_amount))
                        ? "---"
                        : parseFloat(child.approve_amount).toLocaleString()}
                    </Text>
                  </View>
                </View>
              );
            }
          })}
        </React.Fragment>
      );
    };
    return (
      <View>
        <Text style={styles2.sectionTitle}>
          2.B Revenue by Administrative Classification
        </Text>
        <Text style={styles2.subsectionTitle}>
          Table 2: Total Revenue by Administrative Classification
        </Text>
        <Text style={styles2.subsectionTitle}>Table 1: Budget Summary </Text>
        <Text style={styles2.subsectionTitle}>
          Kastina State Government Performance 2024 Q1 Total Revenue by
          Classification by Administrative
        </Text>
        <View style={styles3.table}>
          <View style={styles3.tableRow}>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Code</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Administative Unit</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Original Budget</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Q1 Performance</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                2024 Performance Year to Date (Q1)
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                % Performance Year to Date against 2024 Original Budget
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                Balance (against Original Budget)
              </Text>
            </View>
          </View>
          {/* <Text>{JSON.stringify(treeData)}</Text> */}
          <View>{treeData.map((item, i) => renderItems(item, i))}</View>
        </View>
      </View>
    );
  };

  return (
    <Page size="A4" style={styles3.page} orientation="landscape">
      {generateTable2()}
      {/* <Text>{JSON.stringify(treeData)}</Text> */}
    </Page>
  );
};

export const EconomicRev = ({}) => {
  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: "total_revenue_by_economic",
  });
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const [selectedOption, setSelectedOption] = useState({
    value: "total_revenue_by_economic",
    title: "Total Expenditure by Economic",
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  const likeVar = selectedOption?.title.includes("Personnel")
    ? "21%"
    : selectedOption?.title.includes("Capital")
    ? "30%"
    : selectedOption?.title.includes("Over-head")
    ? "22%"
    : null;

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/reports?query_type=total_ex_by_eco&budget_year=${form.budget_year}&like=${likeVar}`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Economic Segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        setTreeData(tt);

        setLoading(false);
        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value, likeVar]);

  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];
  const headerColors = [
    "#999",
    "#ccc",
    "#eee",
    "#f2f2f2",
    "#f5f5f5",
    "#f9f9f9",
  ];

  const calculateprevyear = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year2_actual_amount;
    } else {
      let sum = node.prev_year2_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateprevyear(child);
      }
      return sum;
    }
  };

  const calculateApprovePrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_approve_amount;
    } else {
      let sum = node.prev_year1_approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApprovePrev1(child);
      }
      return sum;
    }
  };
  const calculateActualPrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_actual_amount;
    } else {
      let sum = node.prev_year1_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActualPrev1(child);
      }
      return sum;
    }
  };

  const calculateBudgetAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.budget_amount;
    } else {
      let sum = node.budget_amount || 0;
      for (const child of node.children) {
        sum += calculateBudgetAmt(child);
      }
      return sum;
    }
  };

  const calculateApproveAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.approve_amount;
    } else {
      let sum = node.approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApproveAmt(child);
      }
      return sum;
    }
  };

  const generateTable2 = () => {
    const renderItems = (item, index) => {
      const isTitle =
        item.code === "0000000000000000000" ||
        item.code === "1000000000000000000" ||
        item.code === "01000000000000" ||
        item.code === "3000000000000000000" ||
        item.code === "7000000000000000000" ||
        // item.code === "10000000" ||
        // item.code === "2000000000000000000" ||
        item.code === "01000";
      const actualPrev1 = parseFloat(calculateActualPrev1(item));
      const approveAmt = parseFloat(calculateApproveAmt(item));
      const diff = approveAmt - actualPrev1;
      const percentageDiff = (diff / approveAmt) * 100;
      return (
        <React.Fragment key={`group_${index}`}>
          {/* <Text>damnnnn</Text> */}
          <View style={styles3.tableRow}>
            {/* {isTitle ? "" : item.code} */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "" : item.code}
              </Text>
            </View>

            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "Total Revenue by Economic" : item.description}
              </Text>
            </View>
            {/* <View style={[styles3.tableCol,{backgroundColor: headerColors[item.parentHeads.length],}]}>
              <Text style={styles3.tableCell}>
                {" "}
                {isNaN(parseFloat(calculateprevyear(item)))
                  ? "---"
                  : parseFloat(calculateprevyear(item)).toLocaleString()}
              </Text>
            </View> */}
            <View
              style={[
                styles3.tableCol,
                {
                  backgroundColor: headerColors[item.parentHeads.length],
                  textAlign: "right",
                  alignItems: "flex-end",
                },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActualPrev1(item)))
                  ? "---"
                  : parseFloat(calculateActualPrev1(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActualPrev1(item)))
                  ? "---"
                  : parseFloat(calculateActualPrev1(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActualPrev1(item)))
                  ? "---"
                  : parseFloat(
                      calculateApproveAmt(item) - calculateActualPrev1(item)
                    ).toLocaleString()}
                {/* {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()} */}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {percentageDiff}
              </Text>
            </View>
          </View>

          {item.children.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else {
              return (
                <View style={styles3.tableRow} key={idx}>
                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.code}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.description}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.prev_year2_actual_amount))
                        ? "---"
                        : parseFloat(
                            child.prev_year2_actual_amount
                          ).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.prev_year1_approve_amount))
                        ? "---"
                        : parseFloat(
                            child.prev_year1_approve_amount
                          ).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.prev_year1_actual_amount))
                        ? "---"
                        : parseFloat(
                            child.prev_year1_actual_amount
                          ).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.budget_amount))
                        ? "---"
                        : parseFloat(child.budget_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {isNaN(parseFloat(child.approve_amount))
                        ? "---"
                        : parseFloat(child.approve_amount).toLocaleString()}
                    </Text>
                  </View>
                </View>
              );
            }
          })}
        </React.Fragment>
      );
    };
    return (
      <View>
        <Text style={styles2.sectionTitle}>
          2.C Revenue by Economic Classification
        </Text>
        <Text style={styles2.subsectionTitle}>
          Table 2: Total Revenue by Economic Classification
        </Text>
        <Text style={styles2.subsectionTitle}>Table 1: Budget Summary </Text>
        <Text style={styles2.subsectionTitle}>
          Kastina State Government Performance 2024 Q1 Total Revenue by
          Classification by Economic
        </Text>
        <View style={styles3.table}>
          <View style={styles3.tableRow}>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Code</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Economic Unit</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Original Budget</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Q1 Performance</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                2024 Performance Year to Date (Q1)
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                % Performance Year to Date against 2024 Original Budget
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                Balance (against Original Budget)
              </Text>
            </View>
          </View>
          {/* <Text>{JSON.stringify(treeData)}</Text> */}
          <View>{treeData?.map((item, i) => renderItems(item, i))}</View>
        </View>
      </View>
    );
  };

  return (
    <Page size="A4" style={styles3.page} orientation="landscape">
      {generateTable2()}
      {/* <Text>{JSON.stringify(treeData)}</Text> */}
    </Page>
  );
};

export const AdministativePersonell = ({}) => {
  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: "revenue_by_admin",
  });
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const [selectedOption, setSelectedOption] = useState({
    value: "revenue_by_admin",
    title: "Personnel Expenditure by Admin",
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  const likeVar = selectedOption?.title?.includes("Personnel")
    ? "21%"
    : selectedOption?.title?.includes("Capital")
    ? "23%"
    : selectedOption?.title?.includes("Over-head")
    ? "22%"
    : null;

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/reports?query_type=ex_by_admin&budget_year=${form.budget_year}&like=${likeVar}`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        // alert(JSON.stringify(tt));
        setTreeData(tt);

        setLoading(false);
        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value, likeVar]);

  const headers = [
    { label: "Codes", key: "code" },
    { label: "Description", key: "description" },
    { label: "MDA", key: "mda_name" },
    {
      label: `${parseInt(form.budget_year) - 2}  Actual`,
      key: "prev_year2_actual_amount",
    },
    {
      label: `${parseInt(form.budget_year) - 1} Aproved`,
      key: "prev_year1_approve_amount",
    },
    {
      label: `${parseInt(form.budget_year) - 1} Actual`,
      key: "prev_year1_actual_amount",
    },
    {
      label: `${parseInt(form.budget_year)} Budget Amount`,
      key: "budget_amount",
    },
    {
      label: `${parseInt(form.budget_year)} Approved Proposal`,
      key: "approve_amount",
    },
    {
      label: `${parseInt(form.budget_year)} Actual Proposal`,
      key: "actual_amount",
    },
  ];

  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];
  const headerColors = [
    "#999",
    "#ccc",
    "#eee",
    "#f2f2f2",
    "#f5f5f5",
    "#f9f9f9",
  ];

  const calculateprevyear = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year2_actual_amount;
    } else {
      let sum = node.prev_year2_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateprevyear(child);
      }
      return sum;
    }
  };

  const calculateApprovePrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_approve_amount;
    } else {
      let sum = node.prev_year1_approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApprovePrev1(child);
      }
      return sum;
    }
  };
  const calculateActualPrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_actual_amount;
    } else {
      let sum = node.prev_year1_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActualPrev1(child);
      }
      return sum;
    }
  };

  const calculateActual = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.actual_amount;
    } else {
      let sum = node.actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActual(child);
      }
      return sum;
    }
  };

  const calculateBudgetAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.budget_amount;
    } else {
      let sum = node.budget_amount || 0;
      for (const child of node.children) {
        sum += calculateBudgetAmt(child);
      }
      return sum;
    }
  };

  const calculateApproveAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.approve_amount;
    } else {
      let sum = node.approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApproveAmt(child);
      }
      return sum;
    }
  };

  const generateTable2 = () => {
    const renderItems = (item, index) => {
      const isTitle =
        item.code === "0000000000000000000" ||
        item.code === "1000000000000000000" ||
        item.code === "01000000000000" ||
        item.code === "3000000000000000000" ||
        item.code === "7000000000000000000" ||
        // item.code === "10000000" ||
        // item.code === "2000000000000000000" ||
        item.code === "01000";
      const actualPrev1 = parseFloat(calculateActual(item));
      const approveAmt = parseFloat(calculateApproveAmt(item));

      const percentageDiff = (actualPrev1 / approveAmt) * 100;
      return (
        <React.Fragment key={`group_${index}`}>
          {/* <Text>damnnnn</Text> */}
          <View style={styles3.tableRow}>
            {/* {isTitle ? "" : item.code} */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "" : item.code}
              </Text>
            </View>

            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "Total Personell Expenditure" : item.description}
              </Text>
            </View>
            {/* <View style={[styles3.tableCol,{backgroundColor: headerColors[item.parentHeads.length],}]}>
              <Text style={styles3.tableCell}>
                {" "}
                {isNaN(parseFloat(calculateprevyear(item)))
                  ? "---"
                  : parseFloat(calculateprevyear(item)).toLocaleString()}
              </Text>
            </View> */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActual(item)))
                  ? "---"
                  : parseFloat(calculateActual(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActual(item)))
                  ? "---"
                  : parseFloat(calculateActual(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(percentageDiff) ? "0" : percentageDiff}
                {/* {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()} */}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(
                  parseFloat(calculateApproveAmt(item) - calculateActual(item))
                )
                  ? "0"
                  : parseFloat(
                      calculateApproveAmt(item) - calculateActual(item)
                    ).toLocaleString()}
              </Text>
            </View>
          </View>

          {item.children.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else {
              return (
                <View style={styles3.tableRow} key={idx}>
                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.code}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.description}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.approve_amount))
                        ? "---"
                        : parseFloat(child?.approve_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.actual_amount))
                        ? "---"
                        : parseFloat(child.actual_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.actual_amount))
                        ? "---"
                        : parseFloat(child.actual_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(
                        parseFloat(child.actual_amount / child.approve_amount)
                      )
                        ? "0"
                        : parseFloat(
                            (child.actual_amount / child.approve_amount) * 100
                          ).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {isNaN(
                        parseFloat(
                          child.approve_amount && child.prev_year1_actual_amount
                        )
                      )
                        ? "---"
                        : parseFloat(
                            child.approve_amount - child.actual_amount
                          ).toLocaleString()}
                    </Text>
                  </View>
                </View>
              );
            }
          })}
        </React.Fragment>
      );
    };
    return (
      <View>
        {/* <Text style={styles2.sectionTitle}>
          Personnel Expenditure by Administrative Classification
        </Text> */}
        <Text style={styles2.subsectionTitle}>
          Table 5: Personnel Expenditure by Administrative Classification
        </Text>
        {/* <Text style={styles2.subsectionTitle}>Table 1: Budget Summary </Text> */}
        <Text style={styles2.subsectionTitle}>
          Kastina State Government Performance 2024 Q1 Personnel Expenditure by
          Classification by Administrative
        </Text>
        <View style={styles3.table}>
          <View style={styles3.tableRow}>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Code</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Administative Unit</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Original Budget</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Q1 Performance</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                2024 Performance Year to Date (Q1)
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                % Performance Year to Date against 2024 Original Budget
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                Balance (against Original Budget)
              </Text>
            </View>
          </View>
          {/* <Text>{JSON.stringify(treeData)}</Text> */}
          <View>{treeData?.map((item, i) => renderItems(item, i))}</View>
        </View>
      </View>
    );
  };

  return (
    <Page size="A4" style={styles3.page} orientation="landscape">
      {generateTable2()}
      {/* <Text>{JSON.stringify(treeData)}</Text> */}
    </Page>
  );
};

export const AdministativeOverHead = ({}) => {
  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: "revenue_by_admin",
  });
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const [selectedOption, setSelectedOption] = useState({
    value: "revenue_by_admin",
    title: "Over-head Expenditure by Admin",
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  const likeVar = selectedOption?.title?.includes("Personnel")
    ? "21%"
    : selectedOption?.title?.includes("Capital")
    ? "23%"
    : selectedOption?.title?.includes("Over-head")
    ? "22%"
    : null;

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/reports?query_type=ex_by_admin&budget_year=${form.budget_year}&like=${likeVar}`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        // alert(JSON.stringify(tt));
        setTreeData(tt);

        setLoading(false);
        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value, likeVar]);

  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];
  const headerColors = [
    "#999",
    "#ccc",
    "#eee",
    "#f2f2f2",
    "#f5f5f5",
    "#f9f9f9",
  ];

  const calculateprevyear = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year2_actual_amount;
    } else {
      let sum = node.prev_year2_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateprevyear(child);
      }
      return sum;
    }
  };

  const calculateApprovePrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_approve_amount;
    } else {
      let sum = node.prev_year1_approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApprovePrev1(child);
      }
      return sum;
    }
  };
  const calculateActualPrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_actual_amount;
    } else {
      let sum = node.prev_year1_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActualPrev1(child);
      }
      return sum;
    }
  };

  const calculateActual = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.actual_amount;
    } else {
      let sum = node.actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActual(child);
      }
      return sum;
    }
  };

  const calculateBudgetAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.budget_amount;
    } else {
      let sum = node.budget_amount || 0;
      for (const child of node.children) {
        sum += calculateBudgetAmt(child);
      }
      return sum;
    }
  };

  const calculateApproveAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.approve_amount;
    } else {
      let sum = node.approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApproveAmt(child);
      }
      return sum;
    }
  };

  const generateTable2 = () => {
    const renderItems = (item, index) => {
      const isTitle =
        item.code === "0000000000000000000" ||
        item.code === "1000000000000000000" ||
        item.code === "01000000000000" ||
        item.code === "3000000000000000000" ||
        item.code === "7000000000000000000" ||
        // item.code === "10000000" ||
        // item.code === "2000000000000000000" ||
        item.code === "01000";
      const actualPrev1 = parseFloat(calculateActual(item));
      const approveAmt = parseFloat(calculateApproveAmt(item));

      const percentageDiff = (actualPrev1 / approveAmt) * 100;
      return (
        <React.Fragment key={`group_${index}`}>
          {/* <Text>damnnnn</Text> */}
          <View style={styles3.tableRow}>
            {/* {isTitle ? "" : item.code} */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "" : item.code}
              </Text>
            </View>

            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "Total Overhead Expenditure" : item.description}
              </Text>
            </View>
            {/* <View style={[styles3.tableCol,{backgroundColor: headerColors[item.parentHeads.length],}]}>
              <Text style={styles3.tableCell}>
                {" "}
                {isNaN(parseFloat(calculateprevyear(item)))
                  ? "---"
                  : parseFloat(calculateprevyear(item)).toLocaleString()}
              </Text>
            </View> */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActual(item)))
                  ? "---"
                  : parseFloat(calculateActual(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActual(item)))
                  ? "---"
                  : parseFloat(calculateActual(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(percentageDiff) ? "0" : percentageDiff}
                {/* {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()} */}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(
                  parseFloat(calculateApproveAmt(item) - calculateActual(item))
                )
                  ? "0"
                  : parseFloat(
                      calculateApproveAmt(item) - calculateActual(item)
                    ).toLocaleString()}
              </Text>
            </View>
          </View>

          {item.children.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else {
              return (
                <View style={styles3.tableRow} key={idx}>
                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.code}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.description}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.approve_amount))
                        ? "---"
                        : parseFloat(child?.approve_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.actual_amount))
                        ? "---"
                        : parseFloat(child.actual_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.actual_amount))
                        ? "---"
                        : parseFloat(child.actual_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(
                        parseFloat(child.actual_amount / child.approve_amount)
                      )
                        ? "0"
                        : parseFloat(
                            (child.actual_amount / child.approve_amount) * 100
                          ).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {isNaN(
                        parseFloat(
                          child.approve_amount && child.prev_year1_actual_amount
                        )
                      )
                        ? "---"
                        : parseFloat(
                            child.approve_amount - child.actual_amount
                          ).toLocaleString()}
                    </Text>
                  </View>
                </View>
              );
            }
          })}
        </React.Fragment>
      );
    };
    return (
      <View>
        {/* <Text style={styles2.sectionTitle}>
          Personnel Expenditure by Administrative Classification
        </Text> */}
        <Text style={styles2.subsectionTitle}>
          Table 6: Overhead Expenditure by Administrative Classificationn
        </Text>
        {/* <Text style={styles2.subsectionTitle}>Table 1: Budget Summary </Text> */}
        <Text style={styles2.subsectionTitle}>
          Kastina State Government Performance 2024 Q1 Overhead Expenditure by
          Classification by Administrative
        </Text>
        <View style={styles3.table}>
          <View style={styles3.tableRow}>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Code</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Administative Unit</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Original Budget</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Q1 Performance</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                2024 Performance Year to Date (Q1)
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                % Performance Year to Date against 2024 Original Budget
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                Balance (against Original Budget)
              </Text>
            </View>
          </View>
          {/* <Text>{JSON.stringify(treeData)}</Text> */}
          <View>{treeData?.map((item, i) => renderItems(item, i))}</View>
        </View>
      </View>
    );
  };

  return (
    <Page size="A4" style={styles3.page} orientation="landscape">
      {generateTable2()}
      {/* <Text>{JSON.stringify(treeData)}</Text> */}
    </Page>
  );
};

export const AdministativeCapital = ({}) => {
  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: "revenue_by_admin",
  });
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const [selectedOption, setSelectedOption] = useState({
    value: "revenue_by_admin",
    title: "Capital Expenditure by Admin",
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  const likeVar = selectedOption?.title?.includes("Personnel")
    ? "21%"
    : selectedOption?.title?.includes("Capital")
    ? "23%"
    : selectedOption?.title?.includes("Over-head")
    ? "22%"
    : null;

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `/reports?query_type=ex_by_admin&budget_year=${form.budget_year}&like=${likeVar}`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }

        const tt = unflattenTable(data?.results);
        // alert(JSON.stringify(tt));
        setTreeData(tt);

        setLoading(false);
        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value, likeVar]);

  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];
  const headerColors = [
    "#999",
    "#ccc",
    "#eee",
    "#f2f2f2",
    "#f5f5f5",
    "#f9f9f9",
  ];

  const calculateprevyear = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year2_actual_amount;
    } else {
      let sum = node.prev_year2_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateprevyear(child);
      }
      return sum;
    }
  };

  const calculateApprovePrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_approve_amount;
    } else {
      let sum = node.prev_year1_approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApprovePrev1(child);
      }
      return sum;
    }
  };
  const calculateActualPrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_actual_amount;
    } else {
      let sum = node.prev_year1_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActualPrev1(child);
      }
      return sum;
    }
  };

  const calculateActual = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.actual_amount;
    } else {
      let sum = node.actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActual(child);
      }
      return sum;
    }
  };

  const calculateBudgetAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.budget_amount;
    } else {
      let sum = node.budget_amount || 0;
      for (const child of node.children) {
        sum += calculateBudgetAmt(child);
      }
      return sum;
    }
  };

  const calculateApproveAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.approve_amount;
    } else {
      let sum = node.approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApproveAmt(child);
      }
      return sum;
    }
  };

  const generateTable2 = () => {
    const renderItems = (item, index) => {
      const isTitle =
        item.code === "0000000000000000000" ||
        item.code === "1000000000000000000" ||
        item.code === "01000000000000" ||
        item.code === "3000000000000000000" ||
        item.code === "7000000000000000000" ||
        // item.code === "10000000" ||
        // item.code === "2000000000000000000" ||
        item.code === "01000";
      const actualPrev1 = parseFloat(calculateActual(item));
      const approveAmt = parseFloat(calculateApproveAmt(item));

      const percentageDiff = (actualPrev1 / approveAmt) * 100;
      return (
        <React.Fragment key={`group_${index}`}>
          {/* <Text>damnnnn</Text> */}
          <View style={styles3.tableRow}>
            {/* {isTitle ? "" : item.code} */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "" : item.code}
              </Text>
            </View>

            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "Total Capital Expenditure" : item.description}
              </Text>
            </View>
            {/* <View style={[styles3.tableCol,{backgroundColor: headerColors[item.parentHeads.length],}]}>
              <Text style={styles3.tableCell}>
                {" "}
                {isNaN(parseFloat(calculateprevyear(item)))
                  ? "---"
                  : parseFloat(calculateprevyear(item)).toLocaleString()}
              </Text>
            </View> */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActual(item)))
                  ? "---"
                  : parseFloat(calculateActual(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActual(item)))
                  ? "---"
                  : parseFloat(calculateActual(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(percentageDiff) ? "0" : percentageDiff}
                {/* {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()} */}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(
                  parseFloat(calculateApproveAmt(item) - calculateActual(item))
                )
                  ? "0"
                  : parseFloat(
                      calculateApproveAmt(item) - calculateActual(item)
                    ).toLocaleString()}
              </Text>
            </View>
          </View>

          {item.children.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else {
              return (
                <View style={styles3.tableRow} key={idx}>
                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.code}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.description}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.approve_amount))
                        ? "---"
                        : parseFloat(child?.approve_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.actual_amount))
                        ? "---"
                        : parseFloat(child.actual_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.actual_amount))
                        ? "---"
                        : parseFloat(child.actual_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(
                        parseFloat(child.actual_amount / child.approve_amount)
                      )
                        ? "0"
                        : parseFloat(
                            (child.actual_amount / child.approve_amount) * 100
                          ).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {isNaN(
                        parseFloat(
                          child.approve_amount && child.prev_year1_actual_amount
                        )
                      )
                        ? "---"
                        : parseFloat(
                            child.approve_amount - child.actual_amount
                          ).toLocaleString()}
                    </Text>
                  </View>
                </View>
              );
            }
          })}
        </React.Fragment>
      );
    };
    return (
      <View>
        {/* <Text style={styles2.sectionTitle}>
          Personnel Expenditure by Administrative Classification
        </Text> */}
        <Text style={styles2.subsectionTitle}>
          Table 7: Capital Expenditure by Administrative Classification
        </Text>
        {/* <Text style={styles2.subsectionTitle}>Table 1: Budget Summary </Text> */}
        <Text style={styles2.subsectionTitle}>
          Kastina State Government Performance 2024 Q1 Capital Expenditure by
          Classification by Administrative
        </Text>
        <View style={styles3.table}>
          <View style={styles3.tableRow}>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Code</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Administative Unit</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Original Budget</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Q1 Performance</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                2024 Performance Year to Date (Q1)
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                % Performance Year to Date against 2024 Original Budget
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                Balance (against Original Budget)
              </Text>
            </View>
          </View>
          {/* <Text>{JSON.stringify(treeData)}</Text> */}
          <View>{treeData?.map((item, i) => renderItems(item, i))}</View>
        </View>
      </View>
    );
  };

  return (
    <Page size="A4" style={styles3.page} orientation="landscape">
      {generateTable2()}
      {/* <Text>{JSON.stringify(treeData)}</Text> */}
    </Page>
  );
};

export const TotalFundExp = ({
  data,
  title = "",
  sub_title = "",
  heading = "",
  names = "",
  unit = "",
}) => {
  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: "revenue_by_admin",
  });
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const [selectedOption, setSelectedOption] = useState({
    value: "revenue_by_admin",
    title: "Capital Expenditure by Admin",
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  const likeVar = selectedOption?.title?.includes("Personnel")
    ? "21%"
    : selectedOption?.title?.includes("Capital")
    ? "23%"
    : selectedOption?.title?.includes("Over-head")
    ? "22%"
    : null;

  // useEffect(() => {
  //   setLoading(true);
  //   _fetchApi(
  //     `/reports?query_type=ex_by_admin&budget_year=${form.budget_year}&like=${likeVar}`,
  //     (data) => {
  //       let _data = data?.results;
  //       const modifiedResults = [..._data];
  //       const adminSegmentIndex = modifiedResults.findIndex(
  //         (item) => item.description === "Administative segment"
  //       );

  //       if (adminSegmentIndex !== -1) {
  //         // If found, empty the "sub_head" for that specific index
  //         modifiedResults[adminSegmentIndex].sub_head = "";
  //         modifiedResults[adminSegmentIndex].head = "";
  //       }

  //       const tt = unflattenTable(data?.results);
  //       // alert(JSON.stringify(tt));
  //       setTreeData(tt);

  //       setLoading(false);
  //       // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
  //     },
  //     (err) => {
  //       console.log(err);
  //       setLoading(false);
  //     }
  //   );
  // }, [form.budget_year, selectedOption.value, likeVar]);

  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];
  const headerColors = [
    "#999",
    "#ccc",
    "#eee",
    "#f2f2f2",
    "#f5f5f5",
    "#f9f9f9",
  ];

  const calculateprevyear = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year2_actual_amount;
    } else {
      let sum = node.prev_year2_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateprevyear(child);
      }
      return sum;
    }
  };

  const calculateApprovePrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_approve_amount;
    } else {
      let sum = node.prev_year1_approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApprovePrev1(child);
      }
      return sum;
    }
  };
  const calculateActualPrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.prev_year1_actual_amount;
    } else {
      let sum = node.prev_year1_actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActualPrev1(child);
      }
      return sum;
    }
  };

  const calculateActual = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.actual_amount;
    } else {
      let sum = node.actual_amount || 0;
      for (const child of node.children) {
        sum += calculateActual(child);
      }
      return sum;
    }
  };

  const calculateBudgetAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.budget_amount;
    } else {
      let sum = node.budget_amount || 0;
      for (const child of node.children) {
        sum += calculateBudgetAmt(child);
      }
      return sum;
    }
  };

  const calculateApproveAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.approve_amount;
    } else {
      let sum = node.approve_amount || 0;
      for (const child of node.children) {
        sum += calculateApproveAmt(child);
      }
      return sum;
    }
  };

  const generateTable2 = ({ cool }) => {
    const renderItems = (item, index) => {
      const isTitle =
        item.code === "0000000000000000000" ||
        item.code === "1000000000000000000" ||
        item.code === "01000000000000" ||
        item.code === "3000000000000000000" ||
        item.code === "7000000000000000000" ||
        // item.code === "10000000" ||
        // item.code === "2000000000000000000" ||
        item.code === "01000";
      const actualPrev1 = parseFloat(calculateActual(item));
      const approveAmt = parseFloat(calculateApproveAmt(item));

      const percentageDiff = (actualPrev1 / approveAmt) * 100;
      return (
        <React.Fragment key={`group_${index}`}>
          {/* <Text>damnnnn</Text> */}
          <View style={styles3.tableRow}>
            {/* {isTitle ? "" : item.code} */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? "" : item.code.replace(/0+$/, "")}
              </Text>
            </View>

            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text style={styles3.tableCell2}>
                {" "}
                {isTitle ? names : item.description}
              </Text>
            </View>
            {/* <View style={[styles3.tableCol,{backgroundColor: headerColors[item.parentHeads.length],}]}>
              <Text style={styles3.tableCell}>
                {" "}
                {isNaN(parseFloat(calculateprevyear(item)))
                  ? "---"
                  : parseFloat(calculateprevyear(item)).toLocaleString()}
              </Text>
            </View> */}
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActual(item)))
                  ? "0"
                  : parseFloat(calculateActual(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(parseFloat(calculateActual(item)))
                  ? "0"
                  : parseFloat(calculateActual(item)).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(percentageDiff) ? "0" : percentageDiff} %
                {/* {isNaN(parseFloat(calculateApproveAmt(item)))
                  ? "---"
                  : parseFloat(calculateApproveAmt(item)).toLocaleString()} */}
              </Text>
            </View>
            <View
              style={[
                styles3.tableCol,
                { backgroundColor: headerColors[item.parentHeads.length] },
              ]}
            >
              <Text
                style={[
                  styles3.tableCell2,
                  { textAlign: "right", alignItems: "flex-end" },
                ]}
              >
                {" "}
                {isNaN(
                  parseFloat(calculateApproveAmt(item) - calculateActual(item))
                )
                  ? "0"
                  : parseFloat(
                      calculateApproveAmt(item) - calculateActual(item)
                    ).toLocaleString()}
              </Text>
            </View>
          </View>

          {item.children.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else {
              return (
                <View style={styles3.tableRow} key={idx}>
                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.code}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text style={styles3.tableCell}> {child.description}</Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.approve_amount))
                        ? "0"
                        : parseFloat(child?.approve_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.actual_amount))
                        ? "0"
                        : parseFloat(child.actual_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(parseFloat(child.actual_amount))
                        ? "---"
                        : parseFloat(child.actual_amount).toLocaleString()}
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {" "}
                      {isNaN(
                        parseFloat(child.actual_amount / child.approve_amount)
                      )
                        ? "0"
                        : parseFloat(
                            (child.actual_amount / child.approve_amount) * 100
                          ).toLocaleString()}{" "}
                      %
                    </Text>
                  </View>

                  <View style={styles3.tableCol}>
                    <Text
                      style={[
                        styles3.tableCell,
                        { textAlign: "right", alignItems: "flex-end" },
                      ]}
                    >
                      {isNaN(
                        parseFloat(
                          child.approve_amount && child.prev_year1_actual_amount
                        )
                      )
                        ? "---"
                        : parseFloat(
                            child.approve_amount - child.actual_amount
                          ).toLocaleString()}
                    </Text>
                  </View>
                </View>
              );
            }
          })}
        </React.Fragment>
      );
    };
    return (
      <View>
        {title === "" ? (
          <Text></Text>
        ) : (
          <Text style={styles2.sectionTitle}>{title}</Text>
        )}
        <Text style={styles2.subsectionTitle}>{sub_title}</Text>
        {/* <Text style={styles2.subsectionTitle}>Table 1: Budget Summary </Text> */}
        <Text style={styles2.subsectionTitle}>
          Kastina State Government Performance 2024 {heading}
        </Text>
        <View style={styles3.table}>
          <View style={styles3.tableRow}>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>Code</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                {unit === "" ? "Function" : unit}
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Original Budget</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>2024 Q1 Performance</Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                2024 Performance Year to Date (Q1)
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                % Performance Year to Date against 2024 Original Budget
              </Text>
            </View>
            <View style={styles3.tableColHeader}>
              <Text style={styles3.tableCellHeader}>
                Balance (against Original Budget)
              </Text>
            </View>
          </View>
          {/* <Text>{JSON.stringify(treeData)}</Text> */}
          <View>{data?.map((item, i) => renderItems(item, i))}</View>
        </View>
      </View>
    );
  };

  return (
    <Page size="A4" style={styles3.page} orientation="landscape">
      {generateTable2(names)}
      {/* <Text>{JSON.stringify(treeData)}</Text> */}
    </Page>
  );
};

TotalFundExp.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  sub_title: PropTypes.string,
  heading: PropTypes.string,
  names: PropTypes.string,
  unit: PropTypes.string,
};
