import ReportsAdminShareCom from "./ReportsAdminShareCom";

const listdata = [
  { value: "total_ex_by_admin", title: "Total Exp. by Admin" },
  {
    value: "ex_by_admin_new",
    title: "Personnel Exp. by Admin",
    code: "Persornnel",
  },
  {
    value: "ex_by_admin_new",
    title: "Other NDR Exp. by Admin",
    code: "OverHead",
  },
  {
    value: "ex_by_admin",
    title: "Debt Service by Admin",
    code: "02%",
  },
  {
    value: "ex_by_admin_new",
    title: "Capital Exp. by Admin",
    code: "Capital",
  },
];
function EcoReport() {
  return (
    <ReportsAdminShareCom
      type="total_ex_by_admin"
      route="/reports"
      options={listdata}
      title="Total Expenditure by Admin"
    />
  );
}

export default EcoReport;
