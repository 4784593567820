import React, { useCallback, useEffect, useRef, useState } from "react";

import { getSector } from "helper";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import SearchBar from "components/SearchBar";
import CustomTable from "components/CustomTable";
import { _postApi } from "redux/action/api";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { _fetchApi } from "redux/action/api";
import Scrollbar from "components/Tree/Scrollbar";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from "reactstrap";
import { formatNumber } from "redux/action/api";
import CustomTypeahead from "components/CustomTypeahead";

import { year } from "helper";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useYear } from "useYearList";

function MOPB() {
  // const navigate = useNavigate();
  const years = useYear();
  const [editingIndex, setEditingIndex] = useState(-1);
  const [form, setForm] = useState({
    budget_year: year,
    economic_code: "",
    economic_description: "",
    program_code: "",
    program_description: "",
    function_code: "",
    function_description: "",
    fund_code: "",
    fund_description: "",
    geo_code: "",
    geo_description: "",
    budget_amount: "",
    actual_amount: "",
    budget_type: "",
    start_date: "",
    end_date: "",
    segment: "",
    // mda_name: "",
  });
  const handleDoubleClick = (index) => {
    setEditingIndex(index);
  };

  const handleInputBlur = () => {
    setEditingIndex(-1);
  };
  const fields = [
    {
      title: "Economic Code",
      custom: true,
      component: (item) => item.economic_code,
    },

    {
      title: "Economic Description",
      custom: true,
      component: (ite) => ite.economic_description,
    },
    { title: "Fund Code", custom: true, component: (ite) => ite.fund_code },
    {
      title: "Fund Description",
      custom: true,
      component: (ite) => ite.fund_description,
    },
    {
      title: "Program Code",
      custom: true,
      component: (ite) => ite.program_code,
    },
    {
      title: "Program Description",
      custom: true,
      component: (ite) => ite.program_description,
    },
    {
      title: "Functional code",
      custom: true,
      component: (ite) => ite.function_code,
    },
    // {
    //   title: "Functional Description",
    //   custom: true,
    //   component: (ite) => ite.function_description,
    // },
    { title: "Geo code", custom: true, component: (ite) => ite.geo_code },
    {
      title: "Geo Description",
      custom: true,
      component: (ite) => ite.geo_description,
    },
    {
      title: "Proposed Amount(₦)",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}> {formatNumber(ite.budget_amount)}</div>
      ),
    },

    {
      title: "MOPB Estimate(₦)",
      custom: true,
      component: (ite, idx) => (
        <div
          onDoubleClick={() => handleDoubleClick(idx)}
          style={{ float: "right" }}
        >
          {ite.status === "final-proposal" ? (
            editingIndex === idx ? (
              <Input
                name="mopb_amount"
                value={ite.mopb_amount || ite.budget_amount}
                onChange={({ target: { value } }) => handleChanges(value, idx)}
                onBlur={handleInputBlur}
                style={{ width: "120px", alignItems: "right" }}
              />
            ) : (
              formatNumber(ite.mopb_amount || ite.budget_amount)
            )
          ) : (
            formatNumber(ite.mopb_amount)
          )}
        </div>
      ),
    },
    {
      title: "Exco Adjustment",
      custom: true,
      component: (ite) => (
        <div style={{ float: "right" }}>
          {" "}
          {formatNumber(parseFloat(ite.mopb_amount - ite.exco_amount))}
        </div>
      ),
    },

    // {
    //   title: "Action", custom: true, component: (ite) => <div className='text-center'>
    //     <button className='btn btn-primary' color='primary' onClick={() => handleSubmit(ite)}>submit</button></div>
    // },
  ];

  const [results, setResults] = useState();
  // let data = results&&results.map(item => item.name)
  const [results4, setResult4] = useState([]);
  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const getBudgetNew = useCallback(() => {
    _fetchApi(
      `/get-budget-new/${form.mda_name}?query_type=SELECTS&budget_year=${form.budget_year}`,
      (res) => {
        const modifiedResults = res.results
          .map((item) => {
            if (!item.mopb_amount) {
              return { ...item, mopb_amount: item.budget_amount };
            }
            return item;
          })
          .filter((item) => item.status != "draft");
        setResults(modifiedResults);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [form.mda_name, form.budget_year]);

  useEffect(() => {
    getBudgetNew();
  }, [getBudgetNew]);

  const handleChanges = (value, index) => {
    const arr = [...results];
    arr[index].mopb_amount = value;
    setResults(arr);
  };
  const { addToast } = useToasts();

  const handleSubmit = (name = "") => {
    const filteredResults = results.filter(
      (item) => item.status === "final-proposal"
    );
    let type = name === "final-mopb" ? "UPDATE" : "INSERT";
    _postApi(
      `/post-budget?query_type=${type}&status=${name}`,
      name === "final-mopb" ? filteredResults : [form],
      (res) => {
        if (res.success) {
          addToast("Submitted Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          getBudgetNew();
          setData([]);
        }
      },
      (err) => {
        console.log(err);
        // alert('An error occurred!');
      }
    );
  };

  let aheadData = results4?.map((i) => ({
    head: `${i.head}-${i.description}`,
    description: i.description,
    code: i.head,
  }));

  const user = useSelector((e) => e.auth.user);
  const totalAmount = results?.reduce(
    (total, item) => parseFloat(item.mopb_amount) + total,
    0
  );

  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const repRef = useRef();
  const repRef1 = useRef();
  const repRef2 = useRef();
  const repRef3 = useRef();
  const repRef4 = useRef();
  const repRef5 = useRef();
  const [results6, setResults6] = useState([]);
  const [results1, setResults1] = useState([]);
  const [results2, setResults2] = useState([]);
  const [results3, setResults3] = useState([]);
  const [results9, setResults9] = useState([]);
  const [results5, setResults5] = useState([]);
  // let data = results&&results.map(item => item.name)
  useEffect(() => {
    getSector({ type: "ADMINISTRATIVE SEGMENT" }, "select", setResults6);
  }, []);

  useEffect(() => {
    getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults1);
  }, []);

  useEffect(() => {
    getSector({ type: "FUNCTIONAL SEGMENT" }, "select", setResults2);
  }, []);

  useEffect(() => {
    getSector({ type: "PROGRAMME SEGMENT" }, "select", setResults3);
  }, []);

  useEffect(() => {
    getSector({ type: "FUND SEGMENT" }, "select", setResults9);
  }, []);

  useEffect(() => {
    getSector({ type: "GEO SEGMENT" }, "select", setResults5);
  }, []);

  let aheadData6 =
    results9 &&
    results9?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData1 =
    results6 &&
    results6?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData2 =
    results2 &&
    results2?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData3 =
    results3 &&
    results3?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData4 =
    results1 &&
    results1?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData5 =
    results5 &&
    results5?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  const handleChanging = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  const filteredResults = results?.filter(
    (item) => item.status === "final-proposal"
  );

  return (
    <DashboardLayout>
      {form.mda_name && (
        <center>
          <h2>{/* {form.budger_} */}</h2>
        </center>
      )}
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  className="text-center"
                >
                  MOPB Recommendation Page
                </MDTypography>
              </MDBox>

              <div className="">
                {" "}
                <Button
                  color="primary"
                  className="pr-5 pl-5 m-4"
                  style={{ float: "right" }}
                  onClick={() => toggle3()}
                >
                  Add New Proposal
                </Button>
              </div>
              {/* {JSON.stringify(form)} */}
              <Modal isOpen={modal3} toggle={toggle3} size="lg">
                <ModalHeader>Continue With</ModalHeader>
                <ModalBody>
                  <div className=" row mb-4 p-3">
                    <div className="col-md-4 ">
                      <Label>Select budget year</Label>
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                    {/* {JSON.stringify(aheadData)} */}
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef}
                        labelKey="head"
                        label="Adminstrative Segment"
                        options={aheadData1}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              admin_code: s[0].code,
                              admin_description: s[0].description,
                              mda_name: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef1}
                        labelKey="head"
                        label="Economic Segment"
                        options={aheadData4}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              economic_code: s[0].code,
                              economic_description: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef2}
                        labelKey="head"
                        label="Fund Segment"
                        options={aheadData6}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              fund_code: s[0].code,
                              fund_description: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef3}
                        labelKey="head"
                        label="Geo Segment"
                        options={aheadData5}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              geo_code: s[0].code,
                              geo_description: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef4}
                        labelKey="head"
                        label="Program Segment"
                        options={aheadData3}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              program_code: s[0].code,
                              program_description: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <CustomTypeahead
                        ref={repRef5}
                        labelKey="head"
                        label="Functional Segment"
                        options={aheadData2}
                        onChange={(s) => {
                          if (s.length) {
                            setForm((p) => ({
                              ...p,
                              function_code: s[0].code,
                              function_description: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-6">
                      <lable>Proposed Amount</lable>
                      <input
                        className="form-control"
                        type="number"
                        maxLength="4"
                        name="budget_amount"
                        value={form.budget_amount}
                        onChange={handleChanging}
                      />
                    </div>
                  </div>
                  <center>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        handleSubmit("final-proposal");
                        toggle3();
                      }}
                      // disabled={filteredResults?.length === 0 ? true : false}
                    >
                      Submit
                    </button>
                  </center>
                </ModalBody>
              </Modal>
              <MDBox>
                <MDBox>
                  <div className=" row p-3">
                    <div className="col-md-4 mb-3">
                      <CustomTypeahead
                        labelKey="head"
                        label="Select MDA"
                        options={aheadData}
                        onChange={(s) => {
                          if (s.length) {
                            console.log(s);
                            setForm((p) => ({
                              ...p,
                              mda_code: s[0].code,
                              mda_name: s[0].description,
                            }));
                          }
                        }}
                      />
                    </div>

                    <div className="col-md-4 " style={{ marginLeft: "300px" }}>
                      <Label>Select budget year</Label>
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                    <SearchBar />

                    <div className="">
                      {" "}
                      <Button
                        color="primary"
                        className="pr-5 pl-5 "
                        style={{ float: "right" }}
                        onClick={() => handleSubmit("final-mopb")}
                        disabled={filteredResults?.length === 0 ? true : false}
                      >
                        Submit
                      </Button>
                    </div>
                    <span style={{ fontWeight: "bold", float: "right" }}>
                      TOTAL : ₦{totalAmount?.toLocaleString()}
                    </span>
                    {/* <h1>i am sub org</h1>  */}
                    <Scrollbar>
                      {/* {JSON.stringify(results[2]?.mopb_amount)} */}

                      <CustomTable fields={fields} data={results} type="mopb" />
                    </Scrollbar>
                    {/* <center>
                      <Button
                        color="primary"
                        className="pr-5 pl-5"
                        onClick={() => handleSubmit("final-mopb")}
                        disabled={filteredResults?.length === 0 ? true : false}
                      >
                        Submit
                      </Button>
                    </center> */}
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MOPB;
