import { useEffect, useRef, useState, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Grid } from "@mui/material";
import { getSector } from "helper";
import CustomTypeahead from "components/CustomTypeahead";
import { FaPlus } from "react-icons/fa";
import { _postApi } from "redux/action/api";
import { MdDrafts } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatNumber } from "redux/action/api";
import moment from "moment";
import { expiredSetting } from "examples/admin/helper";
import CustomTable from "components/CustomTable";
import { useToasts } from "react-toast-notifications";
import BackButton from "./Back";
import { _fetchApi } from "redux/action/api";
import { Button, Col } from "reactstrap";

function BudgetProposal() {
  const mda = useSelector((state) => state.auth.user.mda);
  const today = moment().format("YYYY");
  const [data, setData] = useState([]);
  const [form, setForm] = useState({
    budget_year: today,
    economic_code: "",
    economic_description: "",
    program_code: "",
    program_description: "",
    function_code: "",
    function_description: "",
    fund_code: "",
    fund_description: "",
    geo_code: "",
    geo_description: "",
    budget_amount: "",
    actual_amount: "",
    budget_type: "",
    start_date: "",
    end_date: "",
    segment: "",
    mda_name: mda,
    project_description: "",
  });

  const [adminData, setAdminData] = useState([]);
  const [economicData, setEconomicData] = useState([]);
  const [fundData, setFundData] = useState([]);
  const [geoData, setGeoData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [functionalData, setFunctionalData] = useState([]);

  const [results6, setResults6] = useState([]);

  const getMdaData = useCallback(
    (type, callback) => {
      _postApi(
        `/select-mda-mapping?query_type=select_mda`,
        { mda_name: mda, type },
        (data) => {
          callback(data.results);
        },
        (err) => console.log(err)
      );
    },
    [mda]
  );
  const [expired, setExpired] = useState([]);

  // const getHeads = () => {
  //   _postApi(
  //     `/budgetCeiling?query_type=select`,
  //     {},
  //     (data) => {
  //       setData(data.results);
  //     },
  //     (err) => console.log(err)
  //   );
  // };

  // useEffect(() => {
  //   getSector({ sub_head: dd }, "select_subheds", (d) => {
  //     setResults1(d);
  //   });
  // }, [dd]);

  // useEffect(() => {
  //   getSector(
  //     { type: form.segment, sub_head: [sub_head_list] },
  //     "select",
  //     setResults
  //   );
  //   getHeads();
  // }, []);

  useEffect(() => {
    getSector({ type: "ECONOMIC SEGMENT" }, "select", setResults6);
  }, []);
  const handleChange = ({ target: { name, value } }) => {
    const intValue = parseInt(value, 10);

    // Update form state with the changed value
    setForm((prevForm) => ({ ...prevForm, [name]: intValue }));

    // Calculate the total of fund1, fund2, fund3, and fund4
    const totalFunds =
      parseInt(form.fund1 || 0, 10) +
      parseInt(form.fund2 || 0, 10) +
      parseInt(form.fund3 || 0, 10) +
      parseInt(form.fund4 || 0, 10);

    // Disable the funds inputs if the total funds exceed the budget_amount
    const isBudgetExceeded = totalFunds > form.budget_amount;
    if (isBudgetExceeded) {
      alert("Total funds cannot exceed the budget amount!");
      setForm((prevForm) => ({ ...prevForm, [name]: "" }));
    }
    // Set the funds inputs to disabled if the budget is exceeded
    // document.querySelectorAll(".fundInput").forEach((input) => {
    //   input.disabled = isBudgetExceeded;
    // });
  };

  const _handleChange = ({ target: { name, value } }) => {
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  // const handleChanges = (name, value, index) => {
  //   const arr = [...results1];
  //   arr[index][name] = value;
  //   arr[index] = { ...arr[index], ...form };
  //   adminData(arr);
  // };

  // const handleDelete = (index) => {
  //   const arr = [...results1];
  //   arr.splice(index, 1);
  //   adminData(arr);
  // };

  const repRef = useRef();
  const repRef1 = useRef();
  const repRef2 = useRef();
  const repRef3 = useRef();
  const repRef4 = useRef();
  const repRef5 = useRef();

  const handleFinalProposal = () => {
    submitForm("final-proposal");
  };

  const handleSaveAsDraft = () => {
    submitForm("draft");
  };

  const navigate = useNavigate();
  const res = { ...form, mda_name: mda };
  const { addToast } = useToasts();
  const submitForm = (status) => {
    const apiEndpoint = `/post-budget?query_type=INSERT&status=${status}`;
    _postApi(
      apiEndpoint,
      data,
      (res) => {
        if (res.success) {
          addToast("Submitted Successfully", {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          navigate(-1);
        }
      },
      (err) => {
        console.log(err);
        alert("An error occurred!");
      }
    );
  };
  const [results4, setResults4] = useState([]);
  useEffect(() => {
    getMdaData("ADMINISTRATIVE SEGMENT", setAdminData);
    getMdaData("ECONOMIC SEGMENT", setEconomicData);
    getMdaData("GEO SEGMENT", setGeoData);
    getMdaData("PROGRAMME SEGMENT", setProgramData);
    getMdaData("FUNCTIONAL SEGMENT", setFunctionalData);
    getSector({ type: "FUND SEGMENT" }, "select", setResults4);
    getMdaData("FUND SEGMENT", setFundData);
    expiredSetting(
      { description: "Proposed Budget End Date" },
      "select",
      (d) => {
        setExpired(d.results);
      },
      (e) => {
        console.log(e);
      }
    );
  }, [getMdaData]);

  const user = useSelector((e) => e.auth.user);
  let aheadData =
    adminData &&
    adminData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadData2 =
    economicData &&
    economicData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadData3 =
    geoData &&
    geoData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));
  let aheadData4 =
    programData &&
    programData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadData5 =
    functionalData &&
    functionalData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadData6 =
    fundData &&
    fundData?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  let aheadDataFund =
    results4 &&
    results4?.map((i) => ({
      head: `${i.head}-${i.description}`,
      description: i.description,
      code: i.head,
    }));

  const handleDelete = (index) => {
    let item = data.filter((i, idx) => index !== idx);
    // alert(JSON.stringify(item))
    setData(item);
    console.log(index);
  };
  const handleAdd = () => {
    if (
      form.budget_amount === 0 ||
      form.budget_year === "" ||
      form.admin_description === ""
    ) {
      alert("Please fill the required fields");
    } else {
      setData((p) => [...p, form]);
      setForm({
        budget_year: today,
        admin_code: "",
        admin_description: "",
        economic_code: "",
        economic_description: "",
        program_code: "",
        program_description: "",
        function_code: "",
        function_description: "",
        fund_code: "",
        fund_description: "",
        geo_code: "",
        geo_description: "",
        budget_amount: "",
        actual_amount: "",
        // budget_type: "",
        start_date: "",
        end_date: "",
        mda_name: mda,
      });
      // repRef.current.clear();
      repRef.current.clear();
      repRef1.current.clear();
      repRef2.current.clear();
      repRef3.current.clear();
      repRef4.current.clear();
      repRef5.current.clear();
    }
  };
  const fields = [
    {
      title: "Economic Description",
      custom: true,
      component: (ite) => ite.economic_description,
    },
    {
      title: "Economic Code",
      custom: true,
      component: (item) => item.economic_code,
    },
    {
      title: "Fund Description",
      custom: true,
      component: (ite) => ite.fund_description,
    },
    { title: "Fund Code", custom: true, component: (ite) => ite.fund_code },
    {
      title: "Program Description",
      custom: true,
      component: (ite) => ite.program_description,
    },
    {
      title: "Program Code",
      custom: true,
      component: (ite) => ite.program_code,
    },
    // {
    //   title: "Functional Description",
    //   custom: true,
    //   component: (ite) => ite.function_description,
    // },
    {
      title: "Functional code",
      custom: true,
      component: (ite) => ite.function_code,
    },
    {
      title: "Geo Description",
      custom: true,
      component: (ite) => ite.geo_description,
    },
    { title: "Geo code", custom: true, component: (ite) => ite.geo_code },

    {
      title: "Proposed Amount",
      custom: true,
      component: (ite, idx) => formatNumber(ite.budget_amount || 0),
    },

    {
      title: "Action",
      custom: true,
      component: (ite, idx) => (
        <div className="text-center">
          <button
            className="btn btn-danger"
            color="primary"
            onClick={() => handleDelete(idx)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  const totalAmount = data?.reduce(
    (total, item) => parseFloat(item.budget_amount) + total,
    0
  );

  let _expired = expired[0]?.end_date;
  const submissionDeadline = moment(_expired);
  const daysLeft = submissionDeadline.diff(moment(), "days");
  const [ceiling, setCeiling] = useState([]);
  const [buget, setBuget] = useState([]);
  useEffect(() => {
    const encodedMda = encodeURIComponent(user?.mda);
    const encodedQueryType = encodeURIComponent("ceiling");

    _fetchApi(
      `/get-total-ceiling?mda_name=${encodedMda}&query_type=${encodedQueryType}`,
      (res) => {
        // alert(JSON.stringify(res.results));
        setCeiling(res.results);
      },
      (err) => {
        console.error(err);
      }
    );
    _fetchApi(
      `/get-total-ceiling?mda_name=${encodedMda}&query_type=budget`,
      (res) => {
        const budgetArray = res.results;

        const totalBudget = budgetArray.reduce((sum, item) => {
          const budgetAmount = parseInt(item.budget_amount, 10); // Convert string to integer
          return sum + budgetAmount;
        }, 0);

        setBuget(totalBudget);
      },
      (err) => {
        console.error(err);
      }
    );
  }, []);

  const totalCeiling = parseInt(ceiling[0]?.total_amt) - parseInt(buget);
  const [isFund, setIsFund] = useState(false);
  // const totalFund = form.fund1 + form.fund2 + form.fund3 + form.fund4;

  return (
    <>
      <DashboardLayout style={{ marginTop: 0 }}>
        <MDBox pt={4} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <center>
                    <MDTypography variant="h2" color="white">
                      <center>{user?.mda}</center>
                    </MDTypography>
                  </center>
                </MDBox>
                <MDBox>
                  <div className="d-flex">
                    <BackButton className="mt-2 m-2 mr-5" />
                    <div style={{ marginRight: "300px" }}></div>
                    <MDTypography variant="h5" color="dark" mt={2} mr={5}>
                      <center>Budget Proposal Page</center>
                    </MDTypography>
                  </div>
                  {/* {JSON.stringify(form)} */}

                  <div className="w-50 mx-4">
                    <label>Type of Funding</label>
                    <select
                      className="form-control"
                      value={form.type_of_funding}
                      onChange={({ target: { value } }) =>
                        setForm((prev) => ({ ...prev, type_of_funding: value }))
                      }
                    >
                      <option value={"normal"}>Normal</option>
                      <option value={"counterpart"}>Counterpart Funding</option>
                      <option value={"Countengency Recurrent"}>
                        Countengency Recurrent
                      </option>
                      <option value={"Countengency Capital"}>
                        Countengency Capital
                      </option>
                    </select>
                  </div>
                  <br />
                  <b className="m-5">
                    Total Ceiling :₦{formatNumber(ceiling[0]?.total_amt || 0)}
                  </b>
                  <b className="m-5">
                    Total Budget :₦{formatNumber(buget || 0)}
                  </b>
                  <b className="m-5">
                    Actual Amount :₦{formatNumber(totalCeiling || 0)}
                  </b>
                  <MDBox pt={3}>
                    {/* <center>
                      <div
                        className={
                          daysLeft < 20 ? "text-danger" : "text-success"
                        }
                      >
                        {daysLeft} {daysLeft === 1 ? "day" : "days"} left to
                        submit your budget proposal, Submission will close on{" "}
                        {submissionDeadline.format("Do MMMM, YYYY")}
                      </div>
                    </center> */}
                    <div className=" row mb-4 p-3">
                      <div className="col-md-4">
                        <lable>Budget Year</lable>
                        <input
                          className="form-control"
                          type="number"
                          maxLength="4"
                          name="budget_year"
                          value={form.budget_year}
                          onChange={handleChange}
                        />
                      </div>
                      {/* {JSON.stringify({aheadData})} */}
                      <div className="col-md-4">
                        <lable>Project Description </lable>
                        <input
                          className="form-control"
                          name="project_description"
                          value={form.project_description}
                          onChange={_handleChange}
                        />
                      </div>
                      {/* <div className="col-md-4">
                        <lable>Capital Receipt </lable>
                        <input
                          className="form-control"
                          name="capital_receipt"
                          value={form.capital_receipt}
                          onChange={_handleChange}
                        />
                      </div> */}
                      <div className="col-md-4">
                        <CustomTypeahead
                          ref={repRef}
                          labelKey="head"
                          label="Adminstrative Segment"
                          options={aheadData}
                          onChange={(s) => {
                            if (s.length) {
                              setForm((p) => ({
                                ...p,
                                admin_code: s[0].code,
                                admin_description: s[0].description,
                              }));
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomTypeahead
                          ref={repRef1}
                          labelKey="head"
                          label="Economic Segment"
                          options={aheadData2}
                          onChange={(s) => {
                            if (s.length) {
                              setForm((p) => ({
                                ...p,
                                economic_code: s[0].code,
                                economic_description: s[0].description,
                              }));
                            }
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomTypeahead
                          ref={repRef2}
                          labelKey="head"
                          label="Fund Segment"
                          options={aheadData6}
                          onChange={(s) => {
                            if (s.length) {
                              setForm((p) => ({
                                ...p,
                                fund_code: s[0].code,
                                fund_description: s[0].description,
                              }));
                            }
                          }}
                        />
                      </div>
                      {/* {form.project_description === "" && ( */}
                      <>
                        <div className="col-md-4">
                          <CustomTypeahead
                            ref={repRef3}
                            labelKey="head"
                            label="Geo Segment"
                            options={aheadData3}
                            onChange={(s) => {
                              if (s.length) {
                                setForm((p) => ({
                                  ...p,
                                  geo_code: s[0].code,
                                  geo_description: s[0].description,
                                }));
                              }
                            }}
                          />
                        </div>

                        <div className="col-md-4">
                          <CustomTypeahead
                            ref={repRef4}
                            labelKey="head"
                            label="Program Segment"
                            options={aheadData4}
                            onChange={(s) => {
                              if (s.length) {
                                setForm((p) => ({
                                  ...p,
                                  program_code: s[0].code,
                                  program_description: s[0].description,
                                }));
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <CustomTypeahead
                            ref={repRef5}
                            labelKey="head"
                            label="Functional Segment"
                            options={aheadData5}
                            onChange={(s) => {
                              if (s.length) {
                                setForm((p) => ({
                                  ...p,
                                  function_code: s[0].code,
                                  function_description: s[0].description,
                                }));
                              }
                            }}
                          />
                        </div>
                      </>
                      <div className="col-md-4">
                        <small>Proposed Amount</small>
                        <input
                          className="form-control"
                          type="number"
                          maxLength="4"
                          name="budget_amount"
                          value={form.budget_amount}
                          onChange={handleChange}
                        />
                      </div>
                      {/* )} */}
                      {isFund ? (
                        <>
                          <div className="col-md-4">
                            <CustomTypeahead
                              ref={repRef2}
                              labelKey="head"
                              label="Fund Code"
                              options={aheadDataFund}
                              onChange={(s) => {
                                if (s.length) {
                                  setForm((p) => ({
                                    ...p,
                                    fund_code1: s[0].code,
                                    fund_description1: s[0].description,
                                  }));
                                }
                              }}
                            />
                          </div>
                          <Col md={4}>
                            <label>Fund 1 Amount</label>
                            <input
                              className="form-control"
                              type="number"
                              // maxLength="4"
                              name="fund1"
                              value={form.fund1}
                              onChange={handleChange}
                            />
                          </Col>
                          <div className="col-md-4">
                            <CustomTypeahead
                              ref={repRef2}
                              labelKey="head"
                              label="Fund Code"
                              options={aheadDataFund}
                              onChange={(s) => {
                                if (s.length) {
                                  setForm((p) => ({
                                    ...p,
                                    fund_code2: s[0].code,
                                    // fund_description: s[0].description,
                                  }));
                                }
                              }}
                            />
                          </div>
                          <Col md={4}>
                            {" "}
                            <label>Fund 2 Amount</label>
                            <input
                              className="form-control"
                              type="number"
                              // maxLength="4"
                              name="fund2"
                              value={form.fund2}
                              onChange={handleChange}
                            />
                          </Col>{" "}
                          <div className="col-md-4">
                            <CustomTypeahead
                              ref={repRef2}
                              labelKey="head"
                              label="Fund Code"
                              options={aheadDataFund}
                              onChange={(s) => {
                                if (s.length) {
                                  setForm((p) => ({
                                    ...p,
                                    fund_code3: s[0].code,
                                    // fund_description: s[0].description,
                                  }));
                                }
                              }}
                            />
                          </div>
                          <Col md={4}>
                            {" "}
                            <label>Fund 3 Amount</label>
                            <input
                              className="form-control"
                              type="number"
                              // maxLength="4"
                              name="fund3"
                              value={form.fund3}
                              onChange={handleChange}
                            />
                          </Col>
                          <div className="col-md-4">
                            <CustomTypeahead
                              ref={repRef2}
                              labelKey="head"
                              label="Fund Code"
                              options={aheadDataFund}
                              onChange={(s) => {
                                if (s.length) {
                                  setForm((p) => ({
                                    ...p,
                                    fund_code4: s[0].code,
                                    // fund_description: s[0].description,
                                  }));
                                }
                              }}
                            />
                          </div>
                          <Col md={4}>
                            <label>Fund 4 Amount</label>
                            <input
                              className="form-control"
                              type="number"
                              // maxLength="4"
                              name="fund4"
                              value={form.fund4}
                              onChange={handleChange}
                            />
                          </Col>
                        </>
                      ) : (
                        <Col md={2} className="mt-4">
                          <Button
                            color="primary"
                            onClick={() => setIsFund(true)}
                          >
                            Add Fund
                          </Button>
                        </Col>
                      )}
                    </div>
                    <center className="mb-3">
                      <button
                        className="btn btn-primary px-5"
                        onClick={handleAdd}
                      >
                        Add
                      </button>
                    </center>
                    <div className="p-3">
                      <span style={{ fontWeight: "bold", float: "right" }}>
                        TOTAL : {formatNumber(totalAmount || 0)}
                      </span>
                    </div>
                    <br />
                    {/* <Scrollbar> */}
                    {/* {JSON.stringify(results[0])} */}

                    <CustomTable fields={fields} data={data} />
                    {/* </Scrollbar> */}
                    {data.length ? (
                      <center>
                        <button
                          className="btn btn-success pr-5 pl-5 m-3"
                          onClick={handleFinalProposal}
                        >
                          <FaPlus /> Final Proposal
                        </button>
                        <button
                          className="btn btn-warning pr-5 pl-5 px-2 m-3"
                          onClick={handleSaveAsDraft}
                        >
                          <MdDrafts /> Save As Draft
                        </button>
                      </center>
                    ) : (
                      ""
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default BudgetProposal;
