import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import CustomInput from "./CustomInput";
import { Card } from "reactstrap";

export default function CcCopies() {
  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3} pl={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  CC COPIES SETUP FORMS
                </MDTypography>
              </MDBox>
              <div className="cccopes">
                <div className="title">
                  <h3 className="m-3">Add cc copies</h3>
                  <CustomInput />
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
