/* eslint-disable react/prop-types */
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { postApi } from "redux/action/api_tbeams";
import ReleasesTable from "./ReleasesTable";

export default function ReleaseCollection(props) {
  const { type } = props;
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [budgetUtilization, setBudgetUtilization] = useState([]);
  const [editDropdownOpen, setEditDropdownOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // To store the index of the row where the dropdown is open
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [form, setForm] = useState({
    date_from: "",
    date_to: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleEditDropdown = (idx) => {
    setEditDropdownOpen((prevState) => !prevState);
    setSelectedRow(idx);
  };

  const location = useLocation();
  const isPending = location.pathname.includes("payapp");
  const [filter, setFilter] = useState(type || "All");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...formData, [name]: value });
  };

  const getApprovalCollection = useCallback(() => {
    setIsLoading(true);
    postApi(`post_approval_collection`, {
      form: {
        query_type: isPending ? "pending" : "by_filter",
        filter,
        type: "release",
      },
    })
      .then((data) => {
        console.log("data", data);
        if (data.result) {
          const arr = data.result.map((item) => ({
            ...item,
            isOpen: false,
          }));
          setBudgetUtilization(arr);
        }
      })
      .catch((err) => {
        // Handle errors
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filter, isPending]);

  useEffect(() => {
    getApprovalCollection();
  }, [getApprovalCollection]);

  const filteredData = budgetUtilization.filter((item) =>
    item.mda_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getRowStyle = (row) => {
    switch (row.approval_status) {
      case "Approval Released":
        return { backgroundColor: "orange" };
      case "Approval Processed":
        return { backgroundColor: "lightblue" };
      case "Approval Approved":
        return { backgroundColor: "lightgreen" };
      case "Approval Queried":
        return { backgroundColor: "yellow" };
      case "Payment Schedule":
        return { backgroundColor: "blue" };
      case "No Budget":
        return { backgroundColor: "#cc8888" };
      case "paid":
        return { backgroundColor: "green" };
      default:
        return { backgroundColor: "" };
    }
  };

  const updateApproval = (approval_no, status) => {
    setApprovalLoading(true);
    postApi("update-approval", {
      remarks: "",
      status,
      approval_no,
      // userId: user.username,
    })
      .then((resp) => {
        setApprovalLoading(false);
        if (resp && resp.success) {
          toast.success("Approval Submitted Successfully!");
          // setApprovalMessage(status==="Return Processed"?"Returned Approval Successfully":"Approved Successfully");
          // setTimeout(() => {
          //   toggleRemark();
          //   getApprovalCollection();
          // }, 1000);
          getApprovalCollection();
        }
      })
      .catch((err) => {
        setApprovalLoading(false);
        toast.error("An error occured, please try again later");
        console.log(err);
      });
  };

  return (
    <div>
      <ReleasesTable
        filterBy="approvals Reviewed"
        approval_status="Approval Released"
        reject_status=""
        title="Release Collection"
        query_type={isPending ? "pending" : "by_filter"}
        return_status="Approval Initiated Returned"
        showButton={true}
      />
      <Modal isOpen={approvalLoading}>
        <ModalBody className="text-center">
          <Spinner />
          <h4>Please wait</h4>
        </ModalBody>
      </Modal>
    </div>
  );
}
