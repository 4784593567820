/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useRef, useState } from "react";
import Scrollbar from "components/Tree/Scrollbar";

import moment from "moment";
// import SearchBar from "components/SearchBar";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import { capitalizeWords } from "redux/action/api";
import { unflattenTable, unflatten } from "redux/action/api";
import {
  ButtonGroup,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import MDAReportModal from "./MDAReportsModal";
import { filterZeroRows } from "redux/action/api";
import { formatNumber } from "redux/action/api";
import { unflattenTableNew } from "redux/action/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { headerColors } from "redux/action/api";
import { removeEmptyChildren } from "redux/action/api";

export default function RevenueReports({
  type = "total_revenue_by_mda",
  route = "/get-report",
  options = [],
  title = "",
}) {
  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: type,
  });
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const [selectedOption, setSelectedOption] = useState({
    value: type,
    title,
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title) => {
    setSelectedOption({ value, title });
  };

  const likeVar = selectedOption?.title.includes("Personnel")
    ? "21%"
    : selectedOption?.title.includes("Debt")
    ? "2206%"
    : selectedOption?.title.includes("Capital")
    ? "23%"
    : selectedOption?.title.includes("Over-head")
    ? "22%"
    : null;
  let excludeArr = [
    "fac_amt",
    "igr_amt",
    "aid_amt",
    "cap_amt",
    "other_amt",
    "total_amt",
  ];
  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}&budget_year=${form.budget_year}&like=${likeVar}`,
      (data) => {
        let _data = data?.results;
        const modifiedResults = [..._data];
        const adminSegmentIndex = modifiedResults.findIndex(
          (item) => item.description === "Administative segment"
        );

        if (adminSegmentIndex !== -1) {
          // If found, empty the "sub_head" for that specific index
          modifiedResults[adminSegmentIndex].sub_head = "";
          modifiedResults[adminSegmentIndex].head = "";
        }
        const newY = removeEmptyChildren(excludeArr, data?.results, 3);
        const newZ = removeEmptyChildren(excludeArr, data?.results, 2);
        const tt = unflattenTable(_data);
        console.log(tt, "modified");
        setTreeData(tt);

        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value, likeVar]);

  const headers = [
    { label: "Codes", key: "code" },
    { label: "Description", key: "description" },
    { label: "Federation Acct Revenue", key: "fac_amt" },
    {
      label: `IGR`,
      key: "igr_amt",
    },
    {
      label: `Total Recurrent Revenue`,
      key: "rec_amt",
    },
    {
      label: `Aids and Grants`,
      key: "aid_amt",
    },
    {
      label: `Capital Development Fund Receipts`,
      key: "cap_amt",
    },
    {
      label: `Total Other Receipts`,
      key: "other_amt",
    },
    {
      label: `Total Revenue`,
      key: "total_amt",
    },
  ];

  // const headerColors = ["#000", "#666", "#999", "#ccc", "#eee"];
  // const headerColors = [
  //   "#999",
  //   "#ccc",
  //   "#eee",
  //   "#f2f2f2",
  //   "#f5f5f5",
  //   "#f9f9f9",
  // ];

  const calculatefac = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.fac_amt;
    } else {
      let sum = node.fac_amt || 0;
      for (const child of node.children) {
        sum += calculatefac(child);
      }
      return sum;
    }
  };

  const calculateIGR = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.igr_amt;
    } else {
      let sum = node.igr_amt || 0;
      for (const child of node.children) {
        sum += calculateIGR(child);
      }
      return sum;
    }
  };
  const calculateRec = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.rec_amt;
    } else {
      let sum = node.rec_amt || 0;
      for (const child of node.children) {
        sum += calculateRec(child);
      }
      return sum;
    }
  };

  const calculateAid = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.aid_amt;
    } else {
      let sum = node.aid_amt || 0;
      for (const child of node.children) {
        sum += calculateAid(child);
      }
      return sum;
    }
  };

  const calculateCap = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.cap_amt;
    } else {
      let sum = node.cap_amt || 0;
      for (const child of node.children) {
        sum += calculateCap(child);
      }
      return sum;
    }
  };
  const calculateOtherRe = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.other_amt;
    } else {
      let sum = node.other_amt || 0;
      for (const child of node.children) {
        sum += calculateOtherRe(child);
      }
      return sum;
    }
  };
  const calculateTotalRev = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.total_amt;
    } else {
      let sum = node.total_amt || 0;
      for (const child of node.children) {
        sum += calculateTotalRev(child);
      }
      return sum;
    }
  };

  const tableRef = useRef(null);

  const newFilter = (item) => {
    return (
      item.fac_amt === 0 &&
      item.igr_amt === 0 &&
      item.rec_amt === 0 &&
      item.aid_amt === 0 &&
      item.cap_amt === 0 &&
      item.other_amt === 0
    );
  };

  const newFilterHead = (item) => {
    return (
      calculatefac(item) === 0 &&
      calculateIGR(item) === 0 &&
      calculateRec(item) === 0 &&
      calculateAid(item) === 0 &&
      calculateCap(item) === 0 &&
      calculateOtherRe(item) === 0
    );
  };

  const generateTable2 = () => {
    const renderItems = (item, index) => {
      const isTitle =
        item.code === "0000000000000000000" ||
        item.code === "1000000000000000000" ||
        item.code === "2000000000000000000" ||
        item.code === "3000000000000000000" ||
        item.code === "5000000000000000000" ||
        item.code === "7000000000000000000" ||
        item.code === "8000000000000000000";
      return (
        <React.Fragment key={`group_${index}`}>
          {newFilterHead(item) ? null : (
            <tr
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => {
                toggle();
                setSelectMDA(item);
                console.log(item);
              }}
            >
              {/* {isTitle ? "" : item.code} */}
              <td
                style={{
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isTitle ? "" : item.code.replace(/0+$/, "")}
              </td>
              <td
                style={{
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isTitle ? "Total Revenue" : item.description}

                {/* {form.type} */}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(parseFloat(calculatefac(item)))
                  ? "---"
                  : formatNumber(calculatefac(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(parseFloat(calculateIGR(item)))
                  ? "---"
                  : formatNumber(calculateIGR(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(parseFloat(calculateRec(item)))
                  ? "---"
                  : formatNumber(calculateRec(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(parseFloat(calculateAid(item)))
                  ? "---"
                  : formatNumber(calculateAid(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(parseFloat(calculateCap(item)))
                  ? "---"
                  : formatNumber(calculateCap(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(parseFloat(calculateOtherRe(item)))
                  ? "---"
                  : formatNumber(calculateOtherRe(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isNaN(parseFloat(calculateTotalRev(item)))
                  ? "---"
                  : formatNumber(calculateTotalRev(item))}
              </td>
            </tr>
          )}
          {item.children.map((child, idx) => {
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else if (newFilter(child)) {
              return null;
            } else {
              return (
                <tr key={child.code} style={{ fontSize: "11px" }}>
                  <td>{child.code}</td>
                  <td>{child.description}</td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.fac_amt))
                      ? "---"
                      : formatNumber(child.fac_amt)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.igr_amt))
                      ? "---"
                      : formatNumber(child.igr_amt)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.rec_amt))
                      ? "---"
                      : formatNumber(child.rec_amt)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.aid_amt))
                      ? "---"
                      : formatNumber(child.aid_amt)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.cap_amt))
                      ? "---"
                      : formatNumber(child.cap_amt)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.other_amt))
                      ? "---"
                      : formatNumber(child.other_amt)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(child.total_amt))
                      ? "---"
                      : formatNumber(child.total_amt)}
                  </td>
                </tr>
              );
            }
          })}
        </React.Fragment>
      );
    };

    return (
      <table className="table table-bordered" ref={tableRef}>
        <thead>
          <tr
            style={{
              fontSize: "12px",
              borderWidth: 1,
              borderColor: "black",
              textAlign: "center",
              backgroundColor: "orange",
            }}
          >
            <th style={{ backgroundColor: "orange" }}>Code</th>
            <th style={{ backgroundColor: "orange" }}>Description</th>
            <th style={{ backgroundColor: "orange" }}>
              Federation Acct Revenue
            </th>
            <th style={{ backgroundColor: "orange" }}>
              Independent Revenue (IGR)
            </th>
            <th style={{ backgroundColor: "orange" }}>
              Total Recurrent Revenue
            </th>
            <th style={{ backgroundColor: "orange" }}>Aids and Grants</th>
            <th style={{ backgroundColor: "orange" }}>
              Capital Development Fund Receipts
            </th>
            <th style={{ backgroundColor: "orange" }}>Total Other Receipts</th>
            <th style={{ backgroundColor: "orange" }}>Total Revenue</th>
          </tr>
        </thead>
        <tbody>{treeData.map((item, i) => renderItems(item, i))}</tbody>
        {/* {JSON.stringify(treeData.length)} */}
      </table>
    );
  };

  return (
    <>
      <DashboardLayout>
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div>
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  size="lg"
                  style={{
                    marginLeft: "30%",
                  }}
                >
                  <MDAReportModal
                    toggle={toggle}
                    mda_name={selectMDA.description}
                  />
                </Modal>
              </div>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h5" color="white">
                    {capitalizeWords(selectedOption.title)}
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  {/* {JSON.stringify({ selectedOption })} */}
                  <div
                    className="d-flex justify-content-between m-1 row"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  >
                    <div className="col-sm-2 mb-2">
                      <Input
                        type="select"
                        name="budget_year"
                        value={form.budget_year}
                        onChange={handleChange}
                      >
                        <option>--select--</option>
                        {years?.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </Input>
                    </div>
                    <div className="col-sm-8 mb-2">
                      <center>
                        <ButtonGroup>
                          {options.map((item) => (
                            <Button
                              size="sm"
                              key={item.title}
                              outline={
                                selectedOption.title !== item.title
                                  ? true
                                  : false
                              }
                              color={
                                selectedOption.title === item.title
                                  ? "primary"
                                  : "secondary"
                              }
                              onClick={() =>
                                handleButtonClick(item.value, item.title)
                              }
                            >
                              <small>{item.title}</small>
                            </Button>
                          ))}
                        </ButtonGroup>
                      </center>
                    </div>
                    <div className="col-sm-2 mb-2">
                      {/* <Button
                        style={{ color: "white" }}
                        className="text-white mb-3 ml-3"
                        size="sm"
                        color="primary"
                      >
                        <CSVLink
                          data={treeData && treeData ? treeData : []}
                          className="text-white"
                          filename={capitalizeWords(selectedOption.title)}
                          headers={headers}
                        >
                         
                        </CSVLink>
                      </Button> */}
                      <DownloadTableExcel
                        filename={selectedOption.title}
                        sheet={capitalizeWords(selectedOption.title)}
                        currentTableRef={tableRef.current}
                      >
                        <Button
                          style={{ color: "white" }}
                          className="text-white mb-3 ml-3"
                          size="sm"
                          color="primary"
                        >
                          {" "}
                          <FaFileDownload color="white" size="1.2rem" />{" "}
                          Download Excel
                        </Button>
                      </DownloadTableExcel>
                    </div>
                  </div>
                  <div className="p-3">
                    {/* {JSON.stringify(treeData)} */}
                    <Scrollbar>
                      {loading && (
                        <div className="loading-container text-center">
                          <Spinner
                            color="primary"
                            style={{ marginTop: "20px" }}
                          />{" "}
                          Loading...
                        </div>
                      )}
                      {generateTable2()}
                    </Scrollbar>
                  </div>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
