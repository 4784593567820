import React from "react";
import ReleasesTable from "./ReleasesTable";

export default function ReleaseCancellation(props) {
  return (
    <>
      <ReleasesTable
        filterBy="Discard"
        approval_status="Approval Initiated"
        title="Discard Releases"
        _approval_status="select_by_status"
        return_status="Approval Initiated"
      />
    </>
  );
}
