import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import { _postApi } from "redux/action/api";

export default function ContengencyTable() {
  const navigate = useNavigate();
  const [planningReserve, setPlanningReserve] = useState([]);
  const [filter, setFilter] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState({});
  const [editDropdownOpen, setEditDropdownOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle1 = () => {
    setModal(!modal);
    setDeleteRow(!deleteRow);
  };

  const toggleEditDropdown = (idx) => {
    setEditDropdownOpen((prevState) => !prevState);
    setSelectedRow(idx);
  };
  const route = useLocation();
  const type =
    route.pathname === "/contingency-table-capital"
      ? "Capital"
      : route.pathname === "/contingency-table-recurerent"
      ? "Recurrent"
      : "AIE";
  const getPlanningReserve = () => {
    setIsLoading(true);
    _postApi(
      "/contengency-reserve",
      { query_type: "SELECT", type: type },
      (res) => {
        setPlanningReserve(res.results);
        setIsLoading(false);
      },
      (err) => {
        alert("error");
        console.log(err);
      }
    );
  };

  useEffect(() => {
    getPlanningReserve();
  }, [type]);

  const getRowStyle = (row) => {
    switch (row.reserve_status) {
      case "Pending":
        return { backgroundColor: "lightgreen" };
      case "Approved":
        return { backgroundColor: "yellow" };
      default:
        return { backgroundColor: "" };
    }
  };

  //   const approveRequest = () => {
  //     console.log(data, "xxxx");
  //     setIsUpdating(true);
  //     postApi(`planning-reservation`, {
  //       query_type: "final_approve",
  //       id: data.id,
  //     })
  //       .then((data) => {
  //         console.log("data", data);
  //         if (data.success) {
  //           setModal(false);
  //           getPlanningReserve();
  //         }
  //       })
  //       .catch((err) => {
  //         // Handle errors
  //         console.log(err);
  //         alert("An error occurred");
  //       })
  //       .finally(() => {
  //         setIsUpdating(false);
  //       });
  //   };

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3} pl={2}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {route.pathname === "/contingency-table-capital"
                    ? "Contingency Reserved Capital"
                    : route.pathname === "/contingency-table-recurerent"
                    ? "Contingency Reserved Recurrent"
                    : "AIE"}
                </MDTypography>
              </MDBox>
              {/* {JSON.stringify(route.pathname)} */}
              <Col md={4} className="mt-4">
                <button
                  className="btn btn-success"
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                  onClick={() =>
                    navigate(
                      `${
                        route.pathname === "/contingency-table-capital"
                          ? "/contingency-reserve-capital"
                          : route.pathname === "/contingency-table-recurerent"
                          ? "/contingency-reserve-recurrent"
                          : "/contingency-reserve-aie"
                      }`
                    )
                  }
                >
                  Add New
                </button>
              </Col>

              <Col
                md={11}
                style={{
                  margin: 18,
                }}
              >
                <Label>Search</Label>
                <Input
                  type="Text"
                  name="search"
                  placeholder="Search Table.................."
                  // value={}
                />
              </Col>
              {/* {JSON.stringify(planningReserve)} */}
              <div
                className="mt-4"
                style={{
                  width: "100%",
                  height: 400,
                  overflowX: "scroll",
                }}
              >
                <Table
                  style={{
                    width: "100%",
                  }}
                  bordered
                  striped
                >
                  <thead>
                    <tr>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Admin
                      </td>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Admin Code
                      </td>

                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Economic
                      </td>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Economic Code
                      </td>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Amount
                      </td>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Type
                      </td>
                      <td
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Action
                      </td>
                    </tr>
                  </thead>
                  {isLoading ? (
                    <Spinner color="primary" />
                  ) : (
                    <tbody>
                      {planningReserve.map((item, idx) => (
                        <tr key={idx} style={{}}>
                          <td>{item.mda_name}</td>
                          <td>{item.mda_code}</td>
                          <td>{item.economic}</td>
                          <td>{item.economic_code}</td>
                          <td>{item.amount}</td>
                          <td>{item.type}</td>
                          <td>
                            <Button color="success">View</Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader>
                    <span className="text-center" style={{ width: "100%" }}>
                      <center>
                        {deleteRow ? (
                          <p
                            style={{
                              color: "red",
                              fontWeight: "500",
                            }}
                          >
                            Are You Sure You want to return{" "}
                          </p>
                        ) : (
                          <p
                            style={{
                              color: "#54AC58",
                              fontWeight: "500",
                            }}
                          >
                            Are You Sure You want to Approve this request?
                          </p>
                        )}
                      </center>
                    </span>
                  </ModalHeader>
                  <ModalBody>
                    <Col md={12}>
                      {deleteRow ? (
                        <Col md={12}>
                          <Label>Add Remark</Label>
                          <Input
                            type="textarea"
                            name="text"
                            style={{
                              borderColor: "Red",
                              borderWidth: 2,
                            }}
                          />
                        </Col>
                      ) : (
                        <Col md={12}>
                          <Label> Add Remark</Label>
                          <Input
                            type="textarea"
                            name="text"
                            style={{
                              borderColor: "#54AC58",
                              borderWidth: 2,
                            }}
                          />
                        </Col>
                      )}
                    </Col>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-center">
                    {deleteRow ? (
                      <button color="danger" className="btn btn-danger">
                        Return
                      </button>
                    ) : (
                      <button
                        // onClick={approveRequest}
                        className="btn btn-primary"
                      >
                        Approve
                      </button>
                    )}
                    <Button color="warning" onClick={toggle}>
                      cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
