import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FaFileExcel } from "react-icons/fa";
import { utils as XLSXUtils, writeFile as writeExcelFile } from "xlsx";
import CustomTree from "./CustomTree2";
import useIsPhoneSize from "layouts/useIsPhoneSize";
import { toggleExpandedForAll } from "react-sortable-tree";
import SearchBar from "components/SearchBar";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { FaEye, FaPen, FaPlus } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { PDFViewer } from "@react-pdf/renderer";
import CollectionsSummaryPDF from "./StructurePdf";
import { MdCancel } from "react-icons/md";
import StructureExcel from "./StructureExcel";

function StructureTree({
  editNode = (f) => f,
  deleteNode = (f) => f,
  addChild = (f) => f,
  treeData = [],
  title = "",
}) {
  const [searchText, setSearchText] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [filteredTreeData, setFilteredTreeData] = useState(treeData);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const expandedTreeData = treeData.map((node) => ({
      ...node,
      expanded: expanded,
    }));
    setFilteredTreeData(expandedTreeData);
  }, [expanded, treeData]);

  useEffect(() => {
    if (!searchText) {
      setFilteredTreeData(treeData);
    } else {
      const filterTree = (data) =>
        data
          .map((node) => {
            if (
              node.head.toLowerCase().includes(searchText.toLowerCase()) ||
              node.description.toLowerCase().includes(searchText.toLowerCase())
            ) {
              return {
                ...node,
                expanded: true,
                children: node.children ? filterTree(node.children) : [],
              };
            }
            return null;
          })
          .filter((node) => node !== null);
      const filteredData = filterTree(treeData);
      setFilteredTreeData(filteredData);
    }
  }, [searchText, treeData]);

  function expand(expanded) {
    setFilteredTreeData(
      toggleExpandedForAll({
        treeData,
        expanded,
      })
    );
  }

  function expandAll() {
    expand(true);
  }

  function collapseAll() {
    expand(false);
  }

  const [print, setPrint] = useState(false);

  const open = () => {
    setPrint(!print);
  };

  const close = () => {
    setPrint(false);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const isPhoneSize = useIsPhoneSize();

  return (
    <>
      {print ? (
        <div>
          <Button color="danger" onClick={close}>
            <MdCancel /> Close
          </Button>

          <PDFViewer width="1200" height="600">
            <CollectionsSummaryPDF data={treeData} />
          </PDFViewer>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-md-4 ml-5 m-sm-5 mb-1 mt-0 pl-5">
              <SearchBar
                placeholder="Search By code or description"
                filterText={searchText}
                onFilterTextChange={handleSearchChange}
              />
            </div>
            <div
              className={`col-md-6  ${isPhoneSize} ? d-flex justify-content-center align-items-center  : "" `}
            >
              <button
                onClick={expandAll}
                className="btn btn-primary mb-2 sm-btn"
                style={{
                  marginRight: "10px",
                  marginLeft: "10px",
                  padding: isPhoneSize ? "6px" : "",
                }}
              >
                EXPAND ALL
              </button>
              <button
                onClick={collapseAll}
                className="btn btn-primary mb-2 sm-btn"
                style={{
                  marginRight: "10px",
                  marginLeft: "10px",
                  padding: isPhoneSize ? "6px" : "",
                }}
              >
                COLLAPSE ALL
              </button>
              <button
                style={{ border: "none", background: "transparent" }}
                className="ms-sm-2 mb-2 sm-btn"
              >
                <Dropdown isOpen={dropdownOpen} toggle={toggle} color="primary">
                  <DropdownToggle
                    caret
                    color="primary"
                    className="sm-btn shadow-none sm-btn-1"
                  >
                    {" "}
                    EXPORTS TO
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      {" "}
                      <StructureExcel data={treeData} title={title} />
                    </DropdownItem>
                    <DropdownItem onClick={open}>PDF</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </button>
            </div>
          </div>
          <div id="print_tree">
            {/* {JSON.stringify(title)} */}
            <CustomTree
              generateNodeProps={({ node, path }) => ({
                title: (
                  <span
                    style={{
                      textTransform: node.children.length
                        ? "uppercase"
                        : "capitalize",
                    }}
                  >
                    {`${node.head}-${node.description}`}
                  </span>
                ),
                buttons: [
                  <button
                    className="btn btn-primary btn-sm mr-5 ml-5 no-print"
                    onClick={() => addChild(node)}
                    style={{ marginRight: "5px", color: "white" }}
                    key="addChild"
                  >
                    <FaPlus />
                  </button>,
                  <button
                    className="btn btn-primary btn-sm mr-5 ml-5 no-print"
                    onClick={() => editNode(node)}
                    style={{ marginRight: "5px" }}
                    key="editNode"
                  >
                    <FaPen />
                  </button>,
                  !node.children.length && (
                    <button
                      className="btn btn-danger btn-sm ml-3 no-print"
                      onClick={() => {
                        deleteNode(node);
                      }}
                      key="deleteNode"
                    >
                      <BsTrash />
                    </button>
                  ),
                ],
              })}
              treeData={filteredTreeData}
            />
          </div>
        </div>
      )}
    </>
  );
}

StructureTree.propTypes = {
  editNode: PropTypes.func,
  deleteNode: PropTypes.func,
  addChild: PropTypes.func,
  treeData: PropTypes.array,
  title: PropTypes.string,
};

export default StructureTree;
