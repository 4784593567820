/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useState } from "react";
import Scrollbar from "components/Tree/Scrollbar";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import SearchBar from "components/SearchBar";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import { capitalizeWords } from "redux/action/api";
import { Spinner } from "reactstrap";
import { formatNumber } from "redux/action/api";
import { formatNumbers } from "redux/action/api";
import { postApi } from "redux/action/api_tbeams";

export default function ReportBudgetShareCom({
  type = "budget_overview",
  route = "/get-report",
  options = [],
}) {
  const [results, setResults] = useState();
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: type,
  });
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const years = useYear();

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const [selectedOption, setSelectedOption] = useState({
    value: "budget_overview",
    title: "Budget Overview",
  });

  const handleChangeSelect = (event) => {
    const selectedIndex = event.target.value;
    setSelectedOption(options[selectedIndex]);
  };
  const [openingBal, seTOpeningBal] = useState();
  const getOpeningBalance = () => {
    postApi(`opening_balance?query_type=select`, {})
      .then((data) => {
        console.log("data", data);
        if (data.results) {
          seTOpeningBal(data.results);
        }
      })
      .catch((err) => {
        console.log(err);
        addToast("Fail to Fetch", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    getOpeningBalance();
  }, []);
  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}&budget_year=${form.budget_year}`,
      (data) => {
        setResults(data?.results);
        setLoading(false);
        // console.log(data?.results, "RAW DATA");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value]);
  const openBalance = parseFloat(
    openingBal && openingBal[0]?.opening_balance
  ).toFixed(1);
  const groupedData = results?.reduce((acc, item) => {
    const economicCode = item.economic_code.substring(0, 2);
    const group =
      economicCode === "11"
        ? "11 - Govt. Share of FAAC"
        : economicCode === "12"
        ? "12 - Independent Revenue"
        : economicCode === "21"
        ? "21 - Personnel Cost"
        : economicCode === "22"
        ? "22 - Other Recurrent Costs"
        : economicCode === "13"
        ? "13 - AID AND GRANTS"
        : economicCode === "14"
        ? "14 - Capital Development (CDF) Receipts"
        : economicCode === "32"
        ? "Capital_Expenditure"
        : "Total_Expenditure" || "Total_Revenue" || "Closing_Balance";

    const category =
      group === "11 - Govt. Share of FAAC" ||
      group === "12 - Independent Revenue"
        ? "Recurrent_Revenue"
        : group === "21 - Personnel Cost" ||
          group === "22 - Other Recurrent Costs"
        ? "Recurrent_Expenditure"
        : group === "13 - AID AND GRANTS" ||
          group === "14 - Capital Development (CDF) Receipts"
        ? "Other_Receipts"
        : group === "Capital_Expenditure"
        ? "Capital_Expenditure"
        : "Total_Expenditure" || "Total_Revenue" || "Closing_Balance";

    if (!acc[group]) {
      acc[group] = {
        category,
        prev_year2_actual_amount: 0,
        prev_year1_approve_amount: 0,
        prev_year1_actual_amount: 0,
        budget_amount: 0,
        approve_amount: 0,
      };
    }

    acc[group].prev_year2_actual_amount += item.prev_year2_actual_amount || 0;
    acc[group].prev_year1_approve_amount += item.prev_year1_approve_amount || 0;
    acc[group].prev_year1_actual_amount += item.prev_year1_actual_amount || 0;
    acc[group].budget_amount += item.budget_amount || 0;
    acc[group].approve_amount += item.approve_amount || 0;

    return acc;
  }, {});

  // Initialize totals
  let totals = {
    Recurrent_Revenue: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Recurrent_Expenditure: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Transfer_To_Capital_Account: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Other_Receipts: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Capital_Expenditure: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Total_Revenue: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },
    Total_Expenditure: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Closing_Balance: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 0,
      prev_year1_actual_amount: 0,
      budget_amount: 0,
      approve_amount: 0,
    },

    Opening_Balance: {
      prev_year2_actual_amount: 0,
      prev_year1_approve_amount: 12062370089.11,
      prev_year1_actual_amount: 0,
      budget_amount: 15810985989.93,
      approve_amount: 15810985989.93,
    },
  };
  // Calculate totals
  Object.keys(groupedData || {}).forEach((group) => {
    const category = groupedData[group].category;
    if (category) {
      Object.keys(totals[category] || {}).forEach((key) => {
        totals[category][key] += groupedData[group][key];
      });
    }
    if (
      category === "Opening_Balance" ||
      category === "Recurrent_Revenue" ||
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals.Transfer_To_Capital_Account.prev_year2_actual_amount =
        totals.Recurrent_Revenue.prev_year2_actual_amount +
        totals.Opening_Balance.prev_year2_actual_amount -
        totals.Recurrent_Expenditure.prev_year2_actual_amount;

      totals.Transfer_To_Capital_Account.prev_year1_approve_amount =
        totals.Opening_Balance.prev_year1_approve_amount +
        totals.Recurrent_Revenue.prev_year1_approve_amount -
        totals.Recurrent_Expenditure.prev_year1_approve_amount;

      totals.Transfer_To_Capital_Account.prev_year1_actual_amount =
        totals.Opening_Balance.prev_year1_actual_amount +
        totals.Recurrent_Revenue.prev_year1_actual_amount -
        totals.Recurrent_Expenditure.prev_year1_actual_amount;

      totals.Transfer_To_Capital_Account.budget_amount =
        totals.Opening_Balance.budget_amount +
        totals.Recurrent_Revenue.budget_amount -
        totals.Recurrent_Expenditure.budget_amount;

      totals.Transfer_To_Capital_Account.approve_amount =
        totals.Opening_Balance.approve_amount +
        totals.Recurrent_Revenue.approve_amount -
        totals.Recurrent_Expenditure.approve_amount;
    }
    // total revenue
    if (category === "Recurrent_Revenue" || category === "Other_Receipts") {
      totals.Total_Revenue.prev_year2_actual_amount =
        parseFloat(totals.Recurrent_Revenue.prev_year2_actual_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.prev_year2_actual_amount);

      totals.Total_Revenue.prev_year1_approve_amount =
        parseFloat(totals.Recurrent_Revenue.prev_year1_approve_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.prev_year1_approve_amount);

      totals.Total_Revenue.prev_year1_actual_amount =
        parseFloat(totals.Opening_Balance.prev_year1_actual_amount) +
        parseFloat(totals.Recurrent_Revenue.prev_year1_actual_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.prev_year1_actual_amount);

      totals.Total_Revenue.budget_amount =
        parseFloat(totals.Opening_Balance.budget_amount) +
        parseFloat(totals.Recurrent_Revenue.budget_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.budget_amount);

      totals.Total_Revenue.approve_amount =
        parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Recurrent_Revenue.approve_amount) +
        // parseFloat(totals.Opening_Balance.approve_amount) +
        parseFloat(totals.Other_Receipts.approve_amount);
    }
    // Total Expenditure
    if (
      category === "Capital_Expenditure" ||
      category === "Recurrent_Expenditure"
    ) {
      totals.Total_Expenditure.prev_year2_actual_amount =
        parseFloat(totals.Capital_Expenditure.prev_year2_actual_amount) +
        parseFloat(totals.Recurrent_Expenditure.prev_year2_actual_amount);

      totals.Total_Expenditure.prev_year1_approve_amount =
        parseFloat(totals.Capital_Expenditure.prev_year1_approve_amount) +
        parseFloat(totals.Recurrent_Expenditure.prev_year1_approve_amount);

      totals.Total_Expenditure.prev_year1_actual_amount =
        parseFloat(totals.Capital_Expenditure.prev_year1_actual_amount) +
        parseFloat(totals.Recurrent_Expenditure.prev_year1_actual_amount);

      totals.Total_Expenditure.budget_amount =
        parseFloat(totals.Capital_Expenditure.budget_amount) +
        parseFloat(totals.Recurrent_Expenditure.budget_amount);

      totals.Total_Expenditure.approve_amount =
        parseFloat(totals.Capital_Expenditure.approve_amount) +
        parseFloat(totals.Recurrent_Expenditure.approve_amount);
    }

    // Closing balance

    if (
      category === "Total_Revenue" ||
      category === "Total_Expenditure" ||
      category === "Capital_Expenditure" ||
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals.Closing_Balance.prev_year2_actual_amount =
        parseFloat(totals.Total_Revenue.prev_year2_actual_amount || 0) -
        parseFloat(totals.Total_Expenditure.prev_year2_actual_amount || 0);

      totals.Closing_Balance.prev_year1_approve_amount =
        parseFloat(totals.Total_Revenue.prev_year1_approve_amount) -
        parseFloat(totals.Total_Expenditure.prev_year1_approve_amount);

      totals.Closing_Balance.prev_year1_actual_amount =
        parseFloat(totals.Total_Revenue.prev_year1_actual_amount) -
        parseFloat(totals.Total_Expenditure.prev_year1_actual_amount);

      totals.Closing_Balance.budget_amount =
        parseFloat(totals.Total_Revenue.budget_amount) -
        parseFloat(totals.Total_Expenditure.budget_amount);

      totals.Closing_Balance.approve_amount =
        parseFloat(totals.Total_Revenue.approve_amount) -
        parseFloat(totals.Total_Expenditure.approve_amount);
    }

    if (
      category === "Capital_Expenditure" ||
      category === "Recurrent_Expenditure" ||
      category === "Opening_Balance"
    ) {
      totals.Opening_Balance.prev_year1_approve_amount = 15663016826.32;

      totals.Opening_Balance.budget_amount = 15810985989.93;

      totals.Opening_Balance.approve_amount = 15810985989.93;
    }
  });

  // console.log(groupedData, "LALALAL");

  const report = {
    Opening_Balance: [],
    Recurrent_Revenue: [],
    Recurrent_Expenditure: [],
    Transfer_To_Capital_Account: [],
    Other_Receipts: [],
    Capital_Expenditure: [],
    Total_Revenue: [],
    Total_Expenditure: [],
    "": [],
    Closing_Balance: [],
  };

  Object.keys(groupedData || {}).forEach((item) => {
    const category = groupedData[item].category;
    if (category) {
      report[category]?.push({ item, ...groupedData[item] });
    }
  });

  // Display totals in the console
  // console.log("Totals:", totals);

  const generateTable = () => {
    return (
      <table className="table table-striped table-bordered">
        <thead
          style={{
            fontSize: "12px",
            borderWidth: 1,
            borderColor: "black",
            textAlign: "center",
          }}
        >
          <tr style={{ backgroundColor: "orange" }}>
            <th style={{ backgroundColor: "orange" }}>Items</th>
            <th style={{ backgroundColor: "orange", fontWeight: "bolder" }}>{`${
              parseInt(form.budget_year) - 2
            } Full year  Actual(₦)`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${
              parseInt(form.budget_year) - 1
            } Approved Budget(₦)`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${
              parseInt(form.budget_year) - 1
            } Performance January To August(₦)`}</th>
            {/* <th>{`${parseInt(form.budget_year)} MDA Proposal`}</th> */}
            <th style={{ backgroundColor: "orange" }}>{`${parseInt(
              form.budget_year
            )} Proposed Budget`}</th>
            <th style={{ backgroundColor: "orange" }}>{`${parseInt(
              form.budget_year
            )} Approved Budget`}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(report).map((category) => (
            <React.Fragment key={category}>
              <tr
                key={`${category}-totals`}
                style={{ fontSize: "13px", fontWeight: "bold" }}
              >
                <td>{capitalizeWords(category)}</td>
                <td style={{ textAlign: "right" }}>
                  {formatNumbers(totals[category]?.prev_year2_actual_amount)}{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {formatNumbers(totals[category]?.prev_year1_approve_amount)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {formatNumbers(totals[category]?.prev_year1_actual_amount)}
                </td>
                {/* <td style={{ textAlign: "right" }}>
                  {formatNumbers(totals[category]?.budget_amount}
                </td> */}
                <td style={{ textAlign: "right" }}>
                  {formatNumbers(totals[category]?.approve_amount)}
                </td>
                <td style={{ textAlign: "right" }}>
                  {formatNumbers(totals[category]?.approve_amount)}
                </td>
              </tr>
              {report[category].map((item) => (
                <tr key={item.item} style={{ fontSize: "13px" }}>
                  <td>{capitalizeWords(item.item).toUpperCase()}</td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.prev_year2_actual_amount))
                      ? "---"
                      : formatNumbers(
                          parseFloat(item.prev_year2_actual_amount)
                        )}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.prev_year1_approve_amount))
                      ? "---"
                      : formatNumbers(
                          parseFloat(item.prev_year1_approve_amount)
                        )}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.prev_year1_actual_amount))
                      ? "---"
                      : formatNumbers(
                          parseFloat(item.prev_year1_actual_amount)
                        )}
                  </td>
                  {/* <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.budget_amount))
                      ? "---"
                      : parseFloat(item.budget_amount)}
                  </td> */}
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.approve_amount))
                      ? "---"
                      : formatNumbers(parseFloat(item.approve_amount))}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(parseFloat(item.approve_amount))
                      ? "---"
                      : formatNumbers(parseFloat(item.approve_amount))}
                  </td>
                </tr>
              ))}
              {/* Display category totals */}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {capitalizeWords(selectedOption.value)}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* {JSON.stringify(totals.Total_Revenue.budget_amount)} */}
                <div className="d-flex justify-content-between m-3">
                  <div className="col-md-2">
                    <Input
                      type="select"
                      name="budget_year"
                      value={form.budget_year}
                      onChange={handleChange}
                    >
                      <option>--select--</option>
                      {years?.map((item) => (
                        <option key={item}>{item}</option>
                      ))}
                    </Input>
                  </div>
                  <div className="col-md-4">
                    <select
                      className="form-control"
                      name="query_type"
                      value={
                        selectedOption
                          ? options.findIndex(
                              (opt) => opt.value === selectedOption.value
                            )
                          : ""
                      }
                      onChange={handleChangeSelect}
                    >
                      <option>Select report type</option>
                      {options.map((item, i) => (
                        <option value={i} key={i}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <SearchBar />
                  {/* <CSVLink
                      data={results && results ? results : []}
                      className="text-white"
                      filename={selectedOption.value}
                      headers={headers}
                    >
                      <FaFileDownload color="white" size="1.2rem" /> Download
                      Excelddd
                    </CSVLink> */}
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle
                      className="btn btn-primary text-white mb-3 ml-3"
                      caret
                    >
                      <FaFileDownload
                        color="white"
                        size="1.2rem"
                        className="mr-5"
                      />
                      Download
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => console.log("Option 1 clicked")}
                      >
                        Excel
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => console.log("Option 2 clicked")}
                      >
                        PDF
                      </DropdownItem>
                      <DropdownItem />
                      <DropdownItem
                        onClick={() => console.log("Another option clicked")}
                      >
                        Word
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="p-3">
                  {loading ? (
                    <div className="loading-container text-center">
                      <Spinner color="primary" style={{ marginTop: "20px" }} />{" "}
                      Loading...
                    </div>
                  ) : (
                    <Scrollbar>{generateTable()}</Scrollbar>
                  )}
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
