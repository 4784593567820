/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import {
  Modal,
  Input,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Alert,
} from "reactstrap";
import { expiredSetting } from "./helper";
// import { FaBell } from "react-icons/fa";
import { year } from "helper";
import { useToasts } from "react-toast-notifications";
import { _postApi } from "redux/action/api";
import moment from "moment";
import { bg } from "redux/action/api";
import { FaAddressBook } from "react-icons/fa";
import { postApi } from "redux/action/api_tbeams";
import ExcelUpload from "./UploadExcel";
import Scrollbar from "components/Tree/Scrollbar";

const today = new Date().toISOString().slice(0, 10);

function DateSetting({
  toggle,
  type,
  title = "Click to set Budget Proposal End Date",
  otherFuc = (f) => f,
}) {
  const [expired, setExpired] = useState({});

  function getExpiredSetting(type) {
    expiredSetting(
      { description: type },
      "select",
      (d) => {
        setExpired(d.results[0]);
      },
      (e) => {
        console.log(e);
      }
    );
  }

  useEffect(() => {
    getExpiredSetting(type);
  }, [type]);

  return (
    <div
    className="col-md-4 col-sm-6"
      onClick={() => {
        toggle(type);
        otherFuc();
      }}
      style={{ cursor: "pointer", borderRadius: "5px" }}
    >
      <div
        className={` shadow p-3 mb-3 text-dark bg-light border border-rounded`}
      >
        <div>
          <small className="text-muted float-left text-xs">
            {expired?.end_date || today}
          </small>
          {/* <FaAddressBook /> */}
        </div>
        <br />
        <small className="fw-bold">{title}</small>
      </div>
    </div>
  );
}

export default function OtherSetup() {
  const _form = { description: "", end_date: today };

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [form, setForm] = useState(_form);
  const [status, setStatus] = useState(false);
  const [display, setDisplay] = useState(false);
  const { addToast } = useToasts();

  const disableCond =
    moment().format("YYYY") === moment(form.end_date).format("YYYY");
  const toggle = (type) => {
    setModal(!modal);
    setForm((p) => ({ ...p, description: type }));
  };

  const toggle2 = () => {
    setModal2(!modal2);
    // setForm((p) => ({ ...p, description: type }));
  };

  const toggle4 = () => {
    setModal4(!modal4);
    // setForm((p) => ({ ...p, description: type }));
  };

  const createNewBudget = () => {
    _postApi(
      "/callExpiredSettingAndCreateNext-budget",
      {},
      (res) => {
        console.log(res);
        toggle(form.description);
        addToast("Successfully", {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      },
      (err) => {
        console.log(err);
        addToast("Failed", {
          appearance: "warning",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    );
  };

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  let query = status ? "update" : "insert";

  const handleSubmit = () => {
    expiredSetting(
      form,
      query,
      () => {
        toggle(form.description);
        addToast("Saved Successfully", {
          appearance: "info",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      },
      (e) => {
        console.log(e);
        addToast("Saved Failed", {
          appearance: "warning",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      }
    );
  };

  const handleSubmit2 = () => {
    postApi(`opening_balance?query_type=create`, form)
      .then((data) => {
        console.log("data", data);
        if (data.results) {
          addToast("Form submitted successfully!", {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        addToast("Fail to Fetch", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 5000,
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    expiredSetting(
      { end_date: year, description: form.description },
      "checked",
      (res) => {
        // alert(JSON.stringify(res.results))
        if (res.results.length) {
          setStatus(true);
          setForm(res.results[0]);
        } else {
          setStatus(false);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }, [year, form.description, status]);

  return (
    <div>
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        <MDBox pt={3} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h5" color="white">
                    <center> Other Setup </center>
                  </MDTypography>
                </MDBox>
                <MDBox pt={5} p={3} className="row">
                  <DateSetting
                    form={form}
                    toggle={() => toggle("Budget Proposal Start Date")}
                    type="Budget Proposal Start Date"
                    title="Click to create new Budget Proposal"
                    otherFuc={() => setDisplay(true)}
                  />

                  <DateSetting
                    form={form}
                    toggle={() => toggle("Proposed Budget End Date")}
                    type="Budget Proposal End"
                    otherFuc={() => setDisplay(false)}
                  />

                  <DateSetting
                    form={form}
                    toggle={() => toggle("Ceiling Start Date")}
                    type="Ceiling Start Date"
                    title="Click to set Ceiling Start Date"
                    otherFuc={() => setDisplay(false)}
                  />

                  <DateSetting
                    form={form}
                    toggle={() => toggle("Ceiling End Date")}
                    type="Ceiling End Date"
                    title="Click to set Ceiling End Date"
                    otherFuc={() => setDisplay(false)}
                  />

                  <DateSetting
                    form={form}
                    toggle={() => toggle2("Opening Balance")}
                    type="Opening Balance"
                    title="Click to set Opening Balance"
                    otherFuc={() => setDisplay(false)}
                  />

                  <DateSetting
                    form={form}
                    toggle={() => toggle4("Opening Balance")}
                    type="Upload Budget"
                    title="Click to Upload budget"
                    otherFuc={() => setDisplay(false)}
                  />

                  {/* <div className="text-white">
                    dkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk
                  </div> */}
                </MDBox>

                <Modal
                  isOpen={modal}
                  toggle={() => toggle(form.description)}
                  size="md"
                  className="p-3"
                >
                  <ModalHeader>{form.description}</ModalHeader>
                  {display ? (
                    <div className="p-3">
                      <center>
                        <Alert>
                          <small>
                            Kindly be aware that the functionality of this
                            button below is limited to once per year. If
                            disabled, please reach out to the administrator for
                            assistance.
                          </small>
                        </Alert>
                      </center>
                      <center>
                        <button
                          className="btn btn-primary"
                          onClick={() => createNewBudget()}
                          disabled={disableCond}
                        >
                          Create new budget proposal
                        </button>
                      </center>
                    </div>
                  ) : (
                    <ModalBody>
                      {/* {JSON.stringify(form)} */}
                      <Label>Set Date</Label>
                      <Input
                        type="date"
                        name="end_date"
                        value={form.end_date}
                        onChange={handleChange}
                      />

                      <Label>Description</Label>
                      <Input
                        name="description"
                        value={form.description}
                        onChange={handleChange}
                        placeholder="Description"
                      />
                      <Button
                        color="primary"
                        onClick={handleSubmit}
                        className="m-4"
                      >
                        {status ? "UPDATE" : "Submit"}
                      </Button>
                    </ModalBody>
                  )}
                </Modal>

                <Modal
                  isOpen={modal2}
                  toggle={() => toggle2()}
                  size="md"
                  className="p-3"
                >
                  <ModalBody>
                    {/* {JSON.stringify(form)} */}
                    <Label>Set Date</Label>
                    <Input
                      type="date"
                      name="end_date"
                      value={form.end_date}
                      onChange={handleChange}
                    />

                    <Label>Opening Balance</Label>
                    <Input
                      name="opening_balance"
                      value={form.opening_balance}
                      onChange={handleChange}
                      placeholder="Opening Balance"
                    />
                    <Label>Closing Balance</Label>
                    <Input
                      name="closing_bal"
                      value={form.closing_bal}
                      onChange={handleChange}
                      placeholder="Closing Balance"
                    />
                    <Button
                      color="primary"
                      onClick={handleSubmit2}
                      className="m-4"
                    >
                      {status ? "UPDATE" : "Submit"}
                    </Button>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={modal4}
                  toggle={() => toggle4()}
                  size="xl"
                  className="p-3"
                >
                  <ModalBody>
                    {/* {JSON.stringify(form)} */}
                    <Scrollbar>
                      <ExcelUpload />
                    </Scrollbar>
                  </ModalBody>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </div>
  );
}
