import ReportsAdminShareCom from "./ReportsAdminShareCom";
const listdata = [
  { value: "total_revenue_by_admin", title: "Total Revenue by Admin" },
  { value: "recurrent_revenue_admin", title: "Recurrent Revenue by Admin" },
  { value: "capital_receipts_admin", title: "Capital Receipts by Admin" },
  { value: "total_revenue_by_dep", title: "Total Revenue by Dep." },
  { value: "recurrent_revenue_by_dep", title: "Recurrent Revenue by Dep." },
  { value: "capital_revenue_by_dep", title: "Capital Revenue by Dep." },
];
function AdminQuaterly() {
  return (
    <ReportsAdminShareCom
      type="total_revenue_by_admin"
      route="/reports"
      options={listdata}
      title=" Total Revenue by Admin"
    />
  );
}

export default AdminQuaterly;
