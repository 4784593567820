import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useEffect, useState } from "react";
import { getSector } from "helper";
import { unflatten } from "redux/action/api";
import StructureTree from "components/Tree/StrutureTree";
import Scrollbar from "components/Tree/Scrollbar";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";

import moment from "moment";
import SearchBar from "components/SearchBar";

import { _fetchApi } from "redux/action/api";
import { FaPlus } from "react-icons/fa";
import { _postApi } from "redux/action/api";
import { Badge, Button } from "reactstrap";
import { useToasts } from "react-toast-notifications";
function ManageUser() {
  const [results, setResults] = useState();

  const getDatas = () => {
    _fetchApi(
      `/get-all`,
      (data) => {
        if (data.success) {
          setResults(data.results[0]);
        }
      },
      (err) => console.log(err)
    );
  };
  useEffect(() => {
    getDatas();
  }, []);

  const navigate = useNavigate();
  const { addToast } = useToasts();
  const handleUpdate = (status = "", id = 0) => {
    // alert(JSON.stringify(status));
    _postApi(
      `/update-budget-user?id=${id}&status=${status}`,
      {},
      (data) => {
        if (data.success) {
          addToast(
            status === "approved"
              ? "Approved succesfully"
              : "Suspend Successfully",
            {
              appearance: status === "approved" ? "info" : "error",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            }
          );
          getDatas();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const [showMore, setShowMore] = useState(false);
  const maxLength = 50; // Set your desired maximum length

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const DeleteButtonWithModal = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
      setModalOpen(!modalOpen);
    };
  };

  // const item = "item";
  // const [confirmLabel, ] = useState(
  //   item.status === "approved" ? "Suspend" : "Approve"
  // );
  // const [cancelLabel, ] = useState(
  //   item.status === "approved" ? "Cancel Approval" : "Revert to Approval"
  // );

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {`Users' Management`}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* {JSON.stringify(results)} */}
                <button
                  style={{ marginLeft: 42, float: "left", color: "white" }}
                  className="btn btn-primary text-white mb-3 ml-5"
                  onClick={() => navigate("/create-user")}
                >
                  <FaPlus /> Add New
                </button>
                {/* {JSON.stringify(results[0])} */}
                <div className="p-5">
                  <SearchBar />
                  <Scrollbar>
                    <table className="table table-bordered mt-3">
                      <thead>
                        <tr style={{ width: "100%" }}>
                          <th style={{ fontSize: "14px" }}>S/N</th>
                          <th style={{ fontSize: "14px" }}>Full Name</th>
                          <th style={{ fontSize: "14px" }}>username</th>
                          <th style={{ fontSize: "14px" }}>MDA</th>
                          <th style={{ fontSize: "14px" }}>Access To</th>
                          <th style={{ fontSize: "14px" }}>Created At</th>
                          {/* <th style={{ fontSize: "14px" }}>Status</th> */}
                          <th style={{ fontSize: "14px" }}>Status</th>
                          <th style={{ fontSize: "14px" }}>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {results?.map((item, index) => (
                          <tr key={index}>
                            <td style={{ fontSize: "14px" }}>{index + 1}</td>
                            <td style={{ fontSize: "14px" }}>{item.name}</td>
                            <td style={{ fontSize: "14px" }}>
                              {item.username}
                            </td>
                            <td style={{ fontSize: "14px" }}>{item.mda}</td>
                            <td style={{ fontSize: "14px" }}>
                              {showMore ? (
                                item.accessTo
                              ) : (
                                <>
                                  {item.accessTo.length > maxLength
                                    ? `${item.accessTo.slice(0, maxLength)}...`
                                    : item.accessTo}
                                </>
                              )}
                              <br />
                              {item.accessTo.length > maxLength && (
                                <Link onClick={toggleShowMore}>
                                  {showMore ? "Show Less" : "Show More"}
                                </Link>
                              )}
                            </td>

                            <td style={{ fontSize: "14px" }}>
                              {moment(item.createdAt).format("LLL")}
                            </td>

                            <td style={{ fontSize: "12px" }}>
                              <div>
                                <Button
                                  id="ScheduleApproveButton"
                                  size="sm"
                                  trigger="click"
                                  //   onClick={() =>
                                  //     handleUpdate(
                                  //       // item.status === "approved" ? "Suspend" : "approved",
                                  //       item.id
                                  //     )
                                  // }
                                  color={
                                    item.status === "approved"
                                      ? "primary"
                                      : "danger"
                                  }
                                >
                                  {item.status === "approved"
                                    ? "Approved"
                                    : "Suspend"}
                                </Button>
                                <UncontrolledPopover
                                  placement="top"
                                  target="ScheduleApproveButton"
                                  trigger="click"
                                >
                                  <PopoverHeader
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      border: "none",
                                    }}
                                  >
                                    Are you sure you want to{" "}
                                    {item.status === "approved"
                                      ? "Approved"
                                      : "Suspend"}
                                  </PopoverHeader>
                                  <PopoverBody>
                                    <Button
                                      id="confirmButton"
                                      type="button"
                                      // color="success"

                                      className="m-2"
                                      onClick={() =>
                                        handleUpdate(
                                          item.status === "approved"
                                            ? "Suspend"
                                            : "approved",
                                          item.id
                                        )
                                      }
                                      color={
                                        item.status === "approved"
                                          ? "primary"
                                          : "danger"
                                      }
                                    >
                                      {item.status === "approved"
                                        ? "Approved"
                                        : "Suspend"}
                                    </Button>
                                    <Button
                                      id="cancelButton"
                                      type="button"
                                      color="success"
                                      className="m-2"
                                      onClick={() => {
                                        document
                                          .getElementById(
                                            "ScheduleApproveButton"
                                          )
                                          .click();
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </div>
                            </td>
                            <td style={{ fontSize: "12px" }} className="d-flex">
                              <Button
                                onClick={() =>
                                  navigate(`/create-user?id=${item.id}`)
                                }
                                className="m-1"
                                color="success"
                                size="sm"
                              >
                                Edit
                              </Button>
                              <div className="text-center">
                                <div className="text-center">
                                  <Button
                                    id="ScheduleUpdateButton"
                                    type="button"
                                    color="danger"
                                  >
                                    Delete
                                  </Button>
                                  <UncontrolledPopover
                                    placement="top"
                                    target="ScheduleUpdateButton"
                                    trigger="click"
                                  >
                                    <PopoverHeader
                                      style={{
                                        backgroundColor: "#fff",
                                        color: "#000",
                                        border: "none",
                                      }}
                                    >
                                      Are you sure you want to delete?
                                    </PopoverHeader>
                                    <PopoverBody>
                                      <Button
                                        id="confirmButton"
                                        type="button"
                                        color="danger"
                                        className="m-3"
                                        onClick={() => {
                                          // Add your confirm logic here
                                          console.log("Confirm button clicked");
                                          // Close the popover
                                          document
                                            .getElementById(
                                              "ScheduleUpdateButton"
                                            )
                                            .click();
                                        }}
                                      >
                                        Confirm
                                      </Button>
                                      <Button
                                        id="cancelButton"
                                        type="button"
                                        color="success"
                                        className="m-3"
                                        onClick={() => {
                                          // Add your cancel logic here
                                          console.log("Cancel button clicked");
                                          // Close the popover
                                          document
                                            .getElementById(
                                              "ScheduleUpdateButton"
                                            )
                                            .click();
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Scrollbar>
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ManageUser;
