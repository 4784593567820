/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Alert, Button, Col, Input, Label, Row } from "reactstrap";
import Attachment from "./Attachment";
import { useDropzone } from "react-dropzone";
import ContractorBankDetails from "ContractorBankDetails";
import { apiURL } from "redux/action/api";
import { fetchApi } from "redux/action/api_tbeams";
import { postApi } from "redux/action/api_tbeams";
import { useQuery } from "redux/action/api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// import { PDFViewer } from "@react-pdf/renderer";
// import { PaymentVoucherPdf } from "./PaymentVoucherPdf";
import WarningModal from "./WarningModal";
import { formatNumber } from "redux/action/api";
import { RealiseCollectionPdf } from "./RealiseCollectionPdf";
import { useToasts } from "react-toast-notifications";
import { getSector } from "helper";

export function getDistinctObjects(array, key1, key2) {
  return array?.filter(
    (obj, index, self) =>
      index ===
      self?.findIndex((o) => o[key1] === obj[key1] && o[key2] === obj[key2])
  );
}

export default function CapitalProject({ generalForm = {} }) {
  const query = useQuery();
  const mdacode = query.get("mda_code");
  const mdaRef = useRef();
  const navigate = useNavigate();
  const user = useSelector((e) => e.auth.user);

  const [treasuryAccount, setTreasuryAccount] = useState([]);
  const [mdaAccount, setMdaAccount] = useState([]);
  const [budgetSummary, setBudgetSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showAmountWarningModal, setShowAmountWarningModal] = useState(false);
  const [submittingReserveRequest, setSubmittingReserveRequest] =
    useState(false);
  const [childCode, setChildCode] = useState([]);
  const economicTypeahead = useRef();
  const economicTypeahead1 = useRef();
  const [form, setForm] = useState({
    date: moment().format("YYYY-MM-DD"),
    pv_code: "",
    projectType: "",
    paymentType: "",
    paymentClass: "",
    project_type: "",
    other_project_type: "",
    payment_type: "",
    other_payment_type: "",
    payment_class: "",
    mda_name: "",
    destMdaCode: "",
    sourceMdaCode: "",
    mda_code: "",
    mda_budget_balance1: "",
    sourceEconomicCode: "",
    sourceEconomicDescription: "",
    narration: "",
    fund_code: "",
    fund_description: "",
    functional_code: "",
    functional_description: "",
    program_code: "",
    program_description: "",
    geo_code: "",
    geo_description: "",
    contractor_phone: "",
    contractor_tin: "",
    contractor_email: "",
    contractor_address: "",
    account_name: "",
    bank_name: "",
    account_number: "",
    sort_code: "",
    query_type: "insert",
    approved_amount: "",
    reserveType: "",
    reserveStatus: "Pending",
    userId: user.username,
    approval_date: moment().format("YYYY-MM-DD"),
  });
  const [economicCode, setEconomicCode] = useState([]);
  const [economicCode1, setEconomicCode1] = useState([]);
  const [programCode, setProgramCode] = useState([]);
  const [geoCode, setGeoCode] = useState([]);
  const [funcCode, setFuncCode] = useState([]);
  const [fundCode, setFundCode] = useState([]);
  const [admin, setAdmin] = useState([]);

  const getData = (cb, type) => {
    getSector({ type }, "select", (d) => {
      cb(d);
    });
  };

  useEffect(() => {
    getData(setFuncCode, "FUNCTIONAL SEGMENT");
    getData(setProgramCode, "PROGRAMME SEGMENT");
    getData(setGeoCode, "GEO SEGMENT");
    getData(setFundCode, "FUND SEGMENT");
    getData(setAdmin, "ADMINISTRATIVE SEGMENT");
  }, []);

  const [showForm, setShowForm] = useState(true);
  const { addToast } = useToasts();

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ accept: "image/*, .pdf, text/plain" });

  const getCCCopies = useCallback(() => {
    postApi(`post-cc?query_type=select_mda`, { mda_name: form.mda_name })
      .then((data) => {
        console.log("data", data);
        if (data.results) {
          setForm((p) => ({ ...p, copies: data.results[0] }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [form.mda_name]);

  const getCCCopies1 = useCallback(() => {
    postApi(`post-cc?query_type=select_mda`, { mda_name: form.mda_head })
      .then((data) => {
        console.log("data", data);
        if (data.results) {
          setForm((p) => ({ ...p, copies1: data.results[0] }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [form.mda_head]);

  useEffect(() => {
    getCCCopies();
    getCCCopies1();
  }, [getCCCopies, getCCCopies1]);

  const getList = () => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setBudgetSummary(d);
    });
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    postApi(`select_mda_bank_details`, {
      query_type: "select",
    }).then((data) => {
      if (data.result && data.result.length) {
        console.log(data.result);

        const treasuryAccount = data.result.filter(
          (item) => item.account_type === "treasury_source_account"
        );
        setTreasuryAccount(treasuryAccount);

        const mdaAccount = data.result.filter(
          (item) => item.account_type === "mda_source_account"
        );
        setMdaAccount(mdaAccount);
      }
    });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (typeof value === "string" && value.includes("/")) {
      const [code, description] = value.split("/");

      // Update the form state with code and description separatel
      setForm((prevForm) => ({
        ...prevForm,
        [name]: code,
        [`${name}_description`]: description,
      }));
    } else {
      // If the value is not a string or doesn't contain a '/', update the form state normally
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  };

  const handleChanges = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === "checkbox") {
      setForm((prev) => ({ ...prev, [name]: checked ? value : "" }));
    } else {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleMdaCode = (selected) => {
    if (selected?.length) {
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        mda_head_code: selectedItem.head,
        mda_head: selectedItem.description,
        send_to_office: selectedItem.head,
      }));
    } else {
      console.log("no item selected");
    }
  };

  const getList31 = (economic_code) => {
    postApi(`update_budget_balance`, {
      query_type: "select",
      mda_name:
        form.projectType === "payable-to" ? form.mda_head : form.mda_name,
      mda_code:
        form.projectType === "payable-to" ? form.mda_head_code : form.mda_code,
      amount: null,
      economic_code: economic_code,
      geo_code: form.geo_code,
      program_code: form.program_code,
      functional_code: form.functional_code,
      fund_code: form.fund_code,
    })
      .then((d) => {
        console.log("data", d);
        if (d.success) {
          const selectedItem = d.data[0];
          setForm((p) => ({
            ...p,
            description: selectedItem?.budget_description,
            mda_budget_balance:
              selectedItem?.mopb_balance == 0
                ? "Budget Exausted"
                : selectedItem?.mopb_balance,
            budget:
              selectedItem?.approve_amount == 0
                ? "Budget Exausted"
                : selectedItem?.approve_amount,
          }));
        }
      })
      .catch((err) => {
        // Handle errors
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [newData, setNewdata] = useState();

  const handleEconomicCode = (selected) => {
    if (selected?.length) {
      // console.log("selected budget", selected);
      const selectedItem = selected[0];
      setNewdata(
        economicCode1?.filter(
          (item) => item.economic_code === selectedItem.economic_code
        ) || []
      );
      setForm((p) => ({
        ...p,
        mda_economic_code: selectedItem.economic_code,
        sourceEconomicCode: selectedItem.economic_code,
        sourceEconomicDescription: selectedItem.economic_description,
        description: selectedItem.economic_description,
      }));
      getList31(selectedItem.economic_code);
      setForm((p) => ({ ...p, economic_code: selectedItem.economic_code }));
    } else {
      console.log("no item selected");
    }
  };

  const getList2 = () => {
    fetchApi(`get-budget-summary?query_type=capital`)
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log(d, "ddkdjd");
          setEconomicCode(d.result);
        }
      })
      .catch((err) => console.log(err));
  };

  const getList21 = useCallback((mda_code) => {
    fetchApi(
      `get-budget-summary?query_type=select_economic_code&&mda_code=${mda_code}`
    )
      .then((d) => {
        console.log(d);
        if (d.result && d.result.length) {
          console.log(d);
          setEconomicCode1(d.result);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getList2();
  }, []);

  const handleMdaCode1 = (selected) => {
    if (selected?.length) {
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        mda_code: selectedItem.head,
        mda_description: selectedItem.description,
        mda_name: selectedItem.description,
        // description:selectedItem.mda_name,
      }));

      getList21(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const handleApi = () => {
    console.log(
      "form.approved_amount",
      form.approved_amount,
      "form.mda_budget_balance",
      form.mda_budget_balance
    );
    if (
      parseFloat(form.approved_amount) > parseFloat(form.mda_budget_balance)
    ) {
      setShowAmountWarningModal(true);
    } else {
      // const formDataWithMultiSelect = {
      //   ...form,
      //   cc: selectedOptions.map((option) => option.value),
      // };
      setIsLoading(true);
      // generalForm.sent_to_mda = generalForm?.sent_to.office || "";
      // generalForm.sent_to_office = generalForm?.sent_to.title || "";
      form.approval_type = "Capital";
      form.approved_by = "HE";
      form.query_type = "insert";
      form.approval_status = "Approval Initiated";
      postApi(`post_approval_collection`, { form })
        .then((data) => {
          if (data.success) {
            console.log(data);
            handleApi1(data.approval_no);
            // handleSubmitReset();
            addToast("Approval Submitted Successfully!", {
              appearance: "info",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            });
            setShowForm(false);
            // navigate("/release-collection");
          }
        })
        .catch((err) => {
          alert("");
          addToast("An error occurred, please try again later.", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 5000,
          });
          setShowForm(false);
        })
        .finally(() => {
          setIsLoading(false);
          setShowForm(false);
        });
    }
  };

  const handleApi1 = (approval_no) => {
    if (form.approved_amount && form.approved_amount !== "") {
      const formData = new FormData();
      form.approval_no = approval_no;
      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.append("files", acceptedFiles[i]);
      }

      if (!acceptedFiles.length) {
        setForm((p) => ({ ...p, approval: "not approved" }));
        formData.append("form", JSON.stringify(form));

        fetch(`${apiURL}/post_images`, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
          })

          .catch((err) => {
            console.log(err);
          });
      } else {
        formData.append("form", JSON.stringify(form));

        fetch(`${apiURL}/post_images`, {
          method: "POST",

          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
          })

          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      alert("Please enter a valid amount");
    }
  };

  const submitPlanningReserveRequest = () => {
    setIsLoading(true);
    // generalForm?.sent_to_mda = generalForm?.sent_to?.office || "";
    generalForm.sent_to_office = generalForm.sent_to.title || "";
    form.query_type = "insert";
    form.approval_type = "Capital";
    form.approved_by = "HE";
    form.approval_status = "Approval Released";
    postApi(`post_approval_collection`, { form })
      .then((data) => {
        if (data.success) {
          console.log(data);
          handleApi1(data.approval_no);
          // handleSubmitReset();
          alert("submit success");
          // setShowForm(false);
          postApi(`planning-reservation`, {
            // ...form,
            destMdaCode: selectedBudget.mda_code,
            destEconomicCode: selectedBudget.economic_code,
            destMdaDescription: selectedBudget.mda_name,
            destEconomicDescription: selectedBudget.description,
            destFundCode: selectedBudget.fund_code,
            destFundDescription: selectedBudget.fund_description,
            destFunctionalCode: selectedBudget.functional_code,
            destFunctionalDescription: selectedBudget.function_description,
            destProgramCode: selectedBudget.program_code,
            destProgramDescription: selectedBudget.program_description,
            destGeoCode: selectedBudget.geo_code,
            destGeoDescription: selectedBudget.geo_description,
            approvedAmount: form.approved_amount,
            query_type: "insert",
            reserveStatus: "Pending",
            approvalNo: data.approval_no,
          })
            .then((data) => {
              if (data.success) {
                console.log(data);
                alert("submit success");
                // setShowForm(false);
                navigate("/planning-reservation");
              }
            })
            .catch((err) => {
              alert(err);
            })
            .finally(() => {
              setSubmittingReserveRequest(false);
            });
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getList31(form.mda_economic_code);
  }, [
    form.mda_name,
    form.economic_code,
    form.function_code,
    form.fund_code,
    form.geo_code,
    form.program_code,
  ]);

  return (
    <>
      {/* {JSON.stringify(form.mda_head)} */}
      {showForm ? (
        <Row className="m-3">
          <Col md={4}>
            <Label>Date</Label>
            <Input
              type="date"
              value={form.approval_date}
              name="approval_date"
              onChange={handleChange}
            />
          </Col>
          {/* <Col md={4}>
            <Label>PV Number</Label>
            <Input
              type="number"
              value={form.pv_code}
              name="pv_code"
              onChange={handleChange}
            />
          </Col> */}
          {/* <Col md={4}> */}
          {/* <Label>Project Type</Label>
            <Input
              id="exampleSelect"
              value={form.projectType}
              name="projectType"
              type="select"
              onChange={handleChange}
            >
              <option>--Select--</option>
              <option value="Special Project">Special Project</option>
              <option value="Constituency Project">Constituency Project</option>
              <option value="Other">Other</option>
            </Input> */}
          {/* </Col> */}
          {/* {form.projectType === "Other" && (
            <Col md={4}>
              <Label>Enter Project Type</Label>
              <Input
                type="text"
                value={form.other_project_type}
                name="other_project_type"
                onChange={handleChange}
              />
            </Col>
          )} */}
          {/* <Col md={4}>
            <Label>Payment Type</Label>
            <Input
              id="exampleSelect"
              value={form.paymentType}
              name="paymentType"
              type="select"
              onChange={handleChange}
            >
              <option>--Select--</option>
              <option value="Direct Payment">Direct Payment</option>
              <option value="Power of Attorney">Power of Attorney</option>
              <option value="Other">Other</option>
            </Input>
          </Col> */}
          {/* {form.paymentType === "Other" && (
            <Col md={4}>
              <Label>Enter Payment Type</Label>
              <Input
                type="text"
                value={form.other_payment_type}
                name="other_payment_type"
                onChange={handleChange}
              />
            </Col>
          )} */}
          {/* <Col md={4}>
            <Label>Payment Class</Label>
            <Typeahead
              size="sm"
              id="20"
              onChange={(selected) => {
                if (selected && selected.length) {
                  setForm((p) => ({
                    ...p,
                    paymentClass: selected[0].economic_description,
                  }));
                }
              }}
              options={getDistinctObjects(
                economicCode,
                "economic_code",
                "economic_description"
              )}
              labelKey={(option) =>
                // ${option.economic_code} -
                `${option.economic_description}`
              }
              placeholder="Select---"
            /> */}
          {/* <Input
              id="exampleSelect"
              value={form.paymentClass}
              name="paymentClass"
              type="select"
              onChange={handleChange}
            >
              <option>--Select--</option>
              <option value="Certificate Number">Certificate Number</option>
              <option value="APG Number">APG Number</option>
              <option value="Mobilization">Mobilization</option>
              <option value="Retention">Retention</option>
              <option value="Land and Building">Land and Building</option>
              <option value="Plant and Machinery">Plant and Machinery</option>
              <option value="Furniture and Fittings">
                Furniture and Fittings
              </option>
              <option value="other">Other</option>
            </Input> */}
          {/* </Col> */}
          {/* {JSON.stringify(form)} */}
          <Col md={4}>
            <Label>Select MDA</Label>
            <Typeahead
              size="sm"
              id="20"
              onChange={(selected) => handleMdaCode1(selected)}
              onInputChange={(text) => console.log(text)}
              options={budgetSummary}
              // options={budgetSummary.length ? budgetSummary : []}
              labelKey={(option) => `${option.description}`}
              placeholder="Select---"
              ref={mdaRef}
            />
          </Col>
          <Col md={4}>
            <Label>Admin Code</Label>
            <Input
              type="text"
              value={form.mda_code}
              // name="sourceMdaCode"
              // onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={4}>
            <Label>
              Select Head (if any){" "}
              <span
                style={{
                  // backgroundColor: "green",
                  borderRadius: 10,
                }}
                className="p-2 bg-success text-white"
              >
                OR {" PAYABLE TO "}{" "}
                <Input
                  id="checkbox3"
                  type="checkbox"
                  style={{ fontSize: 15, marginBottom: 10 }}
                  name="projectType"
                  value="payable-to"
                  checked={form.projectType === "payable-to"}
                  onChange={handleChanges}
                />
              </span>
            </Label>
            <Typeahead
              size="sm"
              id="20"
              onChange={(selected) => handleMdaCode(selected)}
              // onInputChange={(text) => console.log(text)}
              options={admin}
              labelKey={(option) => `${option.head} - ${option.description}`}
              placeholder="Select---"
              // ref={mdaRef}
            />
          </Col>
          <Col md={4}>
            <Label>Select Economic Code</Label>
            <Typeahead
              size="sm"
              id="20"
              onChange={(selected) => handleEconomicCode(selected)}
              options={economicCode}
              labelKey={(option) =>
                `${option.economic_description} - ${option.economic_code}`
              }
              placeholder="Select---"
            />
          </Col>
          <Col md={4}>
            <Label>Economic Description</Label>
            <Input
              type="text"
              value={form.sourceEconomicDescription}
              name="sourceEconomicDescription"
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={4}>
            <Label>Select Fund Code</Label>
            <Typeahead
              size="sm"
              id="20"
              onChange={(selected) => {
                console.log(selected, "COOOOOO");
                if (selected && selected.length) {
                  handleChange({
                    target: { name: "fund_code", value: selected[0].head },
                  });
                  setForm((p) => ({
                    ...p,
                    fund_description: selected[0].description,
                  }));
                }
              }}
              options={getDistinctObjects(fundCode, "head", "description")}
              labelKey={(option) => `${option.head}/${option.description}`}
              placeholder="Select---"
            />
          </Col>
          <Col md={4}>
            <Label>Fund Description</Label>
            <Input
              type="text"
              value={form.fund_description}
              name="fund_description"
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={4}>
            <Label>Select Functional Code</Label>
            <Typeahead
              size="sm"
              id="20"
              onChange={(selected) => {
                if (selected && selected.length) {
                  handleChange({
                    target: {
                      name: "functional_code",
                      value: selected[0].functional_code,
                    },
                  });
                  setForm((p) => ({
                    ...p,
                    functional_description: selected[0].description,
                  }));
                }
              }}
              options={funcCode}
              labelKey={(option) => `${option.head}/${option.description}`}
              placeholder="Select---"
            />
          </Col>
          <Col md={4}>
            <Label>Functional Description</Label>
            <Input
              type="text"
              value={form.functional_description}
              name="functional_description"
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={4}>
            <Label>Select Program Code</Label>
            <Typeahead
              size="sm"
              id="20"
              onChange={(selected) => {
                if (selected && selected.length) {
                  handleChange({
                    target: {
                      name: "program_code",
                      value: selected[0].program_code,
                    },
                  });
                  setForm((p) => ({
                    ...p,
                    program_description: selected[0].program_description,
                  }));
                }
              }}
              options={getDistinctObjects(
                economicCode,
                "program_code",
                "program_description"
              )}
              labelKey={(option) =>
                `${option.program_code}/${option.program_description}`
              }
              placeholder="Select---"
            />
          </Col>
          <Col md={4}>
            <Label>Program Description</Label>
            <Input
              type="text"
              value={form.program_description}
              name="program_description"
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={4}>
            <Label>Select Geo Code</Label>
            <Typeahead
              size="sm"
              id="20"
              onChange={(selected) => {
                if (selected && selected.length) {
                  handleChange({
                    target: { name: "geo_code", value: selected[0].head },
                  });
                  setForm((p) => ({
                    ...p,
                    geo_description: selected[0].description,
                  }));
                }
              }}
              // options={economicCode.length ? economicCode : []}
              options={getDistinctObjects(geoCode, "head", "description")}
              labelKey={(option) => `${option.head}/${option.description}`}
              placeholder="Select---"
            />
          </Col>
          <Col md={4}>
            <Label>Geo Description</Label>
            <Input
              type="text"
              value={form.geo_description}
              name="geo_description"
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={4}>
            <Label>Budget Balance</Label>
            <Input
              value={formatNumber(form.mda_budget_balance)}
              name="mda_budget_balance"
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={4}>
            <Label>Approved Budget Allocation</Label>
            <Input
              value={formatNumber(form.mda_budget_balance)}
              name="mda_budget_balance"
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={4}>
            <Label>Amount {formatNumber(form.approved_amount)}</Label>
            <Input
              type="number"
              value={form.approved_amount}
              name="approved_amount"
              onChange={handleChange}
            />
          </Col>
          {form.economic_code !== "" && form.mda_budget_balance === 0 ? (
            <Alert color="primary" className="mt-2">
              <center>Please select the corresponding line item</center>
            </Alert>
          ) : null}

          <Col md={12}>
            <Label>Narration</Label>
            <Input
              className="coloum"
              type="textarea"
              value={form.narration}
              name="narration"
              onChange={handleChange}
              rows={5}
            />
          </Col>

          <Col md={12} className="py-4 px-4">
            {/* <Attachment
              acceptedFiles={acceptedFiles}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              isDragActive={isDragActive}
            /> */}
          </Col>
          {/* <Row>
            <ContractorBankDetails form={form} setForm={setForm} />
          </Row> */}
          <Col md={12}>
            <center>
              <Button className="mb-4" onClick={handleApi} disabled={isLoading}>
                {isLoading ? "Please wait..." : "Submit"}
              </Button>
            </center>
          </Col>

          <WarningModal
            showModal={showAmountWarningModal}
            setShowModal={setShowAmountWarningModal}
            handleSubmit={submitPlanningReserveRequest}
            form={form}
            loading={submittingReserveRequest}
          />
        </Row>
      ) : (
        <></>
        // <PDFViewer style={{ width: "100%", height: "100vh" }}>
        //   <RealiseCollectionPdf form={form} selecOffer={formatNumber} />
        // </PDFViewer>
      )}
    </>
  );
}
