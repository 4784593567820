import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
  page: {
    padding: 10.5,
    fontSize: 10.5,
    flexDirection: "column",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  cell: {
    flexGrow: 1,
    padding: 4,
    borderLeftWidth: 0.5,
    borderLeftColor: "#333",
    fontSize: 10,
    textAlign: "center",
  },
  header: {
    fontSize: 15,
    textAlign: "center",
    margin: 10,
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
    fontSize: 12,
  },
});

const ReleasePDF = ({ data }) => {
  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.row}>
            <Text
              style={[
                styles.cell,
                styles.bold,
                {
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "yellow",
                },
              ]}
            >
              Collection Date
            </Text>
            <Text
              style={[
                styles.cell,
                styles.bold,
                {
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "yellow",
                },
              ]}
            >
              Economic Code
            </Text>
            <Text
              style={[
                styles.cell,
                styles.bold,
                {
                  width: "30%",
                  textAlign: "center",
                  backgroundColor: "yellow",
                },
              ]}
            >
              Economic Description
            </Text>
            <Text
              style={[
                styles.cell,
                styles.bold,
                {
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "yellow",
                },
              ]}
            >
              MDA Code
            </Text>
            <Text
              style={[
                styles.cell,
                styles.bold,
                {
                  width: "15%",
                  textAlign: "center",
                  backgroundColor: "yellow",
                },
              ]}
            >
              MDA Name
            </Text>
            <Text
              style={[
                styles.cell,
                styles.bold,
                {
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "yellow",
                },
              ]}
            >
              Program Code
            </Text>
            <Text
              style={[
                styles.cell,
                styles.bold,
                {
                  width: "15%",
                  textAlign: "center",
                  backgroundColor: "yellow",
                },
              ]}
            >
              Amount ( &#8358;)
            </Text>
          </View>
          {data.map((item, idx) => (
            <View key={idx} style={styles.row}>
              <Text style={[styles.cell, { width: "10%" }]}>
                {item.collection_date}
              </Text>
              <Text style={[styles.cell, { width: "10%" }]}>
                {item.economic_code}
              </Text>
              <Text style={[styles.cell, { width: "30%" }]}>
                {item.description_code}
              </Text>
              <Text style={[styles.cell, { width: "10%" }]}>
                {item.mda_code}
              </Text>
              <Text style={[styles.cell, { width: "15%" }]}>
                {item.mda_name}
              </Text>
              <Text style={[styles.cell, { width: "10%" }]}>
                {item.program_code}
              </Text>
              <Text style={[styles.cell, { width: "15%", textAlign: "right" }]}>
                {item.amount.toLocaleString()}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

ReleasePDF.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
};

export default ReleasePDF;
