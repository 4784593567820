/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import ReleasesTable from "./ReleasesTable";

export default function InternalAudit() {
  return (
    <ReleasesTable
      filterBy="Approval Initiated"
      approval_status="approvals Reviewed"
      title="Internal Control"
      _approval_status="select_by_status"
      return_status="Initiated Returned"
    />
  );
}
