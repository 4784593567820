import React from "react";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import PropTypes from "prop-types";
import image from "./assets/images/k.png";

const WhatsAppFloatingIcon = () => {
  return (
    <WhatsAppWidget
      // phoneNumber="+2349113472208"
      textReplyTime="Typically replies in a few hours"
      companyName="Kastina State (E-BUDGET/TBEAMS) Support Line"
      message="Hello! How can we help you today? or call ****** for quick response"
      // CompanyIcon={image}
    />
  );
};

WhatsAppFloatingIcon.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

export default WhatsAppFloatingIcon;
