import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useEffect, useState } from "react";
import { getSector } from "helper";
import { unflatten } from "redux/action/api";
import StructureTree from "components/Tree/StrutureTree";
import Scrollbar from "components/Tree/Scrollbar";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import { handleDelete } from "helper";
import SearchBar from "components/SearchBar";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { _fetchApi } from "redux/action/api";
import { _postApi } from "redux/action/api";

function FundSegment() {
  const [results, setResults] = useState();
  const [result1, setResult1] = useState();
  const columns = [
    { Header: "Head", accessor: "head", width: "45%", align: "left" },

    { Header: "Description", accessor: "description", align: "left" },
    {
      Header: "Action",
      accessor: "action",
      align: "center",
      Cell: () => (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Edit
        </MDTypography>
      ),
    },
  ];
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getSector({ type: "FUND SEGMENT" }, "select", (d) => {
      const modifiedResults = [...d];

      // Find the index where "description" is "FUND SEGMENT"
      const fundSegmentIndex = modifiedResults.findIndex(
        (item) => item.description === "FUND SEGMENT"
      );

      if (fundSegmentIndex !== -1) {
        // If found, empty the "sub_head" for that specific index
        modifiedResults[fundSegmentIndex].sub_head = "";
      }

      // console.log(modifiedResults);

      // Create a copy excluding the first element
      const modifiedResult1 = modifiedResults.slice(1);

      // setResults with the modified array
      setResults(modifiedResult1);

      // Unflatten the modified array
      const formattedData = unflatten(modifiedResults);

      // setResult1 with the unflattened data
      setResult1(formattedData);

      setLoading(false);
    });
  }, []);

  let data = results?.map((i) => ({
    head: i.head,
    description: i.description,
    sub_head: i.sub_head,
  }));

  const [searchQuery, setSearchQuery] = useState("");
  if (searchQuery) {
    data = data.filter((item) => {
      return (
        item.head.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const rows = data || [];
  const navigate = useNavigate();
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const [inputs, setInputs] = useState({
    head: "",
    description: "",
    remarks: "",
    type: "",
  });
  const handleChange = ({ target: { name, value } }) => {
    setInputs((p) => ({ ...p, [name]: value }));
  };
  const [damn, setDamn] = useState();
  const [edit, setEdit] = useState(false);
  const { addToast } = useToasts();
  let heads = inputs?.head;
  let submit = damn?.hmm === "submit" ? "Submit" : "Update";

  const handleSubmit = () => {
    let type = damn?.hmm === "submit" ? "insert" : "update";
    console.log(inputs);
    let query_type = type;
    if (
      inputs.head === "" ||
      inputs.description === "" ||
      inputs.remarks === ""
    ) {
      alert("complate form");
    } else {
      _postApi(
        `/account?query_type=${type}&head=${heads}`,
        inputs,
        (data) => {
          if (data.success) {
            addToast("Saved Successfully", {
              appearance: "info",
              autoDismiss: true,
              autoDismissTimeout: 5000,
            });
            toggle3();
          }
        },
        (err) => console.log(err)
      );
    }
  };
  let id = damn?.id;
  const getIds = () => {
    _fetchApi(
      `/getId/${id}`,
      (data) => {
        console.log({ form: data.results[0] });
        setInputs(data.results[0][0]);
        // }
      },
      (err) => console.log(err)
    );
  };
  useEffect(() => {
    getIds();
  }, [id]);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  Fund Segment
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* <div className='row'> */}
                {/* {JSON.stringify(results)} */}
                <Modal isOpen={modal3} toggle={toggle3} size="lg">
                  <ModalHeader>Continue With</ModalHeader>
                  <ModalBody>
                    <div className=" row mb-4 p-3">
                      <div className="col-md-6">
                        <lable>CODE</lable>
                        <input
                          className="form-control"
                          type="number"
                          name="head"
                          value={inputs.head}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <lable>Description</lable>
                        <input
                          className="form-control"
                          name="description"
                          value={inputs.description}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <lable>Remarks</lable>
                        <input
                          className="form-control"
                          name="remarks"
                          value={inputs.remarks}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="d-flex justify-content-between">
                    <button
                      className="btn btn-primary  mb-3"
                      onClick={handleSubmit}
                    >
                      {submit}
                    </button>
                    <Button color="danger" onClick={toggle3}>
                      cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                <div className="col-md-12">
                  {loading && <Spinner />}
                  <Scrollbar>
                    <StructureTree
                      treeData={result1}
                      title="Fund Segment"
                      addChild={(node) => {
                        setInputs((p) => ({
                          sub_head: node.head,
                          type: node.type,
                        }));
                        setDamn((p) => ({
                          ...p,
                          name: node.description,
                          type: node.type,
                          head: node.head,
                          hmm: "submit",
                          ids: node.head,
                        }));
                        toggle3();
                      }}
                      editNode={(node) => {
                        setEdit(true);
                        setDamn((p) => ({
                          ...p,
                          name: node.description,
                          type: node.type,
                          head: node.head,
                          hmm: "edit",
                          id: node.head,
                        }));
                        toggle3();
                      }}
                      deleteNode={(node) => handleDelete(node.head)}
                    />
                    {/* </center> */}
                  </Scrollbar>
                  {/* </div>
              <div className='col-md-8'> 
              <SearchBar placeholder='Search By code or description' filterText={searchQuery}  onFilterTextChange={handleSearchChange} />
              <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /></div> */}
                </div>
                {/* {JSON.stringify(results)} */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FundSegment;
