import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormGroup, Label, Input, Col, Row, Button } from "reactstrap";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import { _fetchApi } from "redux/action/api";
import { fetchApi } from "redux/action/api_tbeams";
import { Typeahead } from "react-bootstrap-typeahead";
// import { getDistinctObjects } from "layouts/tables/CapitalProject";
import { _postApi } from "redux/action/api";
import { getSector } from "helper";
import { useQuery } from "redux/action/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function ContingencyReserve() {
  // const [economicCode, setEconomicCode] = useState([]);
  const [formValues, setFormValues] = useState({
    adminName: "",
    adminCode: "",
    economicName: "",
    economicCode: "",
    programName: "",
    programCode: "",
    functionName: "",
    functionCode: "",
    geoName: "",
    geoCode: "",
    budgetAmount: "",
    balance: "",
    virementType: "recurrent",
  });
  const route = useLocation();
  const type =
    route.pathname === "/contingency-reserve-capital"
      ? "Capital"
      : route.pathname === "/contingency-reserve-recurrent"
      ? "Recurrent"
      : "AIE";
  const [form, setForm] = useState({ query_type: "INSERT", type: type });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };
  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     console.log(formValues);
  //   };

  const [budgetSummary, setBudgetSummary] = useState([]);
  const [economic, setEconomic] = useState([]);
  const [fund, setFund] = useState([]);
  const [program, setProgram] = useState([]);
  const [geo, setGeo] = useState([]);
  const [functional, setFunctional] = useState([]);
  // const [economic, setEconomic] = useState([]);
  const query = useQuery();
  const getList = () => {
    fetchApi(`get-budget-summary?query_type=list`)
      .then((d) => {
        console.log("d", d);
        if (d.result) {
          setBudgetSummary(d.result);
        }
      })
      .catch((err) => console.log(err));
  };

  const getBudgetBalance = () => {
    fetchApi(
      `get-budget-summary?query_type=fetch_budget_mda&mda_code=${form.mda_head_code}&economic_code=${form.mda_economic_code}`
    )
      .then((d) => {
        console.log("d", d);
        if (d.result) {
          //   alert(JSON.stringify(d.result[0]));
          let data = d?.result[0];
          setForm((p) => ({ ...p, balance: data?.approve_amount }));
        }
      })
      .catch((err) => console.log(err));
  };

  const getBudgetBalance2 = () => {
    fetchApi(
      `get-budget-summary?query_type=fetch_budget_mda&mda_code=${form.mda_head_code}&economic_code=${form.mda_economic_code}`
    )
      .then((d) => {
        console.log("d", d);
        if (d.result) {
          //   alert(JSON.stringify(d.result[0]));
          let data = d?.result[0];
          setForm((p) => ({ ...p, source_balance: data?.approve_amount }));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getList();
    // getEco();
  }, []);

  const handleMdaCode1 = (selected) => {
    if (selected?.length) {
      console.log(selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        mda_head_code: selectedItem.head,
        mda_head: selectedItem.description,
      }));

      // getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const handleEconomicCode = (selected) => {
    if (selected?.length) {
      console.log("selected budget", selected);
      const selectedItem = selected[0];
      //   setSelectedBudget(selected[0]);
      setForm((p) => ({
        ...p,
        mda_economic_code: selectedItem.economic_code,
        mda_economic_description: selectedItem.economic_description,
      }));
      getBudgetBalance(selectedItem.economic_code);
    } else {
      console.log("no item selected");
    }
  };

  // const handleEconomicCode2 = (selected) => {
  //   if (selected?.length) {
  //     console.log("selected budget", selected);
  //     const selectedItem = selected[0];
  //     //   setSelectedBudget(selected[0]);
  //     setForm((p) => ({
  //       ...p,
  //       source_eco: selectedItem.economic_code,
  //       source_eco_code: selectedItem.economic_description,
  //     }));
  //     getBudgetBalance2(selectedItem.economic_code);
  //   } else {
  //     console.log("no item selected");
  //   }
  // };

  const handleprogram = (selected) => {
    if (selected?.length) {
      console.log(selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        programCode: selectedItem.head,
        program: selectedItem.description,
      }));

      // getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const handleFunction = (selected) => {
    if (selected?.length) {
      console.log(selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        functionCode: selectedItem.head,
        functions: selectedItem.description,
      }));

      // getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const handleGeo = (selected) => {
    if (selected?.length) {
      console.log(selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        geoCode: selectedItem.head,
        geo: selectedItem.description,
      }));

      // getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };

  const handleFund = (selected) => {
    if (selected?.length) {
      console.log(selected);
      const selectedItem = selected[0];
      setForm((p) => ({
        ...p,
        fund_head: selectedItem.description,
        fund_code: selectedItem.head,
      }));

      // getList2(selectedItem.mda_code);
    } else {
      console.log("no item selected");
    }
  };
  const navigate = useNavigate();

  const handleSubmit = () => {
    _postApi(
      "/contengency-reserve",
      form,
      (res) => {
        alert("success");
        navigate(-1);
      },
      (err) => {
        alert("error");
        console.log(err);
      }
    );
  };
  const [results4, setResult4] = useState([]);

  const getList2 = useCallback(() => {
    fetchApi(
      `get-budget-summary?query_type=${
        route.pathname === "/contingency-reserve-capital"
          ? "capital"
          : "recurrent"
      }`
    )
      .then((d) => {
        console.log(d);
        if (d.result) {
          console.log(d);
          setEconomic(d.result);
        }
      })
      .catch((err) => console.log(err));
  }, [route.pathname]);

  useEffect(() => {
    getSector({ type: "mdas" }, "mdas", (d) => {
      setResult4(d);
    });

    getSector({ type: "fund" }, "fund", (d) => {
      setFund(d);
    });

    getSector({ type: "geo" }, "geo", (d) => {
      setGeo(d);
    });
    getSector({ type: "program" }, "program", (d) => {
      setProgram(d);
    });

    getSector({ type: "function" }, "function", (d) => {
      setFunctional(d);
    });
    getList2();
  }, [getList2]);

  let aheadData = results4;

  // const type = query.get("name");
  // const route = useLocation();

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={2} pb={5}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  Contingency Reserve{" "}
                  {route.pathname === "/contingency-reserve-capital"
                    ? "Capital"
                    : "Recurrent"}
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={3}>
                <h4>Request MDA</h4>

                <Row form>
                  <>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="adminName">Admin Name</Label>
                        <Typeahead
                          size="sm"
                          id="20"
                          onChange={(selected) => handleMdaCode1(selected)}
                          onInputChange={(text) => console.log(text)}
                          options={aheadData}
                          labelKey={(option) => `${option.description}`}
                          placeholder="Select MDA"
                          // ref={mdaRef}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="adminCode">Admin Code</Label>
                        <Input
                          id="mda_head_code"
                          name="mda_head_code"
                          type="number"
                          value={form.mda_head_code}
                          onChange={handleChange}
                          disabled
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="economicName">Economic Name</Label>
                        <Typeahead
                          size="sm"
                          id="20"
                          onChange={(selected) => handleEconomicCode(selected)}
                          onInputChange={(text) => console.log(text)}
                          options={economic.length ? economic : []}
                          labelKey={(option) => {
                            return `${option.economic_code} - ${option.economic_description}`;
                          }}
                          placeholder="Select---"
                          //   ref={economicRef}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="economicCode">Economic Code</Label>
                        <Input
                          id="mda_economic_code"
                          name="mda_economic_code"
                          type="number"
                          value={form.mda_economic_code}
                          onChange={handleChange}
                          disabled
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="programName">Program Name</Label>
                        <Typeahead
                          size="sm"
                          id="20"
                          onChange={(selected) => handleprogram(selected)}
                          onInputChange={(text) => console.log(text)}
                          options={program.length ? program : []}
                          labelKey={(option) => {
                            return `${option.head} - ${option.description}`;
                          }}
                          placeholder="Select---"
                          //   ref={economicRef}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="programCode">Program Code</Label>
                        <Input
                          id="programCode"
                          name="programCode"
                          value={form.programCode}
                          onChange={handleChange}
                          disabled
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="functionName">Function Name</Label>
                        <Typeahead
                          size="sm"
                          id="20"
                          onChange={(selected) => handleFunction(selected)}
                          onInputChange={(text) => console.log(text)}
                          options={functional.length ? functional : []}
                          labelKey={(option) => {
                            return `${option.head} - ${option.description}`;
                          }}
                          placeholder="Select---"
                          //   ref={economicRef}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="functionCode">Function Code</Label>
                        <Input
                          id="functionCode"
                          name="functionCode"
                          value={form.functionCode}
                          onChange={handleChange}
                          disabled
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="functionName">Fund Name</Label>
                        <Typeahead
                          size="sm"
                          id="20"
                          onChange={(selected) => handleFund(selected)}
                          onInputChange={(text) => console.log(text)}
                          options={fund.length ? fund : []}
                          labelKey={(option) => {
                            return `${option.head} - ${option.description}`;
                          }}
                          placeholder="Select---"
                          //   ref={economicRef}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="functionCode">Fund Code</Label>
                        <Input
                          id="fund_code"
                          name="fund_code"
                          value={form.fund_code}
                          onChange={handleChange}
                          disabled
                        ></Input>
                      </FormGroup>
                    </Col>
                    {/* </Row>
                    <Row form> */}
                    <Col md={3}>
                      <FormGroup>
                        <Label for="geoName">Geo Name</Label>
                        <Typeahead
                          size="sm"
                          id="20"
                          onChange={(selected) => handleGeo(selected)}
                          onInputChange={(text) => console.log(text)}
                          options={geo.length ? geo : []}
                          labelKey={(option) => {
                            return `${option.head} - ${option.description}`;
                          }}
                          placeholder="Select---"
                          //   ref={economicRef}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="geoCode">Geo Code</Label>
                        <Input
                          id="geoCode"
                          name="geoCode"
                          value={form.geoCode}
                          onChange={handleChange}
                          disabled
                        ></Input>
                      </FormGroup>
                    </Col>
                  </>

                  <Col md={3}>
                    <FormGroup>
                      <Label for="budgetAmount">Budget Amount</Label>
                      <Input
                        id="budgetAmount"
                        name="budgetAmount"
                        type="number"
                        value={form.budgetAmount}
                        onChange={handleChange}
                        //disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="balance">Balance</Label>
                      <Input
                        id="balance"
                        name="balance"
                        type="number"
                        value={form.balance}
                        onChange={handleChange}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <center>
                  <Col xs={12} md={6} className="mb-2">
                    <Button
                      type="submit"
                      color="primary"
                      className="w-100 px-4"
                      onClick={handleSubmit}
                    >
                      {" "}
                      Submit
                    </Button>
                  </Col>
                </center>
                {/* </form> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
